import IconSVG from '../../styles/svg-icons';
import { CloManager } from '../../types/clo-managers/CloManager';
import { OnHoverTooltip } from '../common';
import { useDispatch } from 'react-redux';
import { cloManagersActions } from '../../actions/clo-managers.actions';
import { CloManagerAlertsButton } from './CloManagerAlertsButton';
import classNames from 'classnames';

interface Props {
    manager: CloManager;
    isAlertOn?: boolean;
    showAlertSection?: boolean;
    onFavoriteClick?: React.MouseEventHandler<HTMLButtonElement>;
    showFavoriteIcon?: boolean;
}

export const CloManagerLabels = ({ manager, showAlertSection, isAlertOn = false, onFavoriteClick, showFavoriteIcon = false }: Props) => {
    const dispatch = useDispatch();

    const { hasActiveTransactions, hasAmrDeal, favorite } = manager;

    const renderFavoriteIcon = () => {
        const overlay = favorite
            ? 'Remove from favorite'
            : 'Add to favorite';

        const icon = favorite
            ? 'favorite-fill'
            : 'favorite';

        return (
            <div className="sidebar-controls-item">
                <OnHoverTooltip overlay={overlay}>
                    <button
                        onClick={onFavoriteClick}
                        className="btn-link btn-link-favorite"
                    >
                        <IconSVG name={icon} width={16} height={16} />
                    </button>
                </OnHoverTooltip>
            </div>
        );
    };

    return (
        <>
            <div className="sidebar-controls-item">
                {hasActiveTransactions && (
                    <OnHoverTooltip overlay="Manager with Active deals">
                        <IconSVG name="active-manager" width={16} height={16} />
                    </OnHoverTooltip>
                )}
            </div>
            {hasAmrDeal && (
                <div className="sidebar-controls-item-label">
                    <OnHoverTooltip overlay="Manager with AMR deals">
                        <span className="bwic-state-ktx">AMR</span>
                    </OnHoverTooltip>
                </div>
            )}
            <div className={classNames("flex-row", { "flex-item-right": showFavoriteIcon })}>
                <div className="sidebar-controls-item sidebar-controls-item-alert">
                    {showAlertSection && (
                        <OnHoverTooltip overlay={'Manager’s Profile Alerts'}>
                            <CloManagerAlertsButton
                                isAlertOn={isAlertOn}
                                onClick={() => dispatch(cloManagersActions.setManagerForAlertPopup(manager.referenceName))}
                            />
                        </OnHoverTooltip>
                    )}
                </div>
                {showFavoriteIcon && renderFavoriteIcon()}
            </div>
        </>
    );
};
