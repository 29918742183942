import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeColorActions as actions } from '../../actions';
import { levelSpecifications, pxTalkLevelTypes } from '../../constants';
import { ButtonSwitch } from '../controls';
import { stringUtils } from '../../utils';
import { classnames } from '../../styles/classnames';
import IconSVG from '../../styles/svg-icons';

class ChangeColor extends Component {

    state = {
        changed: false
    };

    render = () => {
        const { ticker, color, errors, saveButtonText, onDelete, dispatch } = this.props;
        const formControlClasses = classnames({
                'form-control': true,
                'is-invalid': !!errors.level || !!errors.color
            });

        if (!color) {
            return null;
        }

        return (
            <div className="sidebar-panel sidebar-panel-color">
                <div className="sidebar-panel-header">
                    {!stringUtils.isNullOrEmpty(ticker) && <h2>Color for {ticker}</h2>}
                    {stringUtils.isNullOrEmpty(ticker) && <h2>Change color</h2>}
                    <button className="btn-close flex-none flex-item-right" onClick={() => dispatch(actions.reset())}>
                        <IconSVG name="close" width={16} height={16} />
                    </button>
                </div>
                <div className="sidebar-panel-content">
                    <div className="form-row form-color-wrapper">
                        <div className="form-item">
                            <ButtonSwitch
                                sourceItems={[{ key: true, title: 'Traded' }, { key: false, title: 'DNT' }]}
                                selectedValue={color.traded}
                                onSelectionChange={traded => dispatch(actions.tradedChange(traded))}
                                className="control-group-type01"
                            />
                        </div>
                        <div className="form-item flex-row">
                            <ButtonSwitch
                                sourceItems={[levelSpecifications.best, levelSpecifications.cover]}
                                selectedValue={color.levelSpecificationType}
                                onSelectionChange={levelSpecification => dispatch(actions.levelSpecificationChange(levelSpecification))}
                                className="control-group-type01"
                            />
                        </div>
                        <div className="form-item form-item-color">
                            <label className="form-label">Color</label>
                            <div className="form-control-wrapper">
                                <input
                                    type="text"
                                    className={formControlClasses}
                                    maxLength="49"
                                    autoComplete="off"
                                    value={color.level || ''}
                                    onChange={this.handleLevelChange}
                                    onBlur={this.handleLevelBlur}
                                />
                                {(!!errors.level || !!errors.color) && <div className="form-error">{errors.level || errors.color}</div>}
                            </div>
                            {color.normalizedLevel != null && <div className="sub-text"><span className="bold">{color.normalizedLevel}</span> Normalized</div>}
                        </div>
                        <div className="form-item">
                            <ButtonSwitch
                                sourceItems={[pxTalkLevelTypes.price, pxTalkLevelTypes.DM]}
                                selectedValue={color.levelType}
                                onSelectionChange={levelType => dispatch(actions.levelTypeChange(levelType))}
                                className="control-group-type01"
                            />
                        </div>
                        <div className="form-item form-item-color">
                            <label className="form-label">DM</label>
                            <div className="form-control-wrapper">
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength="49"
                                    autoComplete="off"
                                    value={color.dm || ''}
                                    onChange={this.handleDmChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar-panel-footer">
                    {
                        !color.isNew &&
                        onDelete != null &&
                        <button
                            className="btn-link btn-danger"
                            type="button"
                            onClick={onDelete.bind(this)}
                        >
                            <i className="icon icon-delete" />
                            Delete
                        </button>
                    }
                    <div className="flex-item-right">
                        <button className="btn btn-ghost" type="button" onClick={this.handleCancel}>CANCEL</button>
                        <button className="btn btn-main" type="button" onClick={this.handleSave}>{saveButtonText || 'SAVE CHANGES'}</button>
                    </div>
                </div>
            </div>
        );
    }

    handleSave = () => {
        const { onSave, dispatch } = this.props;

        dispatch(actions.waitNormalizing(onSave));
    }

    handleCancel = () => {
        const { onCancel, dispatch } = this.props;

        dispatch(actions.reset());

        if (onCancel) {
            onCancel();
        }
    }

    handleLevelChange = e => {
        this.setState({ changed: true });
        this.props.dispatch(actions.levelChange(e.target.value))
    }

    handleDmChange = e => {
        const { value } = e.target;
        if (value.search(/[^\d]/g) === -1) {
            this.props.dispatch(actions.setDm(value));
        }
    }

    handleLevelBlur = () => {
        const { color, dispatch } = this.props;

        if (this.state.changed) {
            this.setState({ changed: false });
            dispatch(actions.normalizeLevel(color.level, color.levelType));
        }
    }
}

const mapStateToProps = ({ changeColor }) => ({
    ticker: changeColor.ticker,
    color: changeColor.color,
    errors: changeColor.errors
});

const connectedChangeColor = connect(mapStateToProps)(ChangeColor);
export { connectedChangeColor as ChangeColor };
