import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent';
import { isRequestFailed, isRequestSuccess, isRequesting } from '../../../../utils';
import { EmptyPlaceholder, Preloader } from '../../../common';
import { Table } from '../../../bidding/common/table';
import { DealsControlPanel } from './DealsControlPanel';
import { useDealSecurityStatistics, useLatestDealTransactions } from './hooks';
import { DealSecurityTableItem, convertToDealSecurityTableItem, convertToTableItem, dealColumns, securityColumns } from './columns';
import { Transaction } from '../../../../types/amr-pipeline/models/Transaction';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { cloManagersActions } from '../../../../actions/clo-managers.actions';
import { CloManagerAccessType } from '../../../../types/amr-pipeline/enums/CloManagerAccessType';
import { OriginatingTransaction } from '../../../../types/amr-pipeline/models/OriginatingTransaction';
import { TableColumnStickType } from '../../../bidding/common/table/types/TableColumnStickType';
import { LazyLoad } from '../../../common/LazyLoad';
import { SORT, constants } from '../../../../constants';
import { DealSecurityStatistics } from '../../../../types/security/DealSecurityStatistics';
import { controlPanelActions } from '../../../../actions';
import { AppState } from '../../../../types/state/AppState';
import IntexPopup from '../../../amrPipeline/detailed/IntexPopup';
import IconSVG from '../../../../styles/svg-icons';
import { ShowFor } from '../../../access';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { Link } from 'react-router-dom';
import { getTransactionDetailsUrl } from '../../../../utils/amr-pipeline.utils';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

export const Deals = () => {
    const dispatch = useAppDispatch();
    const cloManager = useAppSelector(s => s.cloManagers.selectedManager);
    const transactionsState = useLatestDealTransactions(cloManager?.referenceName);

    const [transactionForIntex, setTransactionForIntex] = useState<OriginatingTransaction>();
    const selectedSecurity = useAppSelector(s => s.cloManagers.selectedSecurity);
    const { userCompany } = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon);

    const securitiesState = useDealSecurityStatistics(
        // Pass non-empty deal tickers
        transactionsState.transactions.filter(t => t.dealTicker).map(t => t.dealTicker!),
        // Wait until deal transactions request is done
        !isRequestSuccess(transactionsState.requestState)
    );

    useEffect(() => {
        //Reset panels state
        return () => {
            dispatch(controlPanelActions.hide());
        }
    }, [dispatch]);

    if (!cloManager?.details) return null;

    const getDataItems = () =>
        transactionsState.transactions.map(t =>
            convertToTableItem({
                ...t,
                hasDocuments:
                    isRequestSuccess(securitiesState.requestState) &&
                    securitiesState.statistics.find(
                        s =>
                            t.dealTicker?.localeCompare(s.ticker, undefined, {
                                sensitivity: 'accent',
                            }) === 0,
                    )?.hasDocuments,
            }),
        );

    const trackDoorClick = () => dispatch(
        cloManagersActions.logUserActivity(
            cloManager.referenceName,
            CloManagerAccessType.DealDoor)
    )

    const trackIntexClick = () => dispatch(
        cloManagersActions.logUserActivity(
            cloManager.referenceName,
            CloManagerAccessType.DealIntex)
    );


    const context = {
        trackDoorClick,
        trackIntexClick,
        setTransactionForIntex,
        userCompany,
        securities: securitiesState.statistics,
    };

    const renderPlaceholder = (verticalPlaces: number) =>
        <div
            style={{
                height:
                    constants.tableRowHeight * verticalPlaces + constants.tableHeaderHeight
            }}
        />

    const renderDealSecurities = (
        transaction: OriginatingTransaction,
        statistics: DealSecurityStatistics,
        columnType: TableColumnStickType) =>
        <Table
            tableKey={transaction.referenceName}
            sticky={true}
            columns={columnType === TableColumnStickType.Middle
                ? securityColumns.middle
                : securityColumns.right
            }
            dataItems={statistics.classes.map(c => convertToDealSecurityTableItem(c, transaction))}
            createSecurityCustomArgs={() => ({ context })}
            createRowCustomKey={(s: DealSecurityTableItem) => `${transaction.referenceName}-${s.securityId}`}
            createSecurityCustomClassName={(item: DealSecurityTableItem) => cn({
                highlighted: item.securityId === selectedSecurity?.securityId
            })}
        />

    const renderCollapsibleItem = (
        transaction: OriginatingTransaction,
        columnType: TableColumnStickType) => {
        if (isRequesting(securitiesState.requestState) || isRequestFailed(securitiesState.requestState)) {
            // Show preloader in table middle section while requesting deal securities
            return columnType === TableColumnStickType.Middle
                ? <Preloader inProgress={true} small={true} />
                : <div className="sticky-column-preloader"></div>;
        }

        const statistics = securitiesState.statistics.find(s =>
            transaction.dealTicker?.localeCompare(s.ticker, undefined, { sensitivity: "accent" }) === 0
        );

        if (statistics?.classes.length) {
            return (
                <LazyLoad enabledUnloading className="component-collapsible-list-item-wrap">
                    {
                        visible => visible
                            ? renderDealSecurities(transaction, statistics, columnType)
                            : renderPlaceholder(statistics.classes.length)
                    }
                </LazyLoad>
            );
        }

        // Show emply placeholder in table middle section
        return columnType === TableColumnStickType.Middle ? (
            <EmptyPlaceholder
                text={
                    <>
                        There are no secondary data to display yet.{' '}
                        <ShowFor feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                            <Link
                                target="_blank"
                                to={getTransactionDetailsUrl(transaction)}
                                onClick={e => {
                                    e.stopPropagation();
                                    context.trackDoorClick();
                                }}
                            >
                                Click here
                            </Link>{' '}
                            or hit <IconSVG name="go-to-details" width={16} height={16} /> button in the right panel to
                            see deal details.
                        </ShowFor>
                    </>
                }
                textViewRow
            />
        ) : (
            <EmptyPlaceholder text="" textViewRow />
        );
    }

    return (
        <>
            <SectionContent className="data-item-clo-managers-deals">
                <Preloader inProgress={!isRequestSuccess(transactionsState.requestState)}>
                    {isRequestSuccess(transactionsState.requestState) && (
                        <>
                            <Table
                                collapsible={true}
                                sticky={true}
                                defaultSortBy="closingDate"
                                defaultSortByDirection={SORT.DESC}
                                dataItems={getDataItems()}
                                columns={dealColumns}
                                createRowCustomKey={(t: Transaction) => t.referenceName}
                                createSecurityCustomArgs={() => context}
                                renderCollapsibleItem={renderCollapsibleItem}
                            />
                            <DealsControlPanel />
                        </>
                    )}
                </Preloader>
            </SectionContent>
            {transactionForIntex && (
                <IntexPopup transaction={transactionForIntex} onCloseClick={() => setTransactionForIntex(undefined)} />
            )}
        </>
    );
};
