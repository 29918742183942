import * as React from 'react';
import classNames from 'classnames';
import { Tooltip, TooltipPlacement } from './Tooltip';

interface Props {
    disabled?: boolean;
    children?: any;
    wrapperClass?: string;
    placement?: string | TooltipPlacement;
    overlay?: React.ReactNode;
    overlayClassName?: string;
}

export function OnHoverTooltip({ wrapperClass, overlay, disabled, placement, overlayClassName, children }: Props) {
    return (
        !!overlay && !disabled
            ? (
                <Tooltip
                    overlay={overlay}
                    disabled={disabled}
                    placement={placement}
                    overlayClassName={classNames(overlayClassName, 'kp-tooltip')}
                >
                    <div className={classNames('tooltip-wrapper', wrapperClass)}>{children}</div>
                </Tooltip>
            ) : <>{children}</>
    )
}
