import * as React from 'react';
import { useSelector } from 'react-redux';
import { BwicRules, BwicRulesProps } from './BwicRules';
import { RightSidePanel } from '../controls';
import { rulesActions } from '../../actions';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface BwicRulesPanelProps extends BwicRulesProps {
    onClose?: () => void;
}

export function BwicRulesPanel({ onClose, process, parsedProcess, bidsDueDate, goodUntilDate, isParsed, isSameDayBwic, daysToSettle, isMy, isAllToAll, isDirectBidding }: BwicRulesPanelProps) {
    const visible: boolean = useSelector<any, any>(store => store.rules.show);
    const dispatch = useAppDispatch();
    if (!visible) {
        return null;
    }
    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        dispatch(rulesActions.hide());

        if (onClose) {
            onClose();
        }
    }

    return (
        <RightSidePanel className="right-panel-rules" title="Rules" onClose={handleClose}>
            <BwicRules
                goodUntilDate={goodUntilDate}
                process={process}
                parsedProcess={parsedProcess}
                bidsDueDate={bidsDueDate}
                isParsed={isParsed}
                isSameDayBwic={isSameDayBwic}
                daysToSettle={daysToSettle}
                isMy={isMy}
                isAllToAll={isAllToAll}
                isDirectBidding={isDirectBidding}
            />
        </RightSidePanel>
    );
}
