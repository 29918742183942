import * as React from 'react';

export function LiveBiddingTooltipContent() {
    return (
        <>
            <p>LiveBidding&#8482;</p>
            <p>
                In Stage 1, participants submit blind bids to set the starting bid level for
                Stage 2. In Stage 2, a specified number of top bidders from Stage 1 can see
                live Best or Cover bid levels and improve their bids until the BWIC is completed.
            </p>
        </>
    );
}
