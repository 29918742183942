import classNames from 'classnames';
import { constants } from '../../../../constants';
import { investmentTeamColumnsLabels } from '../../../../constants/cloManagers/investmentTeamColumnLabels';
import IconSVG from '../../../../styles/svg-icons';
import { userStatusTitle } from '../../../../types/account/UserStatus';
import { CloManagerAccessType } from '../../../../types/amr-pipeline/enums/CloManagerAccessType';
import { InvestmentTeamMember } from '../../../../types/amr-pipeline/models/InvestmentTeamMember';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { OnHoverTooltip } from '../../../common/OnHoverTooltip';
import { InvestmentTeamColumn } from '../../types/InvestmentTeamColumn';
import { Checkbox } from '../../../controls';
import { cloManagersActions } from '../../../../actions/clo-managers.actions';
import { ValueBlocker } from '../../../access/ValueBlocker';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';

const investmentTeamColumnDefinition: IColumnDefinition<InvestmentTeamMember>[] = [
    {
        columnKey: InvestmentTeamColumn.status,
        renderColumnHeaderContent: () => '',
        renderColumnContent: ({ status }) => {
            if (!status) {
                return null;
            }

            const statusTitle = userStatusTitle[status];
            return (
                <OnHoverTooltip overlay={statusTitle}>
                    <div className={classNames('status', statusTitle.toLocaleLowerCase())} />
                </OnHoverTooltip>
            );
        },
        className: 'data-list-cell-symbol',
    },
    {
        columnKey: InvestmentTeamColumn.fullName,
        renderColumnHeaderContent: () => investmentTeamColumnsLabels[InvestmentTeamColumn.fullName],
        renderColumnContent: member => member.fullName,
        className: 'data-list-cell-xl',
    },
    {
        columnKey: InvestmentTeamColumn.linkedIn,
        renderColumnHeaderContent: () => investmentTeamColumnsLabels[InvestmentTeamColumn.linkedIn],
        renderColumnContent: (member, { trackActivity }) =>
            member.linkedIn ? (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={member.linkedIn}
                    className="btn-link btn-linkedin"
                    onClick={() => trackActivity(CloManagerAccessType.CloTeamIn)}
                >
                    <IconSVG name="linkedin-filled" width={16} height={16} />
                </a>
            ) : (
                constants.emptyPlaceholder
            ),
        className: 'data-list-cell-xxxs',
    },
    {
        columnKey: InvestmentTeamColumn.title,
        renderColumnHeaderContent: () => investmentTeamColumnsLabels[InvestmentTeamColumn.title],
        renderColumnContent: member => member.title || constants.emptyPlaceholder,
        className: 'data-list-cell-xl-xxl',
    },
    {
        columnKey: InvestmentTeamColumn.email,
        renderColumnHeaderContent: () => investmentTeamColumnsLabels[InvestmentTeamColumn.email],
        renderColumnContent: (member, context) =>
            <ValueBlocker feature={SubscriptionFeature.ManagerProfileContacts} overrideRequiredFeatures={context.ownCompany}>
                {member.email ? <a href={`mailto:${member.email}`}>{member.email}</a> : constants.emptyPlaceholder}
            </ValueBlocker>,
        className: 'data-list-cell-xl-xxl',
    },
    {
        columnKey: InvestmentTeamColumn.phoneNumber,
        renderColumnHeaderContent: () => investmentTeamColumnsLabels[InvestmentTeamColumn.phoneNumber],
        renderColumnContent: (member, context) =>
            <ValueBlocker feature={SubscriptionFeature.ManagerProfileContacts} overrideRequiredFeatures={context.ownCompany}>
                {member.phoneNumber || constants.emptyPlaceholder}
            </ValueBlocker>,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: InvestmentTeamColumn.location,
        renderColumnHeaderContent: () => investmentTeamColumnsLabels[InvestmentTeamColumn.location],
        renderColumnContent: member => member.location || constants.emptyPlaceholder,
        className: 'data-list-cell-lg',
    },
    // AMR-8924 CLO Managers:: CLO Team:: Hide the 'Yrs at firm' and 'Yrs experience' fields in the table
    // {
    //     columnKey: InvestmentTeamColumn.yrsAtCompany,
    //     renderColumnHeaderContent: () => investmentTeamColumnsLabels[InvestmentTeamColumn.yrsAtCompany],
    //     renderColumnContent: member => member.yrsAtCompany ?? constants.emptyPlaceholder,
    //     className: 'data-list-cell-xs text-right',
    // },
    // {
    //     columnKey: InvestmentTeamColumn.yrsExperience,
    //     renderColumnHeaderContent: () => investmentTeamColumnsLabels[InvestmentTeamColumn.yrsExperience],
    //     renderColumnContent: member => member.yrsExperience ?? constants.emptyPlaceholder,
    //     className: 'data-list-cell-sm text-right',
    // },
];

const meetingContact: IColumnDefinition<InvestmentTeamMember> =  {
        columnKey: InvestmentTeamColumn.meetingContact,
        renderColumnHeaderContent: () => investmentTeamColumnsLabels[InvestmentTeamColumn.meetingContact],
        renderColumnContent: (member, { dispatch }) => (
            <Checkbox
                label=""
                checked={!!member.meetingContact}
                onChange={() => dispatch(cloManagersActions.editPlatformUsers(member.email!))}
            />
        ),
        className: 'data-list-cell-lg',
    };

export const investmentTeamColumns = investmentTeamColumnDefinition.map(c => new ColumnBuilder(c));

export const editInvestmentTeamColumns = [...investmentTeamColumns, new ColumnBuilder(meetingContact)];
