import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { CloManagersState } from '../../../../types/state/CloManagersState';
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent';
import { FormField } from '../../../amrPipeline/common/FormField';
import {
    getCloSystemInfrastructureNamesList,
    groupCloSystemInfrastructure,
} from '../../../../utils/clo-managers.utils';
import { constants } from '../../../../constants';
import { CloSystemInfrastructuresTypes } from '../../../../types/clo-managers/CloSystemInfrastructures';

export const CloSystemInfrastructure = () => {
    const { selectedManager } = useSelector<AppState, CloManagersState>((state: AppState) => state.cloManagers);

    if (!selectedManager?.details) {
        return null;
    }

    const {
        details: { cloSystemInfrastructures },
    } = selectedManager;

    const cloSystemInfrastructuresValues = groupCloSystemInfrastructure(cloSystemInfrastructures);

    const cloValuationServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.CloValuationServices);
    const cloAnalyticsServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.CloAnalyticsServices);
    const cloPortfolioManagementServices = cloSystemInfrastructuresValues.get(
        CloSystemInfrastructuresTypes.CloPortfolioManagementServices,
    );
    const offshoreLegalServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.OffshoreLegalServices);
    const offshoreFundAdminServices = cloSystemInfrastructuresValues.get(
        CloSystemInfrastructuresTypes.OffshoreFundAdministrationServices,
    );
    const cloAccountingServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.CloAccountingServices);
    const secondaryCloPlatform = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.SecondaryCLOPlatforms);
    const cloDocsServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.CloDocsServices);
    const usCloLawFirms = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.USCLOLawFirms);
    const euCloLawFirms = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.EuropeanCLOLawFirms);


    return (
        <SectionContent title="CLO System Infrastructure" className="data-item-general-information">
            <div className="content-row">
                <div className="content-col">
                    <FormField title="CLO Valuation Services" className="data-item-row-full-height">
                        {cloValuationServices?.length ? getCloSystemInfrastructureNamesList(cloValuationServices) : constants.emptyPlaceholder}
                    </FormField>
                    <FormField title="CLO Analytics Services" className="data-item-row-full-height">
                        {cloAnalyticsServices?.length ? getCloSystemInfrastructureNamesList(cloAnalyticsServices) : constants.emptyPlaceholder}
                    </FormField>
                    <FormField title="CLO Portfolio Management Services" className="data-item-row-full-height">
                        {cloPortfolioManagementServices?.length ? getCloSystemInfrastructureNamesList(cloPortfolioManagementServices) : constants.emptyPlaceholder}
                    </FormField>
                    <FormField title="Offshore Legal Services" className="data-item-row-full-height">
                        {offshoreLegalServices?.length ? getCloSystemInfrastructureNamesList(offshoreLegalServices) : constants.emptyPlaceholder}
                    </FormField>
                    <FormField title="Offshore Fund Admin. Services" className="data-item-row-full-height">
                        {offshoreFundAdminServices?.length ? getCloSystemInfrastructureNamesList(offshoreFundAdminServices) : constants.emptyPlaceholder}
                    </FormField>
                </div>
                <div className="content-col">
                    <FormField title="CLO Accounting Services" className="data-item-row-full-height">
                        {cloAccountingServices?.length ? getCloSystemInfrastructureNamesList(cloAccountingServices) : constants.emptyPlaceholder}
                    </FormField>
                    <FormField title="Secondary CLO Platform" className="data-item-row-full-height">
                        {secondaryCloPlatform?.length ? getCloSystemInfrastructureNamesList(secondaryCloPlatform) : constants.emptyPlaceholder}
                    </FormField>
                    <FormField title="CLO Docs Services" className="data-item-row-full-height">
                        {cloDocsServices?.length ? getCloSystemInfrastructureNamesList(cloDocsServices) : constants.emptyPlaceholder}
                    </FormField>
                    <FormField title="US CLO Law Firms" className="data-item-row-full-height">
                        {usCloLawFirms?.length ? getCloSystemInfrastructureNamesList(usCloLawFirms) : constants.emptyPlaceholder}
                    </FormField>
                    <FormField title="European CLO Law Firms" className="data-item-row-full-height">
                        {euCloLawFirms?.length ? getCloSystemInfrastructureNamesList(euCloLawFirms) : constants.emptyPlaceholder}
                    </FormField>
                </div>
            </div>
        </SectionContent>
    );
};
