import React, { useCallback, useEffect, useRef, useState, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { ClickOutside } from './ClickOutside';

export enum TooltipTriggerType {
    Hover = 'hover',
    Click = 'click',
}

export enum TooltipPlacement {
    Top = 'top',
    TopLeft = 'topLeft',
    TopRight = 'topRight',
    Bottom = 'bottom',
    BottomLeft = 'bottomLeft',
    BottomRight = 'bottomRight',
    Left = 'left',
    Right = 'right',
    CenterTop = 'centerTop',
    CenterLeft = 'centerLeft',
    CenterRight = 'centerRight',
    CenterBottom = 'centerBottom',
}

interface TooltipProps {
    overlay: (() => React.ReactNode) | React.ReactNode;
    disabled?: boolean;
    placement: string | TooltipPlacement;
    children: React.ReactNode;
    indent: number;
    overlayClassName?: string;
    className?: string;
    delay?: number;
    trigger: TooltipTriggerType;
    onVisibleChange?: (visible: boolean) => void;
    visible: boolean;
    hideOnAnyAction?: boolean;
}

type TAdjustmentMap = {
    [defaultPlacement in TooltipPlacement]: {
        x: TooltipPlacement,
        y: TooltipPlacement
    }
};

const adjustmentMap: TAdjustmentMap = {
    [TooltipPlacement.Top]: { x: TooltipPlacement.BottomRight, y: TooltipPlacement.TopLeft },
    [TooltipPlacement.TopLeft]: { x: TooltipPlacement.BottomRight, y: TooltipPlacement.TopRight },
    [TooltipPlacement.TopRight]: { x: TooltipPlacement.BottomLeft, y: TooltipPlacement.TopLeft },
    [TooltipPlacement.Bottom]: { x: TooltipPlacement.BottomLeft, y: TooltipPlacement.Top },
    [TooltipPlacement.BottomLeft]: { x: TooltipPlacement.Left, y: TooltipPlacement.TopLeft },
    [TooltipPlacement.BottomRight]: { x: TooltipPlacement.Right, y: TooltipPlacement.TopRight },
    [TooltipPlacement.Left]: { x: TooltipPlacement.Right, y: TooltipPlacement.BottomRight },
    [TooltipPlacement.Right]: { x: TooltipPlacement.Left, y: TooltipPlacement.BottomLeft },
    [TooltipPlacement.CenterTop]: { x: TooltipPlacement.CenterBottom, y: TooltipPlacement.CenterBottom },
    [TooltipPlacement.CenterBottom]: { x: TooltipPlacement.CenterRight, y: TooltipPlacement.CenterRight },
    [TooltipPlacement.CenterRight]: { x: TooltipPlacement.CenterLeft, y: TooltipPlacement.CenterLeft },
    [TooltipPlacement.CenterLeft]: { x: TooltipPlacement.CenterTop, y: TooltipPlacement.CenterTop },

}

const defaultCoords: { x: number, y: number } = { x: 0, y: 0 };
const maxCountOfSideAdjust = 3;
const maxCountOfAdjust = maxCountOfSideAdjust + 4; // Side Adjustments + 4 centered

export const Tooltip = ({ children, overlay, placement, disabled, indent, className, overlayClassName, delay, trigger, hideOnAnyAction = true, onVisibleChange, ...props }: TooltipProps) => {
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const childrenRef = useRef<HTMLElement | null>(null);
    const overlayWrapperRef = useRef<HTMLElement | null>(null);
    const [visible, setVisible] = useState(props.visible);
    const [coordinates, setCoordinates] = useState<{ x: number, y: number }>(defaultCoords);
    const [placementClassName, setPlacementClassName] = useState<string>('');

    useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);

    useEffect(() => {
        if (props.visible) {
            showTooltip();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showTooltip = () => {
        const timeDelay = trigger === TooltipTriggerType.Click ? 0 : delay;
        timeout.current = setTimeout(() => {
            handleSetCoordinates(placement)
            onVisibleChange ? onVisibleChange(true) : setVisible(true);
        }, timeDelay);
    }

    const hideTooltip = useCallback(() => {
        setCoordinates(defaultCoords)
        onVisibleChange ? onVisibleChange(false) : setVisible(false)
        setPlacementClassName('')
    }, [onVisibleChange]);

    useEffect(() => {
        if (hideOnAnyAction) {
            const onMouseWheel = (a: WheelEvent) => {
                if (overlayWrapperRef.current?.contains(a?.target as Node)) return;
                hideTooltip()
            }
            if (visible) {
                window.addEventListener("wheel", onMouseWheel);
            }
            return () => {
                window.removeEventListener("wheel", onMouseWheel);
            }
        }
    }, [visible, hideTooltip, hideOnAnyAction]);

    const getMargin = (element: Element) => {
        const result = { t: 0, r: 0, b: 0, l: 0 }
        if (element) {
            const styles = window.getComputedStyle(element);
            result.t = Number(styles.getPropertyValue('margin-top').replace('px', ''));
            result.r = Number(styles.getPropertyValue('margin-right').replace('px', ''));
            result.b = Number(styles.getPropertyValue('margin-bottom').replace('px', ''));
            result.l = Number(styles.getPropertyValue('margin-left').replace('px', ''));
        }
        return result;
    }

    const handleSetCoordinates = useCallback((placement: TooltipPlacement | string, overlayWidth = 0, overlayHeight = 0, adjustmentIteration = 0) => {
        if (!childrenRef.current) return;
        adjustmentIteration += 1;
        const margin = getMargin(childrenRef.current?.children[0]);

        const { y, width, left, top, bottom } = childrenRef.current.getBoundingClientRect();

        const clientWith = document.documentElement.clientWidth + document.documentElement.scrollLeft;
        const clientHeight = document.documentElement.clientHeight + document.documentElement.scrollTop;

        const targetLeft = left + document.documentElement.scrollLeft;
        const targetTop = top + document.documentElement.scrollTop + margin.t;

        const coords = { x: targetLeft, y: y + indent };

        // Calculation coordinates by placement
        if (placement === TooltipPlacement.Bottom) {
            //Bottom
            coords.y = bottom + indent;
            coords.x = targetLeft + width / 2 - overlayWidth / 2;
        } else if (placement === TooltipPlacement.BottomRight) {
            //BottomRight
            coords.y = bottom + indent;
            coords.x = targetLeft + width - overlayWidth - margin.r;

        } else if (placement === TooltipPlacement.BottomLeft) {
            //BottomLeft
            coords.y = bottom + indent;
            coords.x = targetLeft + margin.l;
        } else if (placement === TooltipPlacement.Top) {
            //Top
            coords.y = targetTop - overlayHeight - indent;
            coords.x = targetLeft + width / 2 - overlayWidth / 2;

        } else if (placement === TooltipPlacement.TopRight) {
            //TopRight
            coords.y = targetTop - overlayHeight - indent;
            coords.x = targetLeft + width - overlayWidth - margin.r;

        } else if (placement === TooltipPlacement.TopLeft) {
            //TopLeft
            coords.y = targetTop - overlayHeight - indent;
            coords.x = targetLeft + margin.l;

        } else if (placement === TooltipPlacement.Left) {
            //Left
            coords.y = targetTop;
            coords.x = targetLeft - overlayWidth - margin.r - indent;

        } else if (placement === TooltipPlacement.Right) {
            //Right
            coords.y = targetTop;
            coords.x = targetLeft + width + margin.l + indent;
        } else if (placement === TooltipPlacement.CenterTop) {
            //Top Center
            coords.y = targetTop - overlayHeight - indent;
            coords.x = clientWith / 2 -  overlayWidth / 2;
        } else if (placement === TooltipPlacement.CenterBottom) {
            //Bottom Center
            coords.y = bottom + indent;
            coords.x = clientWith / 2 -  overlayWidth / 2;
        } else if (placement === TooltipPlacement.CenterRight) {
            //Right Center
            coords.y = clientHeight / 2 - overlayHeight / 2;
            coords.x = targetLeft + width + margin.l + indent;
        } else if (placement === TooltipPlacement.CenterLeft) {
            //Left Center
            coords.y = clientHeight / 2 - overlayHeight / 2;
            coords.x = targetLeft - overlayWidth - margin.r - indent;
        }

        //Adjust to screen coordinates
        let adjusted: TooltipPlacement | undefined;

        if (coords.y < 0 || coords.y + overlayHeight > clientHeight) { //Top or Bottom
            adjusted = adjustmentMap[placement as TooltipPlacement].y;
            if (placement === TooltipPlacement.Bottom) {
                setPlacementClassName('top-placement')
            } else {
                setPlacementClassName('bottom-placement')
            }
        }

        if (coords.x < 0 || coords.x + overlayWidth > clientWith) { //Left or Right
            adjusted = adjustmentMap[adjusted ?? placement as TooltipPlacement].x;
        }

        if (adjusted && adjustmentIteration <= maxCountOfAdjust) {
            if (adjustmentIteration === maxCountOfSideAdjust + 1) { // Set on the screen center if all adjustments are placed outside user's screen
                adjusted = TooltipPlacement.CenterLeft;
            }

             handleSetCoordinates(adjusted, overlayWidth, overlayHeight, adjustmentIteration);
             return;
        }

        setCoordinates(coords);
    }, [indent]);


    const overlayCallback = useCallback((node: HTMLDivElement | null) => {
        if (node !== null) {
            overlayWrapperRef.current = node
            const { width, height } = node.getBoundingClientRect();
            handleSetCoordinates(placement, width, height)
        }
    }, [handleSetCoordinates, placement]);

    const handleMouseEnter = () => {
        if (!childrenRef.current || disabled || !overlay || trigger !== TooltipTriggerType.Hover) return;
        showTooltip();
    }

    const handleMouseLeave = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        if (trigger !== TooltipTriggerType.Hover) return;

        hideTooltip();
    }

    const handleClick = () => {
        if (trigger === TooltipTriggerType.Click && !visible) {
            showTooltip();
        }
    }

    const handleClickOutside = (e: MouseEvent) => {
        if (overlayWrapperRef.current?.contains(e.target as Node)) {
            return;
        }

        if (hideOnAnyAction) {
            hideTooltip();
        }
    }

    const renderOverlay = () => {
        if (!overlay) return null;
        const element = (
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 0 }}>
                <div
                    className="tooltip"
                    ref={overlayCallback}
                    style={{ top: `${coordinates.y}px`, left: `${coordinates.x}px` }}
                >
                    <div className={cn(overlayClassName, placementClassName)}>
                        <div className="tooltip-inner">
                            {typeof overlay === 'function' ? overlay() : overlay}
                        </div>
                    </div>
                </div>
            </div>
        )
        return createPortal(element, document.body)
    }

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child as ReactElement, {
                ref: childrenRef,
                onMouseEnter: handleMouseEnter,
                onMouseLeave: handleMouseLeave,
                onClick: handleClick,
            });
        }
        return child;
    });

    const renderChildren = () =>
        trigger === TooltipTriggerType.Click
            ? <ClickOutside className={className} onClick={handleClickOutside}>{childrenWithProps}</ClickOutside>
            : childrenWithProps;

    return (
        <>
            {renderChildren()}
            {visible && (coordinates.x !== 0 || coordinates.y !== 0) && renderOverlay()}
        </>
    );
}

Tooltip.defaultProps = {
    placement: TooltipPlacement.BottomLeft,
    indent: 5,
    className: '',
    overlayClassName: '',
    delay: 300,
    trigger: TooltipTriggerType.Hover,
    visible: false,
}
