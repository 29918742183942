import { RequestState } from '../../../constants/request-state';
import { Preloader } from '../Preloader';
import { CompanyMemberItem } from '../../../types/state/CompanyMembersState';

interface Props {
    companyMembers: CompanyMemberItem;
}

export function MembersList({ companyMembers }: Props) {
    const isRequesting = () => {
        return companyMembers && companyMembers.requestState === RequestState.request;
    };

    const getMemberList = () => {
        if (!companyMembers) {
            return [];
        }
        const members = [...companyMembers.members || []];
        members.sort((a, b) => a.fullName.localeCompare(b.fullName));
        return members;
    };

    const renderMemberList = () => {
        return getMemberList().map((member, i) => {
            return (
                <li key={i}>
                    <span>{member.fullName || ''}</span>
                    <span><a href={`mailto:${member.email}`}>{member.email}</a></span>
                    <span>{member.phone}</span>
                </li>
            )
        });
    };

    return (
        <Preloader inProgress={isRequesting()} fullScreen={false} textOnly={true} text={'Loading...'}>
            <div className="px-talk-history-contact heading">
                <span className="px-talk-history-contact-name">Name</span>
                <span className="px-talk-history-contact-email">Email</span>
                <span className="px-talk-history-contact-phone">Phone Number</span>
            </div>
            <ul className="contacts-cards">
                {renderMemberList()}
            </ul>
        </Preloader>
    );
}
