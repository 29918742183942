import { SectionContent } from '../../amrPipeline/common/section/SectionContent';
import { TextField } from '../../common/form-fields/TextField';
import { NumberField } from '../../common/form-fields/NumberField';
import { SelectField } from '../../common/form-fields/SelectField';
import { CheckboxField } from '../../common/form-fields/CheckboxField';
import { MarkdownField } from '../../common/form-fields/MarkdownField';
import { InstitutionTypes } from '../../../types/clo-managers/InstitutionType';

export const AdditionalInformation = () => {
    return (
        <SectionContent title="Additional Information">
            <div className="content-row">
                <div className="content-col">
                    <TextField maxLength={512} label="Location" name="location" />
                    <NumberField label="CLO Issuance Since" name="cloIssuanceSince" includeThousandsSeparator={false} />
                    <NumberField label="Founded in" name="foundedIn" includeThousandsSeparator={false} />
                    <TextField maxLength={256} label="Founder" name="founder" />
                    <TextField maxLength={256} label="Parent" name="parent" />
                    <SelectField
                        withEmptyValue
                        label="Institution Type"
                        name="institutionType"
                        values={InstitutionTypes}
                        placeholder="Select Institution Type"
                        optionLabelProp="text"
                        optionKeyProp="value"
                        className="institution-type-field"
                    />
                </div>
                <div className="content-col content-col-checkbox">
                    <NumberField label="Total AUM (Parent), USD" name="totalParentAumUsd" />
                    <NumberField label="Total AUM (Parent), EUR" name="totalParentAumEur" />
                    <NumberField label="Leveraged Load AUM, USD" name="leveragedLoanAumUsd" />
                    <NumberField label="Leveraged Load AUM, EUR" name="leveragedLoanAumEur" />

                    <CheckboxField label="Own Equity" name="ownEquity" />
                    <CheckboxField label="Proprietary Analytical Tools" name="proprietaryAnalyticalTools" />
                </div>
            </div>
            <MarkdownField
                label="Investment Philosophy"
                name="investmentPhilosophy"
                wrapperClassName="data-item-row-full-height"
                maxLength={2048}
            />
            <MarkdownField
                label="Investment Model"
                name="investmentModel"
                wrapperClassName="data-item-row-full-height"
                maxLength={2048}
            />
        </SectionContent>
    );
}
