import * as React from 'react';
import IconSVG from '../../styles/svg-icons';

export function SupportInformation() {
    return (
        <ul className="support-info-list">
            <li className="text-warm-grey">For help and support please contact KopenTech via phone or email.</li>
            <li>
                <IconSVG name="phone" width="16" height="16" />
                <a href="tel:18008621684" className="link">1.800.862.1684</a>
            </li>
            <li>
                <IconSVG name="email" width="16" height="16" />
                <a href={`mailto:${process.env.REACT_APP_SALES_EMAIL}`} className="link">{process.env.REACT_APP_SALES_EMAIL}</a>
            </li>
        </ul>
    )
}
