import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RightSidePanel } from '../controls';
import { textRulesPanelActions } from '../../actions/text-rules-panel.actions';
import { AppState } from '../../types/state/AppState';
import { BwicRules } from './BwicRules';

export function BwicTextRulesPanel() {
    const textRulesPanel = useSelector((state: AppState) => state.textRulesPanel);
    const dispatch = useDispatch();

    if (!textRulesPanel.visible || textRulesPanel.params === null) {
        return null;
    }

    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        dispatch(textRulesPanelActions.hide());
    }

    return (
        <RightSidePanel className="right-panel-rules" title="Rules" onClose={handleClose}>
            <BwicRules
                isSameDayBwic={textRulesPanel.params.isSameDayBwic}
                goodUntilDate={textRulesPanel.params.goodUntilDate}
                process={textRulesPanel.params.process}
                parsedProcess={textRulesPanel.params.parsedProcess}
                bidsDueDate={textRulesPanel.params.bidsDueDate}
                isParsed={textRulesPanel.params.isParsed}
                daysToSettle={textRulesPanel.params.daysToSettle}
                isMy={false}
                isAllToAll={false}
                isDirectBidding={false}
            />
        </RightSidePanel>
    );
}
