import * as React from 'react';
import { Process } from '../../../types/models/Process';
import dateTimeUtils from '../../../utils/dateTime.utils';
import { BwicProcessType } from '../../../types/models/Process';
import { CutOffReminder } from '../CutOffReminder';
import { BwicRulesSectionRow } from './BwicRulesSectionRow';
import { BwicRulesStage } from './BwicRulesStage';
import { InfoTooltip } from "../../common/InfoTooltip";

interface BwicRulesSectionJumpBallProps {
    process: Process;
}

export function BwicRulesSectionJumpBall({ process }: BwicRulesSectionJumpBallProps) {
    if (process.type === BwicProcessType.JumpBall) {
        const stage1Duration = process.jumpBall?.automaticStage1Deadline
            ? `${dateTimeUtils.parseTimeSpan(process.jumpBall.automaticStage1Deadline).totalMinutes} minutes`
            : 'Unspecified; Seller controls finish time';
        const stage2Duration = process.jumpBall?.improvementRound
            ? `${dateTimeUtils.parseTimeSpan(process.jumpBall.improvementRound).totalMinutes} minutes`
            : "Unspecified; Seller controls finish time"

        return (
            <div className="rules-section-content">
                <BwicRulesStage
                    title="Stage 1 &#8212; Open bidding with autofeedback"
                >
                    <BwicRulesSectionRow
                        label="Stage 1 duration"
                        dataTestId="stage-1-duration"
                    >
                        {stage1Duration}
                    </BwicRulesSectionRow>
                    {
                        Boolean(process.jumpBall?.automaticStage1Deadline) &&
                        <CutOffReminder process={process} />
                    }
                    <BwicRulesSectionRow label="# of Stage 2 participants">
                        <>
                            Top {process.jumpBall?.improverCount} + ties
                            <InfoTooltip text={`All participants whose bids are at the Top ${process.jumpBall?.improverCount} level will go to Stage 2 and will have a chance to improve the bid.`} />
                        </>
                    </BwicRulesSectionRow>
                    <BwicRulesSectionRow label="Bid improvement">unlimited</BwicRulesSectionRow>
                    <BwicRulesSectionRow label="Bid feedback">auto/manual</BwicRulesSectionRow>
                </BwicRulesStage>
                <BwicRulesStage
                    title="Stage 2 &#8212; Jump Ball, a silent stage with one time to improve your bid"
                >
                    <h5>Bids from Stage 1 are carried to Stage 2 and bidders have one time to improve, if they choose. However, the seller is notified if the buyer is bidding over themselves and has the option to trade at the lower bid. Results are announced when all bidders submit bids in Stage 2 or when Seller closes BWIC via trading or DNT.</h5>
                    <BwicRulesSectionRow label="Stage 2 duration" dataTestId="stage-2-duration">
                        {stage2Duration}
                    </BwicRulesSectionRow>
                    <BwicRulesSectionRow label="Bid improvement">once</BwicRulesSectionRow>
                    <BwicRulesSectionRow label="Bid feedback">manual</BwicRulesSectionRow>
                </BwicRulesStage>
            </div>
        )
    } else return null;
}
