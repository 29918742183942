import { useMemo } from 'react';
import { DashboardNoSelectedFilters } from '../../../../dashboard/DashboardNoSelectedFilters';
import { CloManagerArrangerStatsBarChart } from './CloManagerArrangerStatsBarChart';
import { CloManagerStats } from '../../../../../types/clo-managers/CloManagerStats';
import { user } from '../../../../../user';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';

interface CloManagerArrangerStatsWidgetProps {
    stats: CloManagerStats;
    onChartClick?: (companyReferenceName: string, transactionType: string) => void;
}

export function CloManagerArrangerStatsWidget({ stats, onChartClick }: CloManagerArrangerStatsWidgetProps) {
    const hasImAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);

    const chart = useMemo(() => {
        return <CloManagerArrangerStatsBarChart data={stats.arrangersDeals} onClick={hasImAccess ? onChartClick : undefined} />
    }, [stats.arrangersDeals, onChartClick, hasImAccess]);

    return (
        <div className="dashboard-cnt-box half-size with-border im-chart arrangers-chart">
            <div className="heading">
                <h3>
                    Arrangers<span>Deal Count</span>
                </h3>
            </div>
            {!!stats.arrangersDeals.length ? chart : <DashboardNoSelectedFilters text="No data available" />}
        </div>
    );
}
