import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../access/ActionBlocker';
import { AlertsButton } from '../common/AlertsButton';

interface Props {
    isAlertOn: boolean;
    label?: string;
    onClick: () => void;
}

export const CloManagerAlertsButton = ({
    isAlertOn,
    label,
    onClick
}: Props) => {
    return (
        <ActionBlocker noTooltip features={[
            SubscriptionFeature.ManagerProfileManagerRelatedAlerts,
            SubscriptionFeature.IssuanceMonitorDealUpdateEmailAlerts,
            SubscriptionFeature.BwicMonitorAlerts
        ]}>
            {blocked => (
                <div className="email-alert-wrap">
                    <AlertsButton
                        isSelected={isAlertOn && !blocked}
                        onClick={onClick}
                        label={label}
                    />
                </div>
            )}
        </ActionBlocker>
    );
};
