import { formatUtils } from '../../../utils/format.utils';
import { BwicProcessType, Process } from '../../../types/models/Process';
import { levelSpecifications } from '../../../constants/level.specifications';
import { Pluralize } from '../../common/Pluralize';
import { CutOffReminder } from '../CutOffReminder';
import { BwicRulesSectionRow } from './BwicRulesSectionRow';
import { BwicRulesStage } from './BwicRulesStage';
import { InfoTooltip } from "../../common/InfoTooltip";

interface BwicRulesSectionOpenBiddingProps {
    process: Process;
}

export function BwicRulesSectionOpenBidding({ process }: BwicRulesSectionOpenBiddingProps) {
    if (process.type !== BwicProcessType.Live || process.liveBidding == null) {
        return null;
    }

    const levelSpecification = levelSpecifications.getByKey(process.liveBidding!.levelSpecificationType);
    const improverCount = `Top ${process.liveBidding?.openBiddingImproverCount}`;
    const stage1Deadline = Number(process.liveBidding!.stage1Deadline);
    const improvementRound = Number(process.liveBidding!.improvementRound);
    const minBidIncrement = formatUtils.formatBid(process.liveBidding!.minBidIncrement);

    return (
        <div className="rules-section-content">
            <h5>Bidding is open for platform participants only</h5>
            <BwicRulesStage title="Stage 1 - Blind Bidding">
                <BwicRulesSectionRow label="Stage 1 duration" dataTestId="stage-1-duration">
                    {stage1Deadline}&nbsp;<Pluralize count={stage1Deadline} singular="minute" template={'{{text}}'} />
                </BwicRulesSectionRow>
                <CutOffReminder process={process} />
                <BwicRulesSectionRow label="# of Stage 2 participants" dataTestId="#-of-stage-2-participants">
                    <>
                        {improverCount} + ties
                        <InfoTooltip text={`All participants whose bids are at the ${improverCount} level will go to Stage 2 and will have a chance to improve the bid.`} />
                    </>
                </BwicRulesSectionRow>
                <BwicRulesSectionRow label="Bid improvement">unlimited</BwicRulesSectionRow>
                <BwicRulesSectionRow label="Bid feedback">no</BwicRulesSectionRow>
            </BwicRulesStage>
            <BwicRulesStage title="Stage 2 - LiveBidding&#8482;">
                <BwicRulesSectionRow label="Improvement Round Duration" dataTestId="improvement-round-duration">
                    {improvementRound}&nbsp;<Pluralize count={improvementRound} singular="minute" template={'{{text}}'} />
                </BwicRulesSectionRow>
                <BwicRulesSectionRow label="Minimum Bid Increment" dataTestId="minimum-bid-increment">
                    {minBidIncrement}
                </BwicRulesSectionRow>
                <BwicRulesSectionRow label="Price Type" dataTestId="price-type">
                    {levelSpecification && levelSpecification.title}
                </BwicRulesSectionRow>
                <BwicRulesSectionRow label="Bid improvement">unlimited</BwicRulesSectionRow>
                <BwicRulesSectionRow label="Bid feedback">auto</BwicRulesSectionRow>
            </BwicRulesStage>
        </div>
    );
}
