import * as React from 'react';
import { Process } from '../../../types/models/Process';
import dateTimeUtils from '../../../utils/dateTime.utils';
import { BwicProcessType } from '../../../types/models/Process';
import { CutOffReminder } from '../CutOffReminder';
import { BwicRulesSectionRow } from './BwicRulesSectionRow';
import { BwicRulesStage } from './BwicRulesStage';

interface BwicRulesBestFootForwardProps {
    process: Process;
}

export function BwicRulesBestFootForward({ process }: BwicRulesBestFootForwardProps) {
    if (process.type === BwicProcessType.BestFootForward) {
        return (
            <div className="rules-section-content">
                <BwicRulesStage
                    title='One stage only. Bidder can improve bids during the bidding period.'
                >
                    <BwicRulesSectionRow
                        label="Bids submission duration"
                    >
                        {dateTimeUtils.parseTimeSpan(process.bff?.stage1Deadline).totalMinutes} minutes
                    </BwicRulesSectionRow>
                    <CutOffReminder process={process} />
                </BwicRulesStage>
            </div>
        )
    } else return null;
}
