import React, { useEffect, useState, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ClickOutside } from './ClickOutside';

interface Props {
    visible: boolean;
    onHide: (e: MouseEvent) => void;
    className: string;
    timeout?: number
}

export const SlideDownPanel: React.FC<PropsWithChildren<Props>> = ({ visible, children, onHide, className, timeout = 500 }) => {
    const [internalVisible, setInternalVisible] = useState(visible);

    useEffect(() => {
        if (visible !== internalVisible) {
            setTimeout(() => setInternalVisible(visible), timeout);
        }
    }, [visible, internalVisible, timeout]);

    const panelClassNames = classNames(className, { 'show': visible });

    return (
        <div className={panelClassNames}>
            {
                (visible || internalVisible) &&
                <ClickOutside onClick={onHide}>{children}</ClickOutside>
            }
        </div>
    );
};
