import { createContext, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { v4 as uuid } from 'uuid';

type TVisibilityChangeCallback = (visible: boolean) => void

interface IContext {
    observe: (target: Element, onVisibilityChange: TVisibilityChangeCallback) => void
    unobserve: (target: Element) => void
}

export const IntersectionObserverContext = createContext<IContext | null>(null);

interface Props {
    disabled?: boolean;
    options?: IntersectionObserverInit;
    children: React.ReactNode;
}
export function IntersectionObserverProvider({ disabled, options, children }: Props) {
    const handler = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(e => {
            const visible = typeof e.isIntersecting === 'undefined'
                ? e.intersectionRatio > 0
                : e.isIntersecting;

            observableHandlers.current.get(e.target.id)?.(visible);
        });
    }

    const observerRef = useRef<IntersectionObserver>(new IntersectionObserver(handler, options));
    const observableHandlers = useRef(new Map<string, TVisibilityChangeCallback>());

    useEffect(() => {
        const observer = observerRef.current;
        return () => {
            observer?.disconnect();
        }
    }, [])

    if(disabled) return <>{children}</>

    const observe = (target: Element, onVisibilityChange: TVisibilityChangeCallback) => {
        if (!target.id) throw new Error("element.id is required");

        observableHandlers.current.set(target.id, onVisibilityChange);
        observerRef.current.observe(target)
    }

    const unobserve = (target: Element) => {
        if (!observerRef.current) return;

        observableHandlers.current.delete(target.id);
        observerRef.current.unobserve(target);
    }

    return (
        <IntersectionObserverContext.Provider value={{ observe, unobserve }}>
            {children}
        </IntersectionObserverContext.Provider>
    )
}

export function useIntersectionObserver(elementRef?: React.RefObject<HTMLElement>){
    const ioContext = useContext(IntersectionObserverContext);
    const idRef = useRef(ioContext ? uuid() : undefined);

    const [visible, setVisible] = useState(!ioContext);

    useLayoutEffect(() => {
        const element = elementRef?.current;
        if (ioContext && element) {
            ioContext.observe(element, setVisible);
        }

        return () => {
            if (ioContext && element) {
                ioContext.unobserve(element);
            }
        };
        // eslint-disable-next-line
    }, []);

    return {id: idRef.current, visible };
}