import React from 'react';

interface CloManagersContextProps {
    canEdit: boolean;
    canNavigateToIM: boolean;
    ownCollateralManagersCompany: boolean;
    ownCompany: boolean;
    hasAnalyticsAccess: boolean;
}

const CloManagersContext = React.createContext<CloManagersContextProps>({
    canEdit: false,
    canNavigateToIM: false,
    ownCollateralManagersCompany: false,
    ownCompany: false,
    hasAnalyticsAccess: false,
});

export default CloManagersContext;
