import { FlexRow } from "../../common/FlexRow";

interface BwicRulesSectionRowProps {
    label?: string;
    visible?: boolean;
    children?: React.ReactNode;
    dataTestId?: string;
}

export const BwicRulesSectionRow = ({ label, visible = true, children, dataTestId }: BwicRulesSectionRowProps) => {
    if (!visible) return null;
    return (
        <FlexRow>
            {Boolean(label) && <div className="rules-label">{label}</div>}
            <div className="rules-value" data-testid={dataTestId}>{children}</div>
        </FlexRow>
    )
}
