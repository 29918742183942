import moment from 'moment';
import { routes } from '../../constants';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../access/ActionBlocker';
import { FeatureLink } from '../access/FeatureLink';

interface Props {
    ticker: string
    text?: string
}

export const BwicMonitorLookupLink = ({ ticker, text = "View BWIC" }: Props) => {
    return (
        <ActionBlocker feature={SubscriptionFeature.BwicMonitorSecuritySearch}>
            <FeatureLink
                target="_blank"
                path={`${routes.allBWICs}?isinCusipsAndTickers=${ticker}&dateFrom=${moment().format('YYYY-M-D')}`}
                text={text}
            />
        </ActionBlocker>
    );
};
