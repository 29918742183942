import React from 'react';
import classNames from 'classnames';

export function Relative({ className = '', inline = false, children, ...props }) {
    return (
        <div {...props} className={classNames('position-relative', { 'display-inline': inline}, className)}>
            {children}
        </div>
    );
}
