import { useContext, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import cn from 'classnames';
import { routes } from "../../constants";
import { AppState } from "../../types/state/AppState";
import { useHistory, useParams } from "react-router";
import Header from './Header';
import { CloManagerTabTypes, CloManagersTabType } from '../../types/clo-managers/CloManagersTabType';
import { cloManagersActions } from '../../actions/clo-managers.actions';
import { CloManagersEdit } from './CloManagersEdit';
import { Formik } from 'formik';
import { gridActions } from '../../actions';
import { CloManagerValidationSchema } from './CloManagerValidationSchema';
import { CloManager } from '../../types/clo-managers/CloManager';
import { TabList, TabItem } from '../bidding/common/tab-list';
import { TabContent } from '../amrPipeline/common/tabs/TabContent';
import { Overview } from './tabs/overview/Overview';
import { Deals } from './tabs/deals/Deals';
import { CloTeam } from './tabs/cloTeam/CloTeam';
import { AdditionalInformation } from './tabs/AdditionalInformation';
import { Preloader } from '../common';
import { TabBody } from '../amrPipeline/common/tabs/TabBody';
import { CloManagerAccessType } from '../../types/amr-pipeline/enums/CloManagerAccessType';
import { CloManagerSaveForm } from "../../types/clo-managers/CloManagerSaveForm";
import { Analytics } from './tabs/analytics/Analytics';
import CloManagersContext from './cloManagersContext';
import { useAppDispatch } from "../../effects/useAppDispatch";

const EditTab = 'edit';

interface CLOManagerRouteParams {
    referenceName: string;
    tab?: CloManagersTabType | typeof EditTab;
}

interface CloManagersDetailedProps {
    selectedManager: CloManager;
}

const TabTypeToAccessType = {
    [CloManagersTabType.AdditionalInformation]: CloManagerAccessType.AdditionalInformationTab,
    [CloManagersTabType.CloTeam]: CloManagerAccessType.CloTeamTab,
    [CloManagersTabType.Deals]: CloManagerAccessType.DealTab,
    [CloManagersTabType.Overview]: CloManagerAccessType.OverviewTab,
};

export const CloManagersDetails = ({ selectedManager }: CloManagersDetailedProps) => {
    const { hasAnalyticsAccess } = useContext(CloManagersContext);
    const { referenceName, tab } = useParams<CLOManagerRouteParams>();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const submitHandler = useRef<() => Promise<any>>();

    const isLoading = useSelector((state: AppState) => state.cloManagers.isLoading);
    const editManager = useSelector((state: AppState) => state.cloManagers.editManager);

    const selectedTab = tab || CloManagersTabType.Overview;

    useEffect(() => {
        // This is to prevent double logging, when switching between
        // managers and selected tab is not Overview
        if (selectedManager.referenceName !== referenceName) {
            return;
        }

        if (selectedTab === EditTab || selectedTab === CloManagersTabType.Analytics) {
            return;
        }

        dispatch(cloManagersActions.logUserActivity(selectedManager.referenceName, TabTypeToAccessType[selectedTab]));
    }, [dispatch, referenceName, selectedManager.referenceName, selectedTab]);

    const accessibleTabs = useMemo(() => CloManagerTabTypes.filter(tabType =>
        tabType.value === CloManagersTabType.Analytics ? hasAnalyticsAccess : true
    ), [hasAnalyticsAccess]);

    const renderActiveTab = () => {
        switch (tab) {
            case CloManagersTabType.Deals:
                return <Deals />;
            case CloManagersTabType.CloTeam:
                return <CloTeam />;
            case CloManagersTabType.AdditionalInformation:
                return <AdditionalInformation />;
            case CloManagersTabType.Analytics:
                return <Analytics />;
            default:
                return <Overview />;
        }
    };

    const handleTabChange = (tab: CloManagersTabType) => {
        return history.replace(routes.manageCloManagerUrl(referenceName, tab));
    };

    const handleEdit = () => {
        return history.replace(routes.manageCloManagerUrl(referenceName, EditTab));
    };

    const handleCancel = () => {
        return history.replace(routes.manageCloManagerUrl(referenceName, CloManagersTabType.Overview));
    };

    const handleSave = () => {
        if (submitHandler.current) {
            submitHandler.current();
        }
    };

    const handleSubmit = (cloManagerForm: CloManagerSaveForm) => {
        const { isUSASelected, ...cloManager} = cloManagerForm;

        dispatch(gridActions.validate());
        dispatch(cloManagersActions.createOrUpdateCloManager(referenceName, cloManager));
    };

    return (
        <>
            <Header
                company={selectedManager}
                edit={selectedTab === EditTab}
                onEdit={handleEdit}
                onCancel={handleCancel}
                onSave={handleSave}
            />
            {selectedTab === EditTab && editManager &&
                <Formik
                    initialValues={editManager}
                    onSubmit={handleSubmit}
                    validationSchema={CloManagerValidationSchema}
                    initialTouched={['newCity']}
                >
                    <CloManagersEdit
                        setSubmitHandler={(handler) => submitHandler.current = handler}
                    />
                </Formik>
            }
            {selectedTab !== EditTab &&
                <>
                    <TabList className="tabs-left-shift">
                        {accessibleTabs.map(({ value, text }) => (
                            <TabItem
                                key={value}
                                active={tab === value}
                                title={text}
                                onClick={() => handleTabChange(value)}
                            />
                        ))}
                    </TabList>
                    <TabContent>
                        <TabBody className={cn("tabs-data-padding", {"tabs-data-deals": selectedTab === CloManagersTabType.Deals})}>
                            {isLoading ? <Preloader inProgress={true} /> : renderActiveTab()}
                        </TabBody>
                    </TabContent>
                </>
            }
        </>
    );
};
