import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import IconSVG from '../../styles/svg-icons';

export function SearchInput({ value, onChange, onClear = () => {}, disabled = false, placeholder = 'Search', maxLengthValue = 1000, small = false, ...props }) {
    const refInput = useRef(null);
    const timeout = useRef(0);
    const [currentValue, setCurrentValue] = useState('');
    const [inputFocus, setInputFocus] = useState(false);

    useEffect(() => {
        setCurrentValue(value)
    }, [value]);

    function handleChange(e) {
        const changedValue = e.target.value;
        setCurrentValue(() => {
            if (changedValue.length <= maxLengthValue && typeof onChange === 'function') {
                if (timeout.current) {
                    clearTimeout(timeout.current)
                }
                timeout.current = setTimeout(() => { onChange(changedValue)}, 200)
            }
            return changedValue
        });
    }

    function handleClear() {
        if (refInput.current) {
            refInput.current.focus();
        }
        onClear && onClear();
    }

    return (
        <div className={classNames('control-search-wrapper flex-none', {'control-search-focus': inputFocus, 'small-size': small, disabled})}>
            <div className="form-control-search-btn">
                <i className="icon icon-search" />
            </div>
            <input
                className="form-control form-control-search"
                ref={refInput}
                type="text"
                placeholder={placeholder}
                value={currentValue}
                onChange={handleChange}
                onFocus={() => setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
                disabled={disabled}
                {...props}
            />
            {
                !!value &&
                <button className="btn-close" onClick={handleClear}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
            }
        </div>
    );
}
