import IconSVG from "../../styles/svg-icons";

interface Props {
    isSelected: boolean;
    onClick: () => void;
    disabled?: boolean;
    label?: string;
}

export const AlertsButton = ({
    isSelected,
    onClick,
    disabled,
    label,
}: Props) => (
    <button
        className="btn-link"
        disabled={disabled}
        onClick={(e) => {
            e.stopPropagation();
            onClick();
        }}
    >
        <IconSVG
            name={isSelected ? "alert" : "alert-off"}
            width="16"
            height="16"
        />
        {label}
    </button>
);
