import React, { useEffect, useRef, useState } from 'react';
import { RightSideTabPanel } from '../../../controls/RightSideTabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { companyDetailsActions } from '../../../../actions/company-details.actions';
import Confirm from '../../../alerts/Confirm';
import { clientsCompaniesActions } from '../../../../actions/clients-companies.actions';
import { isRequesting } from '../../../../utils';
import { TradingLimitPanelContent } from './TradingLimitPanelContent';
import { CompanyDetailsPanelContent } from '../../../company-details/CompanyDetailsPanelContent';
import { RouteLeavingGuard } from '../../../routing';
import { useHistory } from 'react-router';
import { SettlementAgentClientAgreement } from '../../../../types/bid-as-dealer/SettlementAgentClientAgreement';

interface Props {
    agreement: SettlementAgentClientAgreement
}
export const ClientsControlPanel: React.FC<Props> = ({ agreement }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isTradingLimitChanged = useRef(false);

    const saveTradingLimitsRequestState = useSelector((s: AppState) => s.clientsCompanies.requestStatusUpdateRequest);
    const currentCompanyDetailsId = useSelector((state: AppState) => state.companyDetails.currentCompanyId);
    const company = useSelector((state: AppState) => state.companyDetails.companies[currentCompanyDetailsId]);

    const [visibleConfirmationPopup, setVisibleConfirmationPopup] = useState(false);

    const isSaving = isRequesting(saveTradingLimitsRequestState[Number(agreement?.signatory?.id)]);

    useEffect(() => {
        isTradingLimitChanged.current = false;
        // eslint-disable-next-line
    }, [agreement])

    const handleHideConfirmationPopup = () => {
        setVisibleConfirmationPopup(false);
        dispatch(clientsCompaniesActions.resetSelectedAgreement());
        dispatch(companyDetailsActions.hide());
        isTradingLimitChanged.current = false;
    };

    const handleShowConfirmationPopup = () => {
        if (!isSaving) {
            if (isTradingLimitChanged.current) {
                setVisibleConfirmationPopup(true)
            } else {
                handleHideConfirmationPopup();
            }
        }
    };

    const tabs = [
        {
            title: 'Trading Limit',
            active: !!agreement && !currentCompanyDetailsId,
            renderContent: () => (
                <TradingLimitPanelContent
                    onCancel={handleShowConfirmationPopup}
                    onTrackChanges={(isChanged) => isTradingLimitChanged.current = isChanged}
                    company={agreement}
                />
            ),
            onActivate: () => { },
            onDeactivate: () => { }
        },
        {
            title: 'Company',
            active: !!currentCompanyDetailsId,
            renderContent: () => <>{company != null && <CompanyDetailsPanelContent company={company} />}</>,
            onActivate: () => agreement && dispatch(companyDetailsActions.show(agreement.signatory, undefined, 0)),
            onDeactivate: () => dispatch(companyDetailsActions.hide())
        }
    ];

    return (
        <>
            <RightSideTabPanel
                className="right-panel-tabs edit-companies-limits"
                title={agreement?.signatory.name}
                items={tabs}
                onClose={handleShowConfirmationPopup}
            />
            {
                visibleConfirmationPopup &&
                <Confirm
                    confirmButtonText="Confirm"
                    onCancel={() => setVisibleConfirmationPopup(false)}
                    onConfirm={handleHideConfirmationPopup}
                    title="Confirm changes discard?"
                    text="No changes will be saved. Discard changes?"
                />
            }
            <RouteLeavingGuard
                navigate={(pathname: string) => history.push(pathname)}
                shouldBlockNavigation={() => isTradingLimitChanged.current}
            />
        </>
    )
}
