import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { constants } from "../../constants";
import { Chip } from "../controls";
import { validateEmail } from '../../utils/validate-email.utils'
import IconSVG from '../../styles/svg-icons';

const INITIAL_INPUT_WIDTH = 320;
const MIN_INPUT_WIDTH = 32;
const INPUT_INDENT = 12;

export function EmailChipList({
    disabled = false,
    onChange,
    allowInvalid = false,
    withClearAll = false,
    placeholder = 'You can copy and paste a list of emails here',
    className,
    defaultItems = [],
}) {
    const refInterval = useRef(null);
    const refInput = useRef(null);
    const refInvalidEmailInput = useRef([]);
    const refInvalidEmailSpan = useRef([]);
    const [items, setItems] = useState(defaultItems);
    const [selectedItem, setSelectedItem] = useState(null);
    const [value, setValue] = useState('');
    const [inputBgOpacity, setInputBgOpacity] = useState(0);
    const [inputWidth, setInputWidth] = useState(INITIAL_INPUT_WIDTH);

    const displayClearAll = !!(withClearAll && (value || items.length));
    const isValidEmail = validateEmail(value);

    useEffect(() => {
        return () => {
            if (refInterval.current) {
                clearInterval(refInterval.current)
            }
        } // eslint-disable-next-line
    }, []);

    useEffect(() => resetInputWidth(), [value]);

    useEffect(() => {
        if (inputBgOpacity <= 0 && refInterval.current) {
            clearInterval(refInterval.current);
        }
    }, [inputBgOpacity])

    useEffect(() => {
        onChange && onChange(items);
        if (allowInvalid && refInvalidEmailInput?.current?.some(el => el?.isEditing)) {
            return;
        }
        if (items.length) {
            resetInputWidth();
        } else {
            setInputWidth(INITIAL_INPUT_WIDTH);
        } // eslint-disable-next-line
    }, [items]);

    useEffect(() => {
        if (!inputWidth) {
            resetInputWidth();
        }
    }, [inputWidth]);

    const resetInputWidth = () => {
        if (refInput.current) {
            setInputWidth(Math.max(MIN_INPUT_WIDTH, refInput.current.scrollWidth));
        }
    };

    const setFocus = () => {
        refInput.current && refInput.current.focus();
    }

    const highlightInput = () => {
        setInputWidth(0);
        setInputBgOpacity(1);
        if (refInterval.current) {
            clearInterval(refInterval.current);
        }
        refInterval.current = setInterval(() => {
            setInputBgOpacity(opacity => Math.max(0, opacity - 0.05));
        }, 50)
    }

    const addItem = item => setItems(items => [...items, item.toLowerCase()]);

    const removeItem = index => {
        if (items[index]) {
            setItems(items.filter((item, i) => i !== index));
            setFocus();
        }
    }

    const addToChipList = () => {
        if (isValidEmail || (allowInvalid && value)) {
            addItem(value);
            setValue('');
            setSelectedItem(null);
            setInputWidth(MIN_INPUT_WIDTH);
        } else if (value) {
            highlightInput();
        }
    }

    const handleClickContainer = e => {
        e.stopPropagation();
        setFocus();
        setSelectedItem(null);
    }
    const handleClickInput = () => setSelectedItem(null);

    const handleClickItem = index => {
        setSelectedItem(index);
        setFocus();
    }

    const handleChange = e => {
        if (e.target.value.length - value.length > 1) {
            const list = e.target.value.split(/[\s,;]+/);
            let added = false;
            for (let i = 0; i < list.length; i++) {
                if (list[i].search(constants.email) === 0 || (allowInvalid && list[i])) {
                    addItem(list[i]);
                    added = true;
                }
            }
            if (added) {
                setValue('');
            }
        } else {
            setValue(e.target.value.replace(/\s+/g, ''));
        }

        if (isValidEmail) {
            onChange([...items, value]);
        }
    }

    const handleKeyDown = e => {
        const isNavEnabled = e.target.selectionStart === 0 && e.target.selectionEnd === 0;
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            addToChipList();
        } else if (isNavEnabled && e.key === 'ArrowLeft') {
            if (selectedItem > 0) {
                setSelectedItem(selectedItem - 1);
            } else if (selectedItem === null) {
                setSelectedItem(items.length - 1);
            }
        } else if (e.key === 'ArrowRight') {
            if (isNavEnabled && selectedItem !== null && selectedItem < items.length - 1) {
                e.preventDefault();
                setSelectedItem(selectedItem + 1);
            } else {
                setSelectedItem(null);
            }
        } else if ((e.key === 'Delete' || e.key === 'Backspace') && isNavEnabled) {
            e.preventDefault();
            if (items[selectedItem]) {
                removeItem(selectedItem);
            } else if (selectedItem === null && items.length) {
                removeItem(items.length - 1);
            }
            if (selectedItem >= items.length - 1) {
                setSelectedItem(null);
            }
        }
    }

    const handleDeleteItem = item => removeItem(item);

    const switchEditMode = (index, isEditing) => {
        if (refInvalidEmailInput.current) {
            refInvalidEmailInput.current[index].isEditing = isEditing;
        }
    }

    const handleEdit = (e, index) => {
        const { value } = e.target;

        if (!value) {
            return removeItem(index);
        }

        setItems(prevValues =>
            [...prevValues.slice(0, index), value.toLowerCase(), ...prevValues.slice(index + 1)]
        );
        switchEditMode(index, true);
    }

    const handleEditKeydown = (e, index) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setSelectedItem(index === selectedItem ? null : index);
            switchEditMode(index, false);
        }
    }

    const handleEditClick = (e) => {
        e.stopPropagation();
        setSelectedItem(null);
    }

    const handleEditOnBlur = index => {
        switchEditMode(index, false);
        setSelectedItem(index);
    }

    const handleClearAll = () => {
        if (onChange) {
            onChange([]);
        }
        setItems([]);
        setValue('');
    }

    const renderItems = () => {
        return items.map((item, i) => {
            const isValid = validateEmail(item);
            const isEditing = refInvalidEmailInput?.current[i]?.isEditing;
            return allowInvalid && (!isValid || isEditing) ? (
                <div className="invalid-email" key={`invalid-email-${i}`}>
                    <span
                        ref={(node) => refInvalidEmailSpan.current[i] = node}
                        className="invalid-email-hidden"
                    >
                        {item}
                    </span>
                    <input
                        ref={(node) => refInvalidEmailInput.current[i] = node}
                        style={{ width: refInvalidEmailSpan?.current[i]?.offsetWidth || "auto" }}
                        onClick={handleEditClick}
                        onChange={(e) => handleEdit(e, i)}
                        onKeyDown={(e) => handleEditKeydown(e, i)}
                        onBlur={() => handleEditOnBlur(i)}
                        value={item}
                        onDoubleClick={() => removeItem(i)}
                        className="invalid-email-input"
                    />
                </div>
            ) : (
                <Chip
                    key={`${i}-${item}`}
                    text={item}
                    selected={i === selectedItem}
                    onClick={() => handleClickItem(i)}
                    onDelete={() => handleDeleteItem(i)}
                    disabled={disabled}
                />
            )
        });
    }

    return (
        <div className={classNames("email-chips-list-wrap", className)}>
            <div className="email-chips-list" onClick={handleClickContainer}>
                {renderItems()}
                <div className="email-chips-list-input" style={{ width: inputWidth + INPUT_INDENT }}>
                    <div className="email-chips-list-input-bg" style={{ opacity: inputBgOpacity, width: inputWidth }} />
                    <textarea
                        className="email-chips-list-input-field"
                        style={{ width: inputWidth + INPUT_INDENT }}
                        ref={refInput}
                        rows="1"
                        value={value}
                        placeholder={items.length ? '' : placeholder}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onClick={handleClickInput}
                        disabled={disabled}
                        onBlur={() => addToChipList()}
                    />
                </div>
            </div>
            {displayClearAll && (
                <div className="email-chips-list-clear">
                    <button
                        className="btn-link"
                        disabled={disabled}
                        onClick={handleClearAll}
                    >
                        <IconSVG name="close" width={16} height={16} />
                    </button>
                </div>
            )}
        </div>
    );
}
