import React, { useEffect, useRef, useState, PropsWithChildren, ReactElement } from 'react';

interface DragItem {
    item: any;
    nodeIndex: number;
}

interface DraggableProps {
    list: any[];
    dragOverClassName?: string;
    onChange: (list: any[]) => void;
}

export const Sortable: React.FC<PropsWithChildren<DraggableProps>> = ({
    list,
    children,
    dragOverClassName,
    onChange
}) => {
    const [internalList, setInternalList] = useState<DragItem[]>([]);
    const [dragging, setDragging] = useState(false);

    const transformToInternal = (list: any[]) => list.map((item, nodeIndex) => ({ item, nodeIndex }));

    useEffect(() => {
        setInternalList(transformToInternal(list));
    }, [setInternalList, list]);

    useEffect(() => {
        lastAppliedList.current = internalList;
    }, [internalList]);

    const dragItem = useRef<number>();
    const dragItemNode = useRef<EventTarget>();
    const lastAppliedList = useRef<DragItem[]>(list);

    const handleDragEnd = (e: DragEvent) => {
        e.preventDefault();

        dragItem.current = undefined;

        if (dragItemNode.current) {
            dragItemNode.current.removeEventListener(
                "dragend",
                handleDragEnd as EventListener
            );
            dragItemNode.current = undefined;
        }

        const newList = lastAppliedList.current.map(({ item }) => {
            const { draggable, ...listProps } = item;
            return listProps;
        })

        setDragging(false);
        setInternalList(transformToInternal(newList));
        onChange(newList);
    };

    const handletDragStart = (e: DragEvent, itemIndex: number) => {
        if (!e.target || !(e.target as HTMLElement).draggable) {
            return;
        }

        e.target.addEventListener("dragend", handleDragEnd as EventListener);

        dragItem.current = itemIndex;
        dragItemNode.current = e.target;

        setTimeout(() => {
            setDragging(true);
        }, 0);
    };

    const handleDragEnter = (e: DragEvent, targetIndex: number) => {
        // To prevent dragging outside `fixed` columns, that are usually
        // at the top and bottom of the list
        if (!(e.target as HTMLElement).draggable) {
            return;
        }

        if (dragItemNode.current === e.target) {
            return;
        }

        setInternalList((oldList) => {
            if (dragItem.current === undefined) {
                return oldList;
            }

            const results = oldList.slice();
            const firstItem = oldList[targetIndex];
            results[targetIndex] = oldList[dragItem.current];
            results[dragItem.current] = firstItem;

            dragItem.current = targetIndex;

            return results;
        });
    };

    const getStyles = (itemIndex: number) => {
        const { current } = dragItem;

        if (current && current === itemIndex) {
            return dragOverClassName;
        }

        return null;
    };

    const wrapChild = (child: React.ReactNode, itemIndex: number) => {
        if (React.isValidElement(child)) {
            const { draggable } = child.props;

            return React.cloneElement(child as ReactElement, {
                draggable: draggable !== undefined ? draggable : true,
                onDragOver: (e: DragEvent) => e.preventDefault(),
                onDragStart: (e: DragEvent) => handletDragStart(e, itemIndex),
                onDragEnter: dragging
                    ? (e: DragEvent) => handleDragEnter(e, itemIndex)
                    : null,
                className: getStyles(itemIndex)
            });
        }

        return child;
    };

    if (!list) {
        return null;
    }

    return (
        <>
            {internalList.map((item, itemIndex) =>
                wrapChild(React.Children.toArray(children)[item.nodeIndex], itemIndex)
            )}
        </>
    );
};
