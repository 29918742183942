import * as React from 'react';

type TRenderChildren = (visible: boolean) => React.ReactNode;

interface Props {
    disabled?: boolean;
    enabledUnloading?: boolean;
    children: React.ReactNode | TRenderChildren;
    className?: string;
    onVisibleChange?: (visible: boolean) => void;
}

export function LazyLoad({ children, disabled = false, enabledUnloading = false, className, onVisibleChange }: Props) {
    const [inViewport, setInViewport] = React.useState(false);
    const targetRef = React.createRef<HTMLDivElement>();
    const observer = React.useRef<IntersectionObserver>();

    const renderChildren = () => {
        if (typeof children === 'function') {
            return children(inViewport);
        }

        if (inViewport || disabled) {
            return children as React.ReactNode;
        }
    }

    React.useEffect(() => {
        const handleIntersection = (entries: any) => {
            const entry = entries[0] || {};
            const { isIntersecting, intersectionRatio } = entry;
            const intersected = typeof isIntersecting === 'undefined' ? intersectionRatio > 0 : isIntersecting;

            if (intersected && !inViewport) {
                setInViewport(true);
                onVisibleChange?.(true);

                if (observer.current) {
                    observer.current.disconnect();
                }
            } else if(enabledUnloading && !intersected && inViewport) {
                setInViewport(false);
                onVisibleChange?.(false);

                if (observer.current) {
                    observer.current.disconnect();
                }
            }
        }

        if (!disabled && targetRef.current) {
            observer.current = new IntersectionObserver(handleIntersection);
            observer.current.observe(targetRef.current);
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        } // eslint-disable-next-line
    }, [enabledUnloading, targetRef, disabled, inViewport]);

    return <div ref={targetRef} className={className}>{renderChildren()}</div>
}
