import * as React from 'react';
import { SettlementAgentClientAgreement } from '../../../types/bid-as-dealer/SettlementAgentClientAgreement';
import { SettlementAgreementStatus } from '../../../types/bid-as-dealer/SettlementAgreementStatus';
import { Popover } from '../../controls';

interface Props {
    entity: SettlementAgentClientAgreement
}

export function BrokerDealerAgreementStatusLabel({ entity }: Props) {
    switch (entity.agreementStatus) {
        case SettlementAgreementStatus.pending:
            return <span className="status status-pending">Requested</span>;
        case SettlementAgreementStatus.confirmed:
            return <span className="status status-confirmed">Approved</span>;
        default:
            return (
                <Popover
                    title="Suspension Reason"
                    className="popover-md"
                    actionButton={(
                        <span className="status status-rejected">
                             <button className="pseudo-link text-capitalize">Suspended</button>
                        </span>
                    )}
                >
                    <div className="status-message alert">
                        <i className="icon icon-warning" />
                        <span className="status-message-cnt">{entity.comment}</span>
                    </div>
                </Popover>
            );
    }
}
