import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatUtils } from '../../../utils';
import { pxTalkListActions } from '../../../actions';
import { constants } from '../../../constants';
import { FeatureButton } from '../../access/FeatureButton';
import { ValueBlocker } from '../../access/ValueBlocker';

export function PxTalkListButton({ positionId, className, pxTalks, requiredFeature, onClick }) {
    const dispatch = useDispatch();
    const activePositionId = useSelector(store => store.pxTalkList.positionId);
    const pxTalkRangeText =
        pxTalks &&
        pxTalks.length &&
        formatUtils.formatPxTalk(pxTalks);

    if (!pxTalkRangeText) {
        return <span>{constants.emptyPlaceholder}</span>;
    }

    const handleClick = e => {
        if (activePositionId === positionId) {
            dispatch(pxTalkListActions.hide());
        } else {
            dispatch(pxTalkListActions.show(positionId));
        }

        if (onClick) {
            onClick();
        }

        e.stopPropagation();
    }

    return (
        <ValueBlocker feature={requiredFeature}>
            <FeatureButton
                className={className}
                type="button"
                onClick={handleClick}
            >
                {pxTalkRangeText}
            </FeatureButton>
        </ValueBlocker>
    );
}

PxTalkListButton.defaultProps = {
    requiredFeature: undefined,
    onClick: undefined
}
