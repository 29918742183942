import { isNil, noop } from 'lodash';
import { constants, pipelineRequestCriteria, routes } from '../../../../constants';
import { Currency } from '../../../../types/enums/Currency';
import { AppState } from '../../../../types/state/AppState';
import { CloManagersState } from '../../../../types/state/CloManagersState';
import { Statistics } from './Statistics';
import { StatisticsChart } from './StatisticsChart';
import { IssuanceSpreadWidget } from '../../../dashboard/issuanceSpread/IssuanceSpreadWidget';
import { ChartGrouping, ChartUnits, Term } from '../../../../types/dashboard/AmrChart';
import { ShortLongDatedDealsWidget } from '../../../dashboard/shortLongDatedDeals/ShortLongDatedDealsWidget';
import { ShortLongDatedDealsFilter } from './charts/ShortLongDatedDealsFilter';
import { ShortLongChartClickHandlerParams } from '../../../dashboard/shortLongDatedDeals/types';
import { ShortOrLongDated } from '../../../../types/amr-pipeline/enums/ShortOrLongDated';
import { CloManagerArrangerStatsWidget } from './charts/CloManagerArrangerStatsWidget';
import { CloManagerTrusteeStatsWidget } from './charts/CloManagerTrusteeStatsWidget';
import { useDispatch, useSelector } from 'react-redux';
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent';
import { QueryStringArgumentSerializer } from '../../../../utils/filtering/serializers/QueryStringArgumentSerializer';
import { IssuanceSpreadsChartClickHandlerParams } from '../../../dashboard/issuanceSpread/types';
import { queryStringSerializer } from '../../../../utils/filtering';
import {
    arrangers,
    collateralManagers,
    coupon,
    multipleCurrencies,
    nonCallEndFrom,
    nonCallEndTo,
    pricingDateFrom,
    pricingDateTo,
    ratings,
    reinvestmentEndFrom,
    reinvestmentEndTo,
    transactionTypes,
    trustees,
    esg,
    euCompliance,
    staticDeals,
    transactionStatuses,
    collateralTypes,
    outOfNC,
    outOfRI,
} from '../../../../utils/filtering/serializers/serializer.definitions';
import { useContext } from 'react';
import CloManagersContext from '../../cloManagersContext';
import IconSVG from '../../../../styles/svg-icons';
import { cloManagersActions } from '../../../../actions/clo-managers.actions';
import { CloManagerAccessType } from '../../../../types/amr-pipeline/enums/CloManagerAccessType';
import { ArrangersTrusteesChartsTransactionStatuses, StatisticsChartsTransactionStatuses, StatisticsChartsTransactionTypes } from './constants';
import { Player } from '../../../common/Player';
import { stringUtils } from '../../../../utils';
import { RequiredFeature } from '../../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../../../access/ActionBlocker';
import { BlockedFeatureContent, SubscribeLink } from '../../../access/BlockedFeatureText';
import { user } from '../../../../user';

export const Overview = () => {
    const dispatch = useDispatch();
    const { selectedManager } = useSelector<AppState, CloManagersState>((state: AppState) => state.cloManagers);
    const { ownCompany } = useContext(CloManagersContext);

    if (!selectedManager) {
        return null;
    }

    const { details } = selectedManager;

    const renderActionButton = () => (
        <div className="company-profile-actions">
            {details?.webSite && (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={details.webSite}
                    className="btn-link"
                    onClick={() => dispatch(cloManagersActions.logUserActivity(selectedManager.referenceName, CloManagerAccessType.CloTeamWebsite))}
                >
                    <IconSVG name="website" width={16} height={16} />
                    Website
                </a>
            )}
            {details?.linkedIn && (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={details.linkedIn}
                    className="btn-link"
                    onClick={() => dispatch(cloManagersActions.logUserActivity(selectedManager.referenceName, CloManagerAccessType.CloTeamIn))}
                >
                    <IconSVG name="linkedin-filled" width={16} height={16} />
                    LinkedIn
                </a>
            )}
        </div>
    );

    const redirectToIssuanceMonitor = (qsBuilder: (...params: any) => string, cloManagerAccessType: CloManagerAccessType) => (...params: any) => {
        dispatch(cloManagersActions.logUserActivity(selectedManager.referenceName, cloManagerAccessType));

        const queryString = qsBuilder(...params);

        window.open(`${window.location.origin}${routes.AMRPipeline}/?${queryString}`);
    };

    const redirectToIMWithCurrency = redirectToIssuanceMonitor((currency: Currency) => {
        const hasImAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorDealUpdateEmailAlerts);

        const searchParams = {
            collateralManagers: [selectedManager.referenceName],
            currency: [currency],
            // Generate different links for subscribed and unsubscribed users
            // to show `see N more deals` placeholder in particular case
            transactionStatuses: hasImAccess ? StatisticsChartsTransactionStatuses : undefined,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            multipleCurrencies(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewGeographicMix);

    const redirectToIMWithESG = redirectToIssuanceMonitor((esgDeals: boolean) => {
        const searchParams = {
            collateralManagers: [selectedManager.referenceName],
            esg: esgDeals,
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            esg(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewEsgDeals);

    const redirectToIMWithEUCompliance = redirectToIssuanceMonitor((euComplianceDeals: boolean) => {
        const searchParams = {
            collateralManagers: [selectedManager.referenceName],
            euCompliance: euComplianceDeals,
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            euCompliance(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewEuCompliant);

    const redirectToIMWithStaticDeals = redirectToIssuanceMonitor((staticDeal: boolean) => {
        const searchParams = {
            collateralManagers: [selectedManager.referenceName],
            staticDeals: staticDeal,
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            staticDeals(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewStaticDeals);

    const redirectToIMWithOutOfNC = redirectToIssuanceMonitor((isOutOfNC: boolean) => {
        const searchParams = {
            collateralManagers: [selectedManager.referenceName],
            outOfNC: isOutOfNC,
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            outOfNC(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewOutOfNCDoor);

    const redirectToIMWithOutOfRI = redirectToIssuanceMonitor((isOutOfRI: boolean) => {
        const searchParams = {
            collateralManagers: [selectedManager.referenceName],
            outOfRI: isOutOfRI,
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            outOfRI(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewOutOfRIDoor);

    const handleArrangersChartClick = redirectToIssuanceMonitor((companyReferenceName: string, transactionType: string) => {
        const searchParams = {
            collateralManagers: [selectedManager.referenceName],
            arrangers: [companyReferenceName],
            transactionTypes: [transactionType],
            transactionStatuses: ArrangersTrusteesChartsTransactionStatuses,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            arrangers(),
            transactionTypes(),
            transactionStatuses(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewArrangers);

    const handleTrusteesChartClick = redirectToIssuanceMonitor((companyReferenceName: string) => {
        const searchParams = {
            collateralManagers: [selectedManager.referenceName],
            trustees: [companyReferenceName],
            transactionStatuses: ArrangersTrusteesChartsTransactionStatuses,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            trustees(),
            transactionStatuses(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewTrustees);

    const handleIssuanceSpreadClick = redirectToIssuanceMonitor((params: IssuanceSpreadsChartClickHandlerParams) => {
        const termRiEndCriteria = {
            [Term.Short]: pipelineRequestCriteria.issuanceSpread.shortTerm.reinvestmentPeriodEndYears,
            [Term.Long]: pipelineRequestCriteria.issuanceSpread.longTerm.reinvestmentPeriodEndYears,
        };

        const termNcEndCriteria = {
            [Term.Short]: pipelineRequestCriteria.issuanceSpread.shortTerm.nonCallPeriodEndYears,
            [Term.Long]: pipelineRequestCriteria.issuanceSpread.longTerm.nonCallPeriodEndYears,
        };

        const [riEndFrom, riEndTo] = params.term ? termRiEndCriteria[params.term] : [];
        const [ncEndFrom, ncEndTo] = params.term ? termNcEndCriteria[params.term] : [];

        const searchParams = {
            collateralManagers: [selectedManager.referenceName],
            collateralTypes: [params.collateralType],
            transactionTypes: params.transactionType
                ? [params.transactionType]
                : StatisticsChartsTransactionTypes,
            transactionStatuses: ArrangersTrusteesChartsTransactionStatuses,
            currency: [params.currencyCode],
            ...(isNil(riEndFrom) ? undefined : { reinvestmentEndFrom: riEndFrom }),
            ...(isNil(riEndTo) ? undefined : { reinvestmentEndTo: riEndTo }),
            ...(isNil(ncEndFrom) ? undefined : { nonCallEndFrom: ncEndFrom }),
            ...(isNil(ncEndTo) ? undefined : { nonCallEndTo: ncEndTo }),
            pricingDateFrom: params.startPeriod,
            pricingDateTo: params.endPeriod,
            coupon: params.floaterIndex,
            ratings: [params.rating],
            staticDeals: false,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            transactionTypes(),
            multipleCurrencies(),
            pricingDateFrom(),
            pricingDateTo(),
            reinvestmentEndFrom(),
            reinvestmentEndTo(),
            nonCallEndFrom(),
            nonCallEndTo(),
            ratings(),
            coupon(),
            transactionStatuses(),
            staticDeals(),
            collateralTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewIssuanceSpreads);

    const handleShortLongDatedDealsClick = redirectToIssuanceMonitor((params: ShortLongChartClickHandlerParams) => {
        const [riEndFrom, riEndTo] = params.shortOrLongDated === ShortOrLongDated.Short
            ? pipelineRequestCriteria.shortLongDatedDeals.reinvestmentPeriodEndYearsShort
            : pipelineRequestCriteria.shortLongDatedDeals.reinvestmentPeriodEndYearsLong;

        const [ncEndFrom, ncEndTo] = params.shortOrLongDated === ShortOrLongDated.Short
            ? pipelineRequestCriteria.shortLongDatedDeals.nonCallPeriodEndYearsShort
            : pipelineRequestCriteria.shortLongDatedDeals.nonCallPeriodEndYearsLong;

        const searchParams = {
            collateralManagers: [selectedManager.referenceName],
            transactionTypes: params.transactionType
                ? [params.transactionType]
                : StatisticsChartsTransactionTypes,
            transactionStatuses: ArrangersTrusteesChartsTransactionStatuses,
            currency: [params.currencyCode],
            reinvestmentEndFrom: riEndFrom,
            reinvestmentEndTo: riEndTo,
            nonCallEndFrom: ncEndFrom,
            nonCallEndTo: ncEndTo,
            pricingDateFrom: params.startPeriod,
            pricingDateTo: params.endPeriod,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            transactionTypes(),
            multipleCurrencies(),
            pricingDateFrom(),
            pricingDateTo(),
            reinvestmentEndFrom(),
            reinvestmentEndTo(),
            nonCallEndFrom(),
            nonCallEndTo(),
            transactionStatuses(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewShortLongDatedDeals);

    const withVideoColumn = details?.webinarVideo || details?.platformOverview;
    const showWebinarVideo = (!!details?.webinarVideo && stringUtils.isValidUrl(details.webinarVideo)) || !!details?.webinarVideoExists;

    return (
        <>
            <SectionContent
                title="Company Profile"
                className="data-item-statistics"
                actionBtn={renderActionButton()}
            >
                <div className="description-with-video-box">
                    {details?.description && (
                        <p>
                            {details.description}
                        </p>
                    )}
                    {withVideoColumn && (
                        <div className="video-wrap">
                            {details?.platformOverview &&
                                stringUtils.isValidUrl(
                                    details.platformOverview
                                ) && (
                                    <div className="video-item">
                                        <h3>Platform Overview</h3>
                                        <Player
                                            url={details.platformOverview}
                                            width={325}
                                            height={180}
                                        />
                                    </div>
                                )}
                                {showWebinarVideo && (
                                    <div className="video-item">
                                        <h3>Webinars</h3>
                                        <RequiredFeature
                                            feature={SubscriptionFeature.ManagerProfileWebinarVideo}
                                            overrideRequiredFeatures={ownCompany}
                                            renderBlockedContent={() => (
                                                <BlockedFeatureContent
                                                    text={<><SubscribeLink /> to unlock access to <b>Webinars</b>.</>}
                                                    className="empty-placeholder"
                                                />
                                            )}
                                        >
                                            <Player
                                                url={details.webinarVideo!}
                                                width={325}
                                                height={180}
                                            />
                                        </RequiredFeature>
                                    </div>
                                )}
                        </div>
                    )}
                </div>
                <Statistics stats={selectedManager.details?.stats} companyReferenceName={selectedManager.referenceName} />
                <div className="statistics-charts">
                    <StatisticsChart
                        title="Geographic Mix"
                        onSectionClick={redirectToIMWithCurrency}
                        values={[details?.stats.usdDeals, details?.stats.eurDeals]}
                        labels={['USD', 'EUR']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[Currency.USD, Currency.EUR]}
                        features={[SubscriptionFeature.ManagerProfilePrimaryMarketData]}
                    />
                    <StatisticsChart
                        title="ESG Deals"
                        onSectionClick={redirectToIMWithESG}
                        values={[details?.stats.esgDeals, details?.stats.nonEsgDeals]}
                        labels={['ESG', 'Non-ESG']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[true, false]}
                        features={[SubscriptionFeature.ManagerProfilePrimaryMarketData, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                    />
                    <StatisticsChart
                        title="EU Compliant"
                        onSectionClick={redirectToIMWithEUCompliance}
                        values={[details?.stats.euCompliantDeals, details?.stats.nonEuCompliantDeals]}
                        labels={['EU Compliant', 'Non-EU Compliant']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[true, false]}
                        features={[SubscriptionFeature.ManagerProfilePrimaryMarketData, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                    />
                    <StatisticsChart
                        title="Static Deals"
                        onSectionClick={redirectToIMWithStaticDeals}
                        values={[details?.stats.staticDeals, details?.stats.nonStaticDeals]}
                        labels={['Static', 'Non-Static']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[true, false]}
                        features={[SubscriptionFeature.ManagerProfilePrimaryMarketData, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                    />
                    <StatisticsChart
                        title="Out of NC"
                        onSectionClick={redirectToIMWithOutOfNC}
                        values={[details?.stats.outOfNC, details?.stats.notOutOfNC]}
                        labels={['Yes', 'No']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[true, false]}
                        features={[SubscriptionFeature.ManagerProfilePrimaryMarketData, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                    />
                    <StatisticsChart
                        title="Out of RI"
                        onSectionClick={redirectToIMWithOutOfRI}
                        values={[details?.stats.outOfRI, details?.stats.notOutOfRI]}
                        labels={['Yes', 'No']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[true, false]}
                        features={[SubscriptionFeature.ManagerProfilePrimaryMarketData, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                    />
                </div>
            </SectionContent>
            <div className="clo-managers-charts">
                <div className="dashboard-row flex-row">
                    {details &&
                        <CloManagerArrangerStatsWidget
                            stats={details.stats}
                            onChartClick={handleArrangersChartClick}
                        />
                    }
                    {details &&
                        <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts} noTooltip>
                            {blocked => (
                                <CloManagerTrusteeStatsWidget
                                    stats={details.stats}
                                    onChartClick={blocked ? noop : handleTrusteesChartClick}
                                />
                            )}
                        </ActionBlocker>
                    }
                    <ActionBlocker requireAllFeatures features={[SubscriptionFeature.ManagerProfileIssuanceSpreadsGraph, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]} noTooltip>
                        {blocked => (
                            <IssuanceSpreadWidget
                                availableGroupings={[ChartGrouping.M, ChartGrouping.Q, ChartGrouping.Y]}
                                defaultFilter={{ grouping: ChartGrouping.Y, term: constants.allListItem }}
                                managerReferenceName={selectedManager.referenceName}
                                startDate={pipelineRequestCriteria.issuanceSpread.startDateCloManagers}
                                onChartClick={blocked ? noop : handleIssuanceSpreadClick}
                                withSlider
                                withAllTermsOption
                                feature={SubscriptionFeature.ManagerProfileIssuanceSpreadsGraph}
                                className="full-size im-chart issuance-spread-chart with-border"
                            />
                        )}
                    </ActionBlocker>
                    <ActionBlocker requireAllFeatures features={[SubscriptionFeature.ManagerProfileDealsGraph, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]} noTooltip>
                        {blocked => (
                            <ShortLongDatedDealsWidget
                                subtitle="Deal Count"
                                defaultFilter={{ units: ChartUnits.Count, grouping: ChartGrouping.Y, currencyCode: undefined }}
                                managerReferenceName={selectedManager.referenceName}
                                startDate={pipelineRequestCriteria.shortLongDatedDeals.startDateCloManagers}
                                filterComponent={ShortLongDatedDealsFilter}
                                onChartClick={blocked ? noop : handleShortLongDatedDealsClick}
                                feature={SubscriptionFeature.ManagerProfileDealsGraph}
                                className="full-size im-chart issuance-spread-chart with-border"
                            />
                        )}
                    </ActionBlocker>
                </div>
            </div>
            {
                // Hid section in scope https://kopentech.atlassian.net/browse/AMR-8551
                /*
            <SectionContent title="CLO Team Structure" className="data-item-general-information data-item-general-cols">
                <div className="data-item-row-col">
                    <FormField title="Region(s)">
                        {details?.cloTeamStructureRegions}
                    </FormField>
                    <FormField title="Number of CLO Portfolio Managers">
                        {isNil(details?.cloPortfolioManagers) ? constants.emptyPlaceholder : details?.cloPortfolioManagers}
                    </FormField>
                    <FormField title="PMs Average Experience">
                        {details?.pmAverageExperience}
                    </FormField>
                    <FormField title="Analyst Team Average Experience">
                        {details?.analystTeamAverageExperience}
                    </FormField>
                    <FormField title="Loan Team Credits Per Analyst">
                        {details?.loanTermCreditsPerAnalyst}
                    </FormField>
                    <FormField title="Approximate No. of Invested Credits">
                        {details?.approxNoOfInvestedCredits}
                    </FormField>
                </div>
                <div className="data-item-row-col">
                    <strong className="loan-analysts-with">Loan Analysts with:</strong>
                    <FormField title=">20 Years’ Credit Experience">
                        {isNil(details?.loanAnalystsWithOver20yCreditExperience) ? constants.emptyPlaceholder : details?.loanAnalystsWithOver20yCreditExperience}
                    </FormField>
                    <FormField title="10-20 Years’ Credit Experience">
                        {isNil(details?.loanAnalystsWith10To20yCreditExperience) ? constants.emptyPlaceholder : details?.loanAnalystsWith10To20yCreditExperience}
                    </FormField>
                    <FormField title="<10 Years’ Credit Experience">
                        {isNil(details?.loanAnalystsWithLessThan10yCreditExperience) ? constants.emptyPlaceholder : details?.loanAnalystsWithLessThan10yCreditExperience}
                    </FormField>
                    <FormField title="Distressed Credit Specialists">
                        {isNil(details?.distressedCreditSpecialists) ? constants.emptyPlaceholder : details?.distressedCreditSpecialists}
                    </FormField>
                    <FormField title="Avg. Experience">
                        {isNil(details?.avgExperience) ? constants.emptyPlaceholder : details?.avgExperience}
                    </FormField>
                </div>
            </SectionContent>
            */
            }
        </>
    );
}
