import * as React from 'react';
import {  ClientsTableDataItem } from '../ClientsTableDataItem';
import { ClientsFilterOptions, SettlementAccountExistType } from './ClientsFilterOptions';
import { SettlementAgreementStatus } from '../../../../types/bid-as-dealer/SettlementAgreementStatus';
import { enumsUtils } from '../../../../utils/enums.utils';

export function useClientsFilter(agreements: ClientsTableDataItem[], filter: ClientsFilterOptions, searchString: string) {
    const [searchResult, setSearchResult] = React.useState(agreements);

    React.useEffect(() => {
        const search = searchString.toLowerCase();

        const matchSeatchString = (item: ClientsTableDataItem) =>
            !search || item.signatory.name.toLowerCase().includes(search);

        const matchSettlementAccountExist = (item: ClientsTableDataItem) => {
            if (!filter.settlementAccountExist.length ||
                enumsUtils.getKeys(SettlementAccountExistType).length === filter.settlementAccountExist.length) {
                // all options enabled
                return true;
            }

            return filter.settlementAccountExist.some(o => o === SettlementAccountExistType.WithoutSettlementAccount)
                ? !item.settlementAccountCount
                : !!item.settlementAccountCount;
        };

        const matchAgreementStatus = (item: ClientsTableDataItem) => {
            if (!filter.agreementStatus.length ||
                (enumsUtils.getKeys(SettlementAgreementStatus).length + 1) === filter.agreementStatus.length) { // + Unrequested
                // all options enabled
                return true;
            }

            return filter.agreementStatus.some(s => s === item.agreementStatus);
        }

        const filtered = agreements.filter(a =>
            matchSeatchString(a) &&
            matchSettlementAccountExist(a) &&
            matchAgreementStatus(a)
        );

        setSearchResult(filtered);

    }, [agreements, filter, searchString]);

    return searchResult;
}

