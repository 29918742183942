import { constants } from '../../../../constants';
import { formatUtils } from '../../../../utils';

interface AumStatisticsItemProps {
    value?: number;
    count?: number;
}

export const AumStatisticsItem = ({ value, count }: AumStatisticsItemProps) => {
    if (!value && !count) {
        return <span>{constants.emptyPlaceholder}</span>;
    }

    return (
        <>
            <b>{value ? formatUtils.formatToBillions(value) : constants.emptyPlaceholder}</b> /
            <span>{count || constants.emptyPlaceholder}</span>
        </>
    );
};
