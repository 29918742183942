import { constants, SORTING_TYPE } from '../../../../../constants';
import { ColumnBuilder } from '../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../../bidding/common/table/types/ColumnDefinition';
import { CloManagerProfileView } from '../../../../../types/amr-pipeline/models/CloManagerSession';
import { ProportionBar } from '../../../../amrPipeline/ProportionBar';
import { CloManagerAccessTypes } from '../../../../../types/amr-pipeline/enums/CloManagerAccessType';
import { dateTimeUtils } from '../../../../../utils';
import { OnHoverTooltip, Pluralize } from '../../../../common';
import FollowUp from '../../../../common/FollowUp';
import { ProfileViewHistoryPopover } from '../ProfileViewHistoryPopover/ProfileViewHistoryPopover';
import { ProfileViewHistoryTabs } from '../ProfileViewHistoryPopover/ProfileViewHistoryTabs';

const profileViewHistoryColumnsDefinition: IColumnDefinition<CloManagerProfileView>[] = [
    {
        columnKey: 'profileViews',
        renderColumnHeaderContent: () => 'Profile Views',
        renderColumnContent: (userSession, { companyReferenceName }) => (
            <ProfileViewHistoryPopover
                userSession={userSession}
                companyReferenceName={companyReferenceName}
            >
                {dateTimeUtils.utcToLocalString(userSession.accessDateTime)}
            </ProfileViewHistoryPopover>
        ),
        sortingField: 'accessDateTime',
        className: 'data-list-cell-lg',
        sortingType: SORTING_TYPE.date,
    },
    {
        columnKey: 'tabsViewed',
        renderColumnHeaderContent: () => 'Tabs Viewed',
        renderColumnContent: profileSession => {
            const views = profileSession.views;

            return (
                <ProportionBar
                    title="view(s)"
                    items={CloManagerAccessTypes.map(t => ({
                        title: t.text,
                        value: views && profileSession.views[t.value],
                        color: t.color
                    }))}
                />
            );
        },
        className: 'data-list-cell-lg-02',
    },
    {
        columnKey: 'docsDownloadedSize',
        renderColumnHeaderContent: () => 'Presentation',
        renderColumnContent: (profileSession, { companyReferenceName }) => {
            const downloads = profileSession.documents.length;

            if (!downloads) {
                return constants.emptyPlaceholder;
            }

            return (
                <ProfileViewHistoryPopover
                    userSession={profileSession}
                    defaultTab={ProfileViewHistoryTabs.PresDownloaded}
                    companyReferenceName={companyReferenceName}
                >
                    <Pluralize
                        singular="doc"
                        count={downloads}
                    />
                </ProfileViewHistoryPopover>
            );
        },
    },
    {
        columnKey: 'fullName',
        renderColumnHeaderContent: () => 'Full Name',
        renderColumnContent: (profileSession, { companyReferenceName }) => (
            <ProfileViewHistoryPopover
                userSession={profileSession}
                defaultTab={ProfileViewHistoryTabs.Contacts}
                companyReferenceName={companyReferenceName}
            >
                {profileSession.userFullName}
            </ProfileViewHistoryPopover>
        ),
        sortingField: 'userFullName',
        className: 'data-list-cell-xl',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'companyLegalName',
        renderColumnHeaderContent: () => 'Company',
        renderColumnContent: ({ companyLegalName }) => (
            <OnHoverTooltip overlay={companyLegalName}>
                {companyLegalName}
            </OnHoverTooltip>
        ),
        sortingField: 'companyLegalName',
        className: 'data-list-cell-xl-xxl',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'follow-up',
        renderColumnHeaderContent: () => '',
        renderColumnContent: (profileSession) => <FollowUp email={profileSession.userEmail} />,
        className: 'data-list-cell-md-flexible',
    }
];

export const profileViewHistoryColumns = () => profileViewHistoryColumnsDefinition.map(c => new ColumnBuilder(c));

