import React from 'react';
import { Preloader } from './Preloader';

export function FormControlLoader({ inProgress }) {
    if (inProgress) {
        return (
            <div className="form-control-loader"><Preloader inProgress={inProgress} fullScreen={false} small={true} /></div>
        );
    }
    return null;
}
