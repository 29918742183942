import React from 'react';

export const PxTalkNormalizationTable = () => (
    <>
        <h5>Px Talk Normalization Algorithm</h5>
        <table className="rating-table">
            <tbody>
                <tr>
                    <th>Prefix</th>
                    <th>A</th>
                    <th>VVL</th>
                    <th>VL</th>
                    <th>L</th>
                    <th>LM</th>
                </tr>
                <tr>
                    <th>Digital Convertion</th>
                    <td>0</td>
                    <td>0.025</td>
                    <td>0.05</td>
                    <td>0.15</td>
                    <td>0.35</td>
                </tr>
                <tr>
                    <th>Prefix</th>
                    <th>M</th>
                    <th>MH</th>
                    <th>H</th>
                    <th>VH</th>
                    <th>VVH</th>
                </tr>
                <tr>
                    <th>Digital Convertion</th>
                    <td>0.5</td>
                    <td>0.6</td>
                    <td>0.8</td>
                    <td>0.9</td>
                    <td>0.95</td>
                </tr>
                <tr>
                    <th colSpan="6">A &ndash; Area</th>
                </tr>
            </tbody>
        </table>
    </>
);
