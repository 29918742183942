import { useState } from 'react';
import { validateEmail } from '../../../utils';
import { FlexRow, Preloader } from '../../common';
import { EmailChipList } from '../../common/EmailChipList';
import { Popup, PopupBody, PopupFooter } from '../../controls';
import { useRequestScheduleMeeting } from './useRequestScheduleMeeting';
import { ScheduleMeetingMailPreview } from './ScheduleMeetingPreview';
import { StatusMessageSection } from '../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../types/state/NotificationState';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../../actions';


const emailMaxCount = 500;

interface Props {
    cloManagerReferenceName: string;
    cloManagerLegalName: string;
    userCompanyName: string;
    onClose: () => void;
    defaultEmailList?: string[];
}

export function ScheduleMeetingPopup({
    onClose,
    defaultEmailList = [],
    cloManagerReferenceName,
    cloManagerLegalName,
    userCompanyName
}: Props) {
    const dispatch = useDispatch();
    const [emailList, setEmailList] = useState<string[]>(defaultEmailList);
    const [textContent, setTextContent] = useState('');
    const [sending, setSending] = useState(false);


    const handleClose = () => {
        if (!sending) {
            onClose();
        }
    };

    const onLoadSuccess = () => {
        onClose();
        dispatch(notificationActions.notificationAddInfo('Meeting request sent', `Your meeting request has been sent to ${cloManagerLegalName}`));
    }

    useRequestScheduleMeeting(cloManagerReferenceName, emailList, textContent, sending, setSending, onLoadSuccess);

    const isLimitExceeded = emailList.length > emailMaxCount;
    const isDataInvalid = emailList.some(e => !validateEmail(e));

    const renderInviteForm = () => (
        <>
            {(isDataInvalid || isLimitExceeded) ? (
                <StatusMessageSection type={StatusMessageSectionType.Error}>
                    {isLimitExceeded
                        ? `Max recipients count is ${emailMaxCount}`
                        : 'Please fix invalid email addresses'}
                </StatusMessageSection>
            ) : (
                <StatusMessageSection type={StatusMessageSectionType.Info}>
                    Email will be sent to contacts below
                </StatusMessageSection>
            )}
            <EmailChipList
                disabled={sending}
                defaultItems={emailList}
                onChange={(list: string[]) => setEmailList(list)}
                allowInvalid
                withClearAll
                placeholder="Enter recipients or Ctrl+C / Ctrl+V emails from Excel"
                className="email-chips-list-with-clear"
            />
            <textarea
                value={textContent}
                maxLength={2048}
                onChange={e => setTextContent(e.target.value)}
                className="form-control share-transaction-message"
                placeholder="Enter message (optional)"
            />
        </>
    );

    return (
        <Popup
            renderTitle={
                <h2>
                    Schedule Meeting <span className="name-tag">{cloManagerLegalName}</span>
                </h2>
            }
            modalClass="modal-share-transaction modal-schedule-meeting"
            onClose={handleClose}
        >
            <PopupBody>
                <div className="share-transaction-row">
                    <div className="share-transaction-form">{renderInviteForm()}</div>
                    <div className="share-transaction-preview">
                        <ScheduleMeetingMailPreview
                            userCompanyName={userCompanyName}
                            text={textContent}
                        />
                    </div>
                </div>
            </PopupBody>
            <PopupFooter>
                <FlexRow>
                    {sending && <Preloader inProgress={false} fullScreen={false} small={true} />}
                    <button type="button" className="btn btn-ghost" onClick={handleClose}>
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-main"
                        onClick={() => setSending(true)}
                        disabled={isDataInvalid || !emailList.length || sending || isLimitExceeded}
                    >
                        Send request
                    </button>
                </FlexRow>
            </PopupFooter>
        </Popup>
    );
}
