import * as React from 'react';
import { Process } from '../../../types/models/Process';
import dateTimeUtils from '../../../utils/dateTime.utils';
import { BwicProcessType } from '../../../types/models/Process';
import { CutOffReminder } from '../CutOffReminder';
import { BwicRulesSectionRow } from './BwicRulesSectionRow';
import { BwicRulesStage } from './BwicRulesStage';
import { InfoTooltip } from "../../common/InfoTooltip";

interface BwicRulesTopXProps {
    process: Process;
}

export function BwicRulesTopX({ process }: BwicRulesTopXProps) {
    if (process.type === BwicProcessType.TopX) {
        const stage1Duration = process.topX?.automaticStage1Deadline
            ? `${dateTimeUtils.parseTimeSpan(process.topX.automaticStage1Deadline).totalMinutes} minutes`
            : 'Unspecified; Seller controls finish time';
        const stage2Duration = process.topX?.improvementRound
            ? `${dateTimeUtils.parseTimeSpan(process.topX?.improvementRound).totalMinutes} minutes`
            : 'Unspecified; Seller controls finish time';

        return (
            <div className="rules-section-content">
                <BwicRulesStage
                    title='Stage 1 — Open bidding with autofeedback'
                >
                    <BwicRulesSectionRow label="Stage 1 duration" dataTestId="stage-1-duration">
                        {stage1Duration}
                    </BwicRulesSectionRow>
                    {
                        Boolean(process.topX?.automaticStage1Deadline) &&
                        <CutOffReminder process={process} />
                    }
                    <BwicRulesSectionRow label="# of Stage 2 participants" dataTestId="#-of-stage-2-participants">
                        <div className="flex-row">
                            Top {process.topX?.improverCount} + ties
                            <InfoTooltip text={`All participants whose bids are at the Top ${process.topX?.improverCount} level will go to Stage 2 and will have a chance to improve the bid.`} />
                        </div>
                    </BwicRulesSectionRow>
                    <BwicRulesSectionRow label="Bid improvement">unlimited</BwicRulesSectionRow>
                    <BwicRulesSectionRow label="Bid feedback">auto/manual</BwicRulesSectionRow>
                </BwicRulesStage>
                <BwicRulesStage
                    title='Stage 2 — Top X bidders have the ability to improve their bids many times'
                >
                    <h5>
                        Bids from Stage 1 are carried to Stage 2. However, the seller is notified if the bidder is bidding over themselves and has the option to trade at the lower bid. The seller can provide manual/auto feedback on bids. Results are announced when the Seller closes BWIC via trading or DNT.
                    </h5>
                    <BwicRulesSectionRow label="Stage 2 duration" dataTestId="stage-2-duration">
                        {stage2Duration}
                    </BwicRulesSectionRow>
                    <BwicRulesSectionRow label="Bid improvement">unlimited</BwicRulesSectionRow>
                    <BwicRulesSectionRow label="Bid feedback">auto/manual</BwicRulesSectionRow>
                </BwicRulesStage>
            </div>
        )
    } else return null;
}
