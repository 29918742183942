import React, { Component } from 'react';
import { connect } from 'react-redux';
import { classnames } from '../../styles/classnames';
import { onBoardingTooltipConst, roles } from '../../constants';
import { onBoardingTooltipSetVisible } from '../../actions';
import { ShowFor } from '../access';
import { SalesRepresentativeList } from '../sales-representative/SalesRepresentativeList';
import IconSVG from '../../styles/svg-icons';
import { OnHoverTooltip } from '../common';
import { Toggle } from '../controls';

class SupportMain extends Component {
    state = {
        visible: false
    };

    getCheckedOnBoardingTooltipToggle = () => {
        const { tooltipState } = this.props;
        const { pages } = tooltipState;
        for (const key in onBoardingTooltipConst.pages) {
            const pageKey = onBoardingTooltipConst.pages[key];
            if (!pages[pageKey]) {
                return false
            }
            if (!pages[pageKey].gotIt && pageKey !== onBoardingTooltipConst.pages.bwicOpenBidding) {
                return true
            }
        }
        return false
    };

    render = () => {
        const isShowSupport = classnames({
            'navbar-support navbar-item-dropdown': true,
            'is-show': this.state.visible
        });

        return (
            <div ref={node => this._dropdown = node} className={isShowSupport}>
                <OnHoverTooltip overlay="Help & Support">
                    <button
                        type="button"
                        className="navbar-link navbar-link-support"
                        onClick={this.show}
                    >
                        <IconSVG name="support" width={24} height={24} />
                    </button>
                </OnHoverTooltip>
                {
                    this.state.visible &&
                    <div className="navbar-dropdown-menu navbar-dropdown-support">
                        <ShowFor roles={roles.salesRepresentativesSupported()}>
                            <SalesRepresentativeList salesRepresentatives={this.props.salesRepresentatives} />
                        </ShowFor>
                        <div className="navbar-dropdown-menu-section navbar-dropdown-employees">
                            <h3>Help &amp; Support</h3>
                            <ul className="employees-list">
                                <li>
                                    <a className="flex-row navbar-dropdown-menu-item" href="tel:18008621684"
                                        onClick={this.hide}>
                                        <div className="employee-icon">
                                            <IconSVG name="phone" width="16" height="16" />
                                        </div>
                                        <ul className="employee-contacts">
                                            <li className="link-color">1.800.862.1684</li>
                                            <li><span className="sub-text">Contact Phone</span></li>
                                        </ul>
                                    </a>
                                </li>
                                <li>
                                    <a className="flex-row navbar-dropdown-menu-item"
                                        href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} onClick={this.hide}>
                                        <div className="employee-icon">
                                            <IconSVG name="email" width="16" height="16" />
                                        </div>
                                        <ul className="employee-contacts">
                                            <li className="link-color">{process.env.REACT_APP_SUPPORT_EMAIL}</li>
                                            <li><span className="sub-text">Contact Email</span></li>
                                        </ul>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <ShowFor role={roles.Viewer}>
                            <div className="navbar-dropdown-menu-item navbar-dropdown-support">
                                <Toggle
                                    value={this.getCheckedOnBoardingTooltipToggle()}
                                    onClick={(e) => this.props.dispatch(onBoardingTooltipSetVisible(e.target.checked))}
                                >
                                    <>
                                        <span>Show Training</span>
                                        <span className="sub-text">Toggle on for online training and learning content</span>
                                    </>
                                </Toggle>
                            </div>
                        </ShowFor>
                    </div>
                }
            </div>
        );
    };

    show = () => {
        if (this.state.visible) {
            this.hide();
        } else {
            document.addEventListener('mousedown', this.handleClickOutside);
            this.setState({ visible: true });
        }
    };

    hide = () => {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.setState({ visible: false });
    };

    componentWillUnmount = () => document.removeEventListener('mousedown', this.handleClickOutside);

    handleClickOutside = e => {
        if (this._dropdown &&
            !this._dropdown.contains(e.target) &&
            this.state.visible) {
            this.hide();
        }
    }
}

const mapStateToProps = ({ onBoardingTooltip, salesRepresentative }) => ({
    tooltipState: onBoardingTooltip,
    salesRepresentatives: salesRepresentative.salesRepresentatives
});

const Support = connect(mapStateToProps)(SupportMain);

export default Support
