import { SettlementAgreementStatus } from '../../../../types/bid-as-dealer/SettlementAgreementStatus';
import { UnrequestedAgreementStatus } from '../../../../types/bid-as-dealer/SettlementAgreementStatus';

export interface ClientsFilterOptions {
    settlementAccountExist: SettlementAccountExistType[];
    agreementStatus: (SettlementAgreementStatus | UnrequestedAgreementStatus)[];
}

export enum SettlementAccountExistType {
    WithSettlementAccount,
    WithoutSettlementAccount
}

export const SettlementAccountExistTypeTitle = {
    [SettlementAccountExistType.WithSettlementAccount]: 'Yes',
    [SettlementAccountExistType.WithoutSettlementAccount]: 'No',
}

