import React from 'react';
import cn from 'classnames';

interface FlexRowProps {
    className?: string;
    children?: React.ReactNode;
}

function FlexRow({ className, children }: FlexRowProps) {
    return (
        <div className={cn('flex-row', className)}>
            {children}
        </div>
    )
}

export { FlexRow };
