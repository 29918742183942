import cn from "classnames";
import IconSVG from "../../styles/svg-icons";
import { ParsedBwicProcess } from "../../types/models/ParsedBwicProcess";
import { BwicProcessType, Process } from "../../types/models/Process";
import { formatUtils } from "../../utils/format.utils";

interface BwicRulesButtonProps {
    className?: string;
    isParsed: boolean;
    process: Process;
    parsedProcess?: ParsedBwicProcess;
    withIcon?: boolean;
    showLabel?: boolean;
    onClick: (e: React.MouseEvent) => void;
}

export const BwicRulesPseudoLink = ({ className, isParsed, process, parsedProcess, withIcon = false, showLabel = true, onClick }: BwicRulesButtonProps) => {
    const processType = isParsed ? parsedProcess?.processType : process.type;
    const btnTypeClassName = withIcon ? "btn-link" : "pseudo-link";

    return (
        <button className={cn(btnTypeClassName, "btn-rules", className)}
            onClick={onClick} >
            {withIcon ? <IconSVG name="rules" width={16} height={16} /> : null}
            {processType && processType !== BwicProcessType.Unknown
                ? <>{showLabel && "Rules:"} {formatUtils.formatBwicProcessTitle(processType)}</>
                : 'Rules'}
        </button>
    )
}