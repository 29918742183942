import { isNil } from 'lodash';
import * as Yup from "yup";

const RequiredMessage = 'Required';

export const CloManagerValidationSchema = Yup.object().shape({
    unpriSignatorySince: Yup
        .date()
        .when('unpriSignatory', {
            is: (unpriSignatory: string) => !!unpriSignatory,
            then: () => Yup
                .date()
                .typeError('UNPRI Signatory Since is required')
                .required('UNPRI Signatory Since is required'),
            otherwise: () => Yup
                .date()
                .optional()
                .nullable(),
        }),

    unpriSignatory: Yup
        .string()
        .when('unpriSignatorySince', {
            is: (unpriSignatorySince?: Date) => !!unpriSignatorySince,
            then: () => Yup
                .string()
                .typeError('UNPRI Signatory is required')
                .required('UNPRI Signatory is required'),
            otherwise: () => Yup
                .string()
                .optional()
                .nullable(),
        }),

    countryId: Yup
        .number()
        .typeError(RequiredMessage)
        .required(RequiredMessage),

    stateId: Yup
        .number()
        .when('isUSASelected', {
            is: (isUSASelected: boolean) => isUSASelected,
            then: () => Yup
                .number()
                .typeError(RequiredMessage)
                .required(RequiredMessage),
            otherwise: () => Yup
                .number()
                .optional()
                .nullable(),
        }),

    newCity: Yup
        .string()
        .when('cityId', {
            is: (cityId?: number) => isNil(cityId),
            then: () => Yup
                .string()
                .typeError(RequiredMessage)
                .required(RequiredMessage),
            otherwise: () => Yup
                .string()
                .optional()
                .nullable(),
        }),

    zipCode: Yup
        .string()
        .typeError(RequiredMessage)
        .required(RequiredMessage),

    address: Yup
        .string()
        .typeError(RequiredMessage)
        .required(RequiredMessage),

}, [
    ['unpriSignatory', 'unpriSignatorySince'],
    ['unpriSignatorySince', 'unpriSignatory'],
]);
