import cn from 'classnames';
import { Color as _Color } from "../../types/bwic/Color";
import { formatUtils } from "../../utils/format.utils";

interface Props {
    color: _Color;
}

export function Color({ color }: Props) {
    return (
        <span className={`bwic-color-traded-info status active ${cn({ 'blocked': !color.traded })}`}>
            {formatUtils.formatColor(color)}
        </span>
    );
}