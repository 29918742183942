import * as React from 'react';
import dateTimeUtils from '../../../utils/dateTime.utils';
import { formatUtils } from '../../../utils/format.utils';
import { BwicProcessType, Process } from '../../../types/models/Process';
import { BwicRulesSectionRow } from './BwicRulesSectionRow';

interface BwicRulesSectionGeneralProps {
    process: Process;
    isMy: boolean;
}

export function BwicRulesSectionGeneral({ process, isMy }: BwicRulesSectionGeneralProps) {
    return (
        <div className="rules-section">
            <div className="rules-section-title">
                <h4>General</h4>
            </div>
            <div className="rules-section-content">
                <BwicRulesSectionRow label="Reserve levels apply" dataTestId="reserve-levels-apply">
                    <span className='text-capitalize'>{formatUtils.formatBoolean(process.reserveLevelsApply)}</span>
                </BwicRulesSectionRow>
                <BwicRulesSectionRow label="Can trade early" dataTestId="can-trade-early">
                    <span className='text-capitalize'>{formatUtils.formatBoolean(process.earlyTrades)}</span>
                </BwicRulesSectionRow>
                {process.minBidIncrement && <BwicRulesSectionRow label="Minimum bid improvement">
                    <span className='text-capitalize'>{formatUtils.formatBid(process.minBidIncrement)}</span>
                </BwicRulesSectionRow>}
                {
                    (process.type === BwicProcessType.Standard ||
                        process.type === BwicProcessType.BestFootForward) &&
                    <BwicRulesSectionRow label="Bid improvement">unlimited</BwicRulesSectionRow>
                }
                {
                    process.type === BwicProcessType.Standard &&
                    <BwicRulesSectionRow label="Bid feedback">auto/manual</BwicRulesSectionRow>
                }
                {
                    process.type === BwicProcessType.BestFootForward &&
                    <BwicRulesSectionRow label="Bid feedback">manual</BwicRulesSectionRow>
                }
                {
                    (process.type === BwicProcessType.Standard ||
                        process.type === BwicProcessType.TopX ||
                        process.type === BwicProcessType.JumpBall) &&
                    Boolean(process?.autoFeedbackDelay) && isMy &&
                    <BwicRulesSectionRow label="Autofeedback delay">
                        {` ${dateTimeUtils.parseTimeSpan(process?.autoFeedbackDelay).totalMinutes} minutes`}
                    </BwicRulesSectionRow>
                }
                {process.rulesText &&
                    <BwicRulesSectionRow label="Notes">
                        {process.rulesText}
                    </BwicRulesSectionRow>
                }
            </div>
        </div>
    );
}
