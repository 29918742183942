import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { gridActions } from '../../../actions';
import { constants, gridColumns } from '../../../constants';
import { AppState } from '../../../types/state/AppState';
import { SectionContent } from '../../amrPipeline/common/section/SectionContent';
import { Grid } from '../../grid';
import { Table } from '../../bidding/common/table';
import { editInvestmentTeamColumns } from '../tabs/cloTeam/investment-team.columns';
import { StatusMessageSection } from '../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../types/state/NotificationState';
import { cloManagersActions } from '../../../actions/clo-managers.actions';
import CloManagersContext from '../cloManagersContext';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { InvestmentTeamMember } from '../../../types/amr-pipeline/models/InvestmentTeamMember';
import { GridDataItem } from '../../../types/state/GridState';
import { RouteLeavingGuard } from '../../routing';
import { useHistory } from 'react-router';
import { CloManagerInvestmentTeamMember } from '../../../types/clo-managers/CloManagerInvestmentTeamMember';

export const Contacts = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { ownCompany } = useContext(CloManagersContext);
    const cloManager = useSelector((state: AppState) => state.cloManagers.editManager);
    const bwicCloManagers = useSelector((state: AppState) => state.cloManagers.editBwicCloManagers);

    const dataItems = useSelector((state: AppState) => state.grid.dataItems);
    const nonDraftDataItems = dataItems.filter(d => !d.draft);

    useEffect(() => {
        dispatch(gridActions.reset());
        dispatch(
            gridActions.init(
                cloManager?.investmentTeam || [],
                gridColumns.cloManagersInvestmentTeam(),
                constants.gridRowsLimit,
                10,
                false,
            ),
        );

        return () => {
            dispatch(gridActions.reset());
            dispatch(cloManagersActions.resetEditPlatformUsers());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasChanges = () => {
        const isChangedOrRemoved = (
            original: CloManagerInvestmentTeamMember,
            edit?: GridDataItem<InvestmentTeamMember>,
        ) => {
            if (!edit) return true;

            return (
                original.fullName !== edit.fullName ||
                original.linkedIn !== edit.linkedIn ||
                original.title !== edit.title ||
                original.email !== edit.email ||
                original.phoneNumber !== edit.phoneNumber ||
                original.location !== edit.location ||
                original.meetingContact !== edit.meetingContact
            );
        };

        return cloManager?.investmentTeam?.some(p =>
            isChangedOrRemoved(
                p,
                nonDraftDataItems.find(i => i.fullName === p.fullName),
            ),
        );
    };


    return (
        <>
            <RouteLeavingGuard
                navigate={(pathname: string) => history.push(pathname)}
                shouldBlockNavigation={() => hasChanges()}
            />
            <SectionContent
                title="Contacts"
                className="section-investment-team"
                actionBtn={
                    !!bwicCloManagers?.length && (
                        <StatusMessageSection type={StatusMessageSectionType.Info}>
                            Platform users cannot be edited here
                        </StatusMessageSection>
                    )
                }
            >
                <Table
                    className="data-list data-list-striped data-list-overflow-auto clo-manager-investment-team-table"
                    columns={editInvestmentTeamColumns}
                    dataItems={bwicCloManagers}
                    createSecurityCustomArgs={() => ({ dispatch, ownCompany })}
                />
                <Grid dataUploadDisabled />
            </SectionContent>
        </>
    );
};
