import IconSVG from '../../styles/svg-icons';

type Props = {
    email: string;
};

const FollowUp = ({ email }: Props) => {
    return (
        <a className="btn btn-link regular" href={`mailto:${email}`}>
            <IconSVG width="16" height="16" name="follow-up" />
            Follow up
        </a>
    );
};

export default FollowUp;
