import { useDispatch } from 'react-redux';
import { cloManagersActions } from '../../../../actions/clo-managers.actions';
import { TickerButton } from '../../../common/TickerButton';
import { DealSecurityTableItem } from './columns';

interface Props {
    security: DealSecurityTableItem;
}

export const DealsTickerButton = ({ security }: Props) => {
    const dispatch = useDispatch();

    return (
        <TickerButton
            security={security}
            fetchAgencyRatings={true}
            onClick={() => dispatch(cloManagersActions.selectedSecurityChange(security))}
        />
    )
}
