import IconSVG from '../../../../styles/svg-icons';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../../../access/ActionBlocker';
import { OnHoverTooltip } from '../../../common';

interface StatisticsItemProps {
    label: string;
    redirectRequiredFeatures?: SubscriptionFeature[];
    showLink?: boolean;
    onLinkClick?: () => void;
    children: React.ReactElement;
}

export const StatisticsItem = ({ label, redirectRequiredFeatures, showLink, onLinkClick, children }: StatisticsItemProps) => {
    const renderRedirectButton = () => {
        if (!onLinkClick || !showLink) {
            return null;
        }

        const redirectButton = (disabled: boolean = false) => (
            <>
                {disabled ? (
                    <button className="btn-link disabled">
                        <IconSVG name="go-to" width={16} height={16} />
                    </button>
                ) : (
                    <OnHoverTooltip overlay="See on Issuance Monitor" overlayClassName="trial-tooltip">
                        <button onClick={onLinkClick} disabled={disabled} className="btn-link">
                            <IconSVG name="go-to" width={16} height={16} />
                        </button>
                    </OnHoverTooltip>
                )}
            </>
        );

        if (redirectRequiredFeatures?.length) {
            return (
                <ActionBlocker requireAllFeatures features={redirectRequiredFeatures}>
                    {redirectButton}
                </ActionBlocker>
            );
        }

        return redirectButton();
    };

    return (
        <div className="statistics-item">
            <span>{label}</span>
            <div className="statistics-info">
                {children}
                {renderRedirectButton()}
            </div>
        </div>
    );
}
