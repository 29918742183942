import React, { DragEvent, useRef } from 'react';

interface DragFileZoneProps {
    children: React.ReactNode;
    onFile?: (file: File) => void;
    onFiles?: (files: FileList) => void;
    onDragEnter?: () => void;
    onDragLeave?: () => void;
    className?: string;
}

export function DragFileZone({ children, onFile, onFiles, onDragEnter, onDragLeave, className = 'drag-zone' }: DragFileZoneProps) {
    const dragEnterCount = useRef(0);

    const dragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const dragEnter = (e: DragEvent<HTMLDivElement>) => {
        dragEnterCount.current++;
        onDragEnter && onDragEnter();
        e.preventDefault();
    };

    const dragLeave = (e: DragEvent<HTMLDivElement>) => {
        dragEnterCount.current--;
        if (dragEnterCount.current === 0) {
            onDragLeave && onDragLeave();
        }
        e.preventDefault();
    };

    const fileDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if(typeof onFile === 'function') {
            onFile(e.dataTransfer.files[0]);
        }
        if(typeof onFiles === 'function') {
            onFiles(e.dataTransfer.files);
        }
        onDragLeave && onDragLeave();
        dragEnterCount.current = 0;
    };

    return (
        <div
            className={className}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
        >
            {children}
        </div>
    )
}
