import { constants } from "../../../../../constants";
import { CloManagerAccessType, CloManagerAccessTypes } from "../../../../../types/amr-pipeline/enums/CloManagerAccessType";
import { FormField } from "../../../../amrPipeline/common/FormField";

interface Props {
    views: Record<CloManagerAccessType, number>;
}

export function OverviewTab({ views }: Props) {
    return (
        <FormField
            title="Tabs Viewed"
            value={
                <ul className="tabs-viewed-list">
                    {views &&
                        CloManagerAccessTypes.map((type) => (
                            <li key={type.value} className="tabs-viewed-item">
                                <span className="tabs-viewed-label">
                                    {type.text}
                                </span>
                                {views[type.value]
                                    ? `${views[type.value]} view(s)`
                                    : constants.emptyPlaceholder}
                            </li>
                        ))}
                </ul>
            }
            className="data-item-row-full-height data-item-row-title-md"
        />
    );
}
