import * as React from 'react';
import { Preloader } from './Preloader';

interface Props {
    isNextPageRequesting: boolean;
}
export function InfiniteScrollLoader({ isNextPageRequesting }: Props) {
    if (isNextPageRequesting) {
        return <div className="scroll-loader"><Preloader inProgress={isNextPageRequesting} fullScreen={false} /></div>
    }
    return null;
}
