import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formsActions } from '../../actions';
import { constants } from '../../constants';
import { Popup, PopupBody, PopupFooter } from '../controls';
import { Form, FormFieldLabel, ValidationMessage } from '../forms';

interface Props {
    title?: React.ReactNode;
    textareaPlaceholder?: string;
    comfirmButtonText?: string;
    onReject: (reason: string) => void;
    onClose: () => void;
}

export function RejectReasonPopup({ title = 'Reject Reason', textareaPlaceholder, comfirmButtonText, onReject, onClose }: Props) {
    const dispatch = useDispatch()
    const [rejectReason, setRejectReason] = useState('');

    useEffect(() => {
        return () => {
            dispatch(formsActions.reset());
        }
    }, [dispatch])

    const handleRejectReasonCustomValidate = (reason: string) => constants.nonWhitespace.test(reason);

    const handleSubmit = () => {
        onReject(rejectReason);
        onClose();
    }

    return (
        <Popup
            renderTitle={<h1>{title}</h1>}
            modalClass="modal-reject-form"
            onClose={onClose}
            renderInBody={true}
        >
            <Form
                onSubmit={handleSubmit}
                onCustomValidate={() => handleRejectReasonCustomValidate(rejectReason)}
            >
                <PopupBody>
                    <div className="form-row">
                        <FormFieldLabel text="Description" required />
                        <textarea
                            required
                            className="form-control"
                            name="rejectReason"
                            maxLength={255}
                            value={rejectReason}
                            placeholder={textareaPlaceholder ?? 'Enter the reason for rejecting here'}
                            onChange={e => setRejectReason(e.target.value)}
                        />
                        <ValidationMessage for="rejectReason" onCustomValidate={handleRejectReasonCustomValidate} />
                    </div>
                </PopupBody>
                <PopupFooter>
                    <button type="button" className="btn btn-ghost" onClick={onClose}>Cancel</button>
                    <button type="submit" className="btn btn-main btn-danger" disabled={!rejectReason.trim()}>
                        {comfirmButtonText || 'Reject'}
                    </button>
                </PopupFooter>
            </Form>
        </Popup>
    );
}
