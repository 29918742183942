import { InfoTooltip } from './InfoTooltip';

interface Props {
    isDirectBidding: boolean;
    isAllToAll: boolean;
    infoTooltipVisible?: boolean;
}

export const TradingProtocol = ({ isDirectBidding, isAllToAll, infoTooltipVisible }: Props) => {
    let text = '';
    let tooltip = '';

    if (isAllToAll) {
        text = "All-to-All";
        tooltip = "All-to-All(anonymous) BWIC. Only on-platform members approved to All-to-All trading are able to participate on a BWIC.";
    } else if (isDirectBidding) {
        text = "DirectBidding™";
        tooltip = "DirectBidding™ BWIC. Invited Broker-Dealer members and Buy-Side members approved to DirectBidding™ are able to participate on a BWIC.";
    }

    if (text && tooltip) {
        return (
            <>
                Trading Protocol: {text}
                {infoTooltipVisible && <InfoTooltip text={tooltip} />}
            </>
        );
    }

    return null;
}
