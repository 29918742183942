import * as React from 'react';
import { BwicRulesParsed } from './bwic-types/BwicRulesParsed';
import { Process } from '../../types/models/Process';
import { BwicRulesMain } from './bwic-types/BwicRulesMain';
import { ParsedBwicProcess } from '../../types/models/ParsedBwicProcess';

export interface BwicRulesProps {
    bidsDueDate: Date;
    process: Process;
    parsedProcess?: ParsedBwicProcess;
    isParsed?: boolean;
    goodUntilDate: Date;
    isSameDayBwic: boolean;
    daysToSettle: number;
    isMy: boolean
    isAllToAll: boolean;
    isDirectBidding: boolean;
}

export function BwicRules({ process, parsedProcess, bidsDueDate, goodUntilDate, isParsed, isSameDayBwic, daysToSettle, isMy, isAllToAll, isDirectBidding }: BwicRulesProps) {
    if (!process) {
        return null;
    }

    if (isParsed) {
        return <BwicRulesParsed
            process={process}
            parsedProcess={parsedProcess}
            bidsDueDate={bidsDueDate}
            goodUntilDate={goodUntilDate}
            isSameDayBwic={isSameDayBwic}
            daysToSettle={daysToSettle} />;
    }

    return (
        <BwicRulesMain
            process={process}
            bidsDueDate={bidsDueDate}
            goodUntilDate={goodUntilDate}
            isSameDayBwic={isSameDayBwic}
            daysToSettle={daysToSettle}
            isMy={isMy}
            isAllToAll={isAllToAll}
            isDirectBidding={isDirectBidding}
        />
    );
}

