interface Props {
    title: string;
    visible?: boolean;
    children: React.ReactNode;
}
export function BwicRulesSection({ title, visible = true, children }: Props) {
    if(!visible) return null;

    return (
        <div className="rules-section">
            <div className="rules-section-title">
                <h4>{title}</h4>
            </div>
            <div className="rules-section-content">
                {children}
            </div>
        </div>
    );
}