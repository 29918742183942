import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../../actions';
import { cloManagersService } from '../../../services/clo-managers.service';

export const useRequestScheduleMeeting = (
    cloManagerReferenceName: string,
    emailList: string[],
    message: string,
    sending: boolean,
    setSending: (isSending: boolean) => void,
    onLoadSuccess: () => void,
) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const request = async () => {
            try {
                await cloManagersService.scheduleMeeting(
                    cloManagerReferenceName,
                    emailList,
                    message,
                );
                onLoadSuccess();
            } catch (e) {
                dispatch(notificationActions.notificationAddErrorMessage('Error'));
            } finally {
                setSending(false);
            }
        };

        if (sending) {
            request();
        }
        // eslint-disable-next-line
    }, [sending]);
};
