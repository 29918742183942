import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../types/state/AppState';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { AnalyticsWidget } from '../../../../amrPipeline/common/analytics/AnalyticsWidget';
import { CloManagerAnalyticsFilter } from '../../../../../types/analytics/AnalyticsFilter';
import { CloManagerAccessType } from '../../../../../types/amr-pipeline/enums/CloManagerAccessType';
import { cloManagersActions } from '../../../../../actions/clo-managers.actions';
import { ChartRequiredFeature } from '../../../../access/ChartRequiredFeature';
import { BaseAnalyticsComponentProps, GetAction, withAnalyticsRequest } from '../../../../common/manager-analytics/withAnalyticsRequest';
import { ClientsActivityChart } from '../../../../common/manager-analytics/ClientsActivityChart';

const selector = (state: AppState) => state.cloManagers.analytics;

const getAction = (companyReferenceName: string, filter: CloManagerAnalyticsFilter) => {
    return cloManagersActions.clientsActivityRequest(
        companyReferenceName,
        filter.date.dateFrom || undefined,
        filter.date.dateTo || undefined,
    );
};

function ClientsActivityWidgetComponent({ companyReferenceName, isLoaded, users, accessTypes }: BaseAnalyticsComponentProps<CloManagerAccessType>) {
    const dispatch = useDispatch();
    const state = useSelector(selector);

    const { clientsActivity } = state;
    const { filter, data } = clientsActivity;

    const handleFilterChange = useCallback((filter: CloManagerAnalyticsFilter) => {
        dispatch(cloManagersActions.clientsActivityFilterChange(filter));
    }, [dispatch]);

    const handleBarClick = useCallback((accessType: CloManagerAccessType, date: Date) => {
        dispatch(
            cloManagersActions.profileViewHistoryFilterByBar(
                companyReferenceName,
                accessType,
                date
            )
        );
    }, [dispatch, companyReferenceName]);

    const filteredClientsActivity = useMemo(() => data
        .filter(row => {
            if (filter.users.length && !filter.users.includes(row.userEmail)) {
                return false;
            }

            if (filter.tabs.length && !filter.tabs.some(x => x === row.accessType)) {
                return false;
            }

            return true;
        }), [data, filter.users, filter.tabs]);

    const chart = useMemo(() => <ClientsActivityChart
        onBarClick={handleBarClick}
        filter={filter}
        data={filteredClientsActivity}
        accessTypes={accessTypes}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    />, [handleBarClick, filter, filteredClientsActivity]);

    return (
        <ChartRequiredFeature
            feature={SubscriptionFeature.ManagerProfileAnalytics}
            chartName="Clients Activity"
            blockedClassName="restricted-placeholder-clients-activity"
        >
            <AnalyticsWidget
                title="Clients Activity"
                users={users}
                isLoading={isLoaded}
                onFilterChange={handleFilterChange}
                state={clientsActivity}
                tabDefinitions={accessTypes}
                feature={SubscriptionFeature.ManagerProfileAnalytics}
            >
                {chart}
            </AnalyticsWidget >
        </ChartRequiredFeature>
    );
}

export const ClientsActivityWidget = withAnalyticsRequest(
    getAction as GetAction,
    selector,
    (state) => state.clientsActivity,
)(ClientsActivityWidgetComponent);
