import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { FeatureButton } from '../access/FeatureButton';
import { favoriteBrokerDealersActions } from '../../actions/favorite-broker-dealers.actions';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import classNames from 'classnames';
import IconSVG from '../../styles/svg-icons';
import { RoleActionBlocker } from '../access/RoleActionBlocker';
import { roles } from '../../constants';

interface Props {
    companyId: number;
    disabled?: boolean;
    hideWhenNotFavorite?: boolean
}

export function FavoriteButton({ companyId, disabled = false, hideWhenNotFavorite = false }: Props) {
    const dispatch = useDispatch();
    const isFavorite = useSelector((s: AppState) => s.favoriteBrokerDealers.favorites[companyId]);
    const isUpdatingFavorite = useSelector((state: AppState) => state.favoriteBrokerDealers.isUpdatingFavorite[companyId]);

    const handleToggleFavorite = (companyId: number, isFavorite: boolean, e: React.MouseEvent) => {
        e.stopPropagation();

        if (!isUpdatingFavorite) {
            dispatch(favoriteBrokerDealersActions.updateFavoriteBrokerDealerRequest(companyId, isFavorite))
        }
    };

    const getTitle = () => {
        if (disabled) {
            return isFavorite ? 'Favorite' : null;
        }
        return (isFavorite ? "Remove from favorite" : "Add to favorite");
    };

    if (hideWhenNotFavorite && !isFavorite && disabled) return null;

    return (
        <RoleActionBlocker roles={[roles.SellerTrader]}>
            <OnHoverTooltip overlay={getTitle()}>
                <FeatureButton
                    className={classNames('btn-favorite', { 'selected': isFavorite })}
                    disabled={disabled}
                    onClick={(e) => handleToggleFavorite(companyId, isFavorite, e)}
                >
                    {
                        isFavorite
                            ? <IconSVG name="favorite-fill" width={16} height={16} />
                            : <IconSVG name="favorite" width={16} height={16} />
                    }
                </FeatureButton>
            </OnHoverTooltip>
        </RoleActionBlocker>
    );
}
