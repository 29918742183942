import { AumStatisticsItem } from './AumStatisticsItem'
import { StatisticsItem } from './StatisticsItem'
import { TotalStatisticsItem } from './TotalStatisticsItem'
import { routes } from '../../../../constants';
import { TransactionStatus } from '../../../../types/amr-pipeline/enums/TransactionStatus';
import { QueryStringArgumentSerializer } from '../../../../utils/filtering/serializers/QueryStringArgumentSerializer';
import { amr, collateralManagers, collateralTypes, imPipelineTab, multipleCurrencies, transactionStatuses, transactionTypes } from '../../../../utils/filtering/serializers/serializer.definitions';
import { queryStringSerializer } from '../../../../utils/filtering';
import { IssuanceMonitorTab } from '../../../amrPipeline/types/PipelineTabTypes';
import { CloManagerAccessType } from '../../../../types/amr-pipeline/enums/CloManagerAccessType';
import { useDispatch } from 'react-redux';
import { cloManagersActions } from '../../../../actions/clo-managers.actions';
import { StatisticsChartsTransactionStatuses, StatisticsChartsTransactionTypes } from './constants';
import { Currency } from '../../../../types/enums/Currency';
import { CollateralType } from '../../../../types/amr-pipeline/enums/CollateralType';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { user } from '../../../../user';
import { CompanyStats } from '../../../../types/amr-pipeline/models/CompanyStats';

interface StatisticsProps {
    companyReferenceName: string;
    stats?: CompanyStats;
}

export const Statistics = ({ companyReferenceName, stats }: StatisticsProps) => {
    const dispatch = useDispatch();

    if (!stats) {
        return null;
    }

    const redirectToIssuanceMonitor = (qsBuilder: (...params: any) => string, cloManagerAccessType?: CloManagerAccessType, ) => (...params: any) => {
        const queryString = qsBuilder(...params);

        if (cloManagerAccessType) {
            dispatch(cloManagersActions.logUserActivity(companyReferenceName, cloManagerAccessType));
        }

        window.open(`${window.location.origin}${routes.AMRPipeline}/?${queryString}`);
    };

    const redirectToArrangerPipeline = redirectToIssuanceMonitor(() => {
        const searchParams = {
            collateralManagers: [companyReferenceName],
            imPipelineTab: IssuanceMonitorTab.ArrangerPipeline,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            imPipelineTab(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewUpcomingDeals);

    const redirectToAmrDeals = redirectToIssuanceMonitor(() => {
        const searchParams = {
            collateralManagers: [companyReferenceName],
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
            amr: true,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            transactionStatuses(),
            transactionTypes(),
            amr(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewAmrDeal);

    const redirectToTotalTransactions = redirectToIssuanceMonitor(() => {
        const hasImAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorDealUpdateEmailAlerts);

        const searchParams = {
            collateralManagers: [companyReferenceName],
            // Generate different links for subscribed and unsubscribed users
            // to show `see N more deals` placeholder in particular case
            transactionStatuses: hasImAccess ? StatisticsChartsTransactionStatuses : undefined,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, CloManagerAccessType.OverviewTotalTransactions);

    const redirectToActiveTransactions = redirectToIssuanceMonitor(() => {
        const searchParams = {
            collateralManagers: [companyReferenceName],
            transactionStatuses: [TransactionStatus.Active],
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            collateralManagers(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    });

    const redirectToVolume = (currency: Currency, collateralType: CollateralType, accessType: CloManagerAccessType) =>
        redirectToIssuanceMonitor(() => {
            const hasImAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorDealUpdateEmailAlerts);

            const searchParams = {
                collateralManagers: [companyReferenceName],
                // Generate different links for subscribed and unsubscribed users
                // to show `see N more deals` placeholder in particular case
                transactionStatuses: hasImAccess ? StatisticsChartsTransactionStatuses : undefined,
                transactionTypes: StatisticsChartsTransactionTypes,
                currency: [currency],
                collateralTypes: [collateralType],
            };

            const serializer: QueryStringArgumentSerializer<any>[] = [
                collateralManagers(),
                transactionStatuses(),
                transactionTypes(),
                multipleCurrencies(),
                collateralTypes(),
            ];

            return queryStringSerializer.serialize(searchParams, serializer);
        }, accessType);

    return (
        <div className="statistics-row">
            <StatisticsItem
                label="Vol. (USD BSL)"
                redirectRequiredFeatures={[SubscriptionFeature.ManagerProfilePrimaryMarketData]}
                showLink={!!stats.usdBslCount}
                onLinkClick={redirectToVolume(Currency.USD, CollateralType.broadlySyndicated, CloManagerAccessType.VolUsdBslDoor)}
            >
                <AumStatisticsItem value={stats.usdBslVolume} count={stats.usdBslCount} />
            </StatisticsItem>
            <StatisticsItem
                label="Vol. (USD MM)"
                redirectRequiredFeatures={[SubscriptionFeature.ManagerProfilePrimaryMarketData]}
                showLink={!!stats.usdMmCount}
                onLinkClick={redirectToVolume(Currency.USD, CollateralType.middleMarket, CloManagerAccessType.VolUsdMmDoor)}
            >
                <AumStatisticsItem value={stats.usdMmVolume} count={stats.usdMmCount} />
            </StatisticsItem>
            <StatisticsItem
                label="Vol. (EUR BSL)"
                redirectRequiredFeatures={[SubscriptionFeature.ManagerProfilePrimaryMarketData]}
                showLink={!!stats.eurBslCount}
                onLinkClick={redirectToVolume(Currency.EUR, CollateralType.broadlySyndicated, CloManagerAccessType.VolEurBslDoor)}
            >
                <AumStatisticsItem value={stats.eurBslVolume} count={stats.eurBslCount} />
            </StatisticsItem>
            <StatisticsItem
                label="Vol. (EUR MM)"
                redirectRequiredFeatures={[SubscriptionFeature.ManagerProfilePrimaryMarketData]}
                showLink={!!stats.eurMmCount}
                onLinkClick={redirectToVolume(Currency.EUR, CollateralType.middleMarket, CloManagerAccessType.VolEurMmDoor)}
            >
                <AumStatisticsItem value={stats.eurMmVolume} count={stats.eurMmCount} />
            </StatisticsItem>
            <StatisticsItem
                label="Active Trans."
                redirectRequiredFeatures={[SubscriptionFeature.ManagerProfilePrimaryMarketData]}
                showLink={!!stats.activeTransactions}
                onLinkClick={redirectToActiveTransactions}
            >
                <TotalStatisticsItem count={stats.activeTransactions}/>
            </StatisticsItem>
            <StatisticsItem
                label="Total Trans."
                redirectRequiredFeatures={[SubscriptionFeature.ManagerProfilePrimaryMarketData]}
                showLink={!!stats.totalTransactions}
                onLinkClick={redirectToTotalTransactions}
            >
                <TotalStatisticsItem count={stats.totalTransactions}/>
            </StatisticsItem>
            <StatisticsItem label="Total Deals">
                <TotalStatisticsItem count={stats.totalDeals} />
            </StatisticsItem>
            <StatisticsItem
                label="Upcoming Deals"
                showLink={!!stats.totalPipelines}
                redirectRequiredFeatures={[SubscriptionFeature.ManagerProfilePrimaryMarketData, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                onLinkClick={redirectToArrangerPipeline}
            >
                <TotalStatisticsItem count={stats.totalPipelines} />
            </StatisticsItem>
            <StatisticsItem
                label="AMR Deals"
                showLink={!!stats.numberOfAmrTransactions}
                redirectRequiredFeatures={[SubscriptionFeature.ManagerProfilePrimaryMarketData, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                onLinkClick={redirectToAmrDeals}
            >
                <TotalStatisticsItem count={stats.numberOfAmrTransactions}/>
            </StatisticsItem>
        </div>
    );
}
