import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RejectReasonPopup } from '../../common/RejectReasonPopup';
import { SettlementAgreementStatus } from '../../../types/bid-as-dealer/SettlementAgreementStatus';
import { clientsCompaniesActions } from '../../../actions/clients-companies.actions';
import { UpdateSettlementAgentAgreementStatus } from '../../../types/bid-as-dealer/UpdateSettlementAgentAgreementStatus';
import IconSVG from '../../../styles/svg-icons';
import { Confirm } from "../../alerts";
import { FeatureButton } from '../../access/FeatureButton';
import { AppState } from '../../../types/state/AppState';
import { isRequesting } from '../../../utils';
import { Relative, Preloader } from '../../common';
import { ClientsTableDataItem } from './ClientsTableDataItem';

interface Props {
    entity: ClientsTableDataItem
}

export function AgreementActionButtons({ entity }: Props) {
    const dispatch = useDispatch();

    const [visibleEditReasonPopup, setVisibleEditReasonPopup] = React.useState(false);
    const [visibleUndoConfirmPopup, setVisibleUndoConfirmPopup] = React.useState(false);

    const isUpdatingRequestState = useSelector((state: AppState) => state.clientsCompanies.requestStatusUpdateRequest[entity.signatory.id]);

    const handleAffirm = () => {
        dispatch(clientsCompaniesActions.hideActivePanels());
        dispatch(clientsCompaniesActions.setSelectedAgreement(entity.signatory.id))
    };

    const handleReject = (message: string) => {
        const requestData: UpdateSettlementAgentAgreementStatus = {
            bidderId: entity.signatory.id,
            agreementStatus: SettlementAgreementStatus.rejected,
            comment: message
        };
        dispatch(clientsCompaniesActions.updateAgreementRequest(requestData));
        setVisibleEditReasonPopup(false);
    };

    const handleUndoConfirm = () => {
        const requestData: UpdateSettlementAgentAgreementStatus = {
            bidderId: entity.signatory.id,
            agreementStatus: SettlementAgreementStatus.pending,
            dailyTradingLimit: {
                common: 0,
                byRating: {}
            },
        };
        dispatch(clientsCompaniesActions.updateAgreementRequest(requestData));
        visibleUndoConfirmPopup && setVisibleUndoConfirmPopup(false);
    }

    const handleEdit = () => {
        dispatch(clientsCompaniesActions.hideActivePanels());
        dispatch(clientsCompaniesActions.setSelectedAgreement(entity.signatory.id));
    };

    const handleSetVisibleReasonPopup = () => {
        dispatch(clientsCompaniesActions.resetSelectedAgreement());
        setVisibleEditReasonPopup(true);
    };

    const renderContent = () => {
        if (entity.agreementStatus === SettlementAgreementStatus.pending) {
            return (
                <div className="flex-row flex-item-right">
                    <FeatureButton className="btn-link btn-affirm flex-item-right" onClick={handleAffirm}>
                        <IconSVG name="saved" width={16} height={16} /><span>Approve</span>
                    </FeatureButton>
                    <FeatureButton className="btn-link btn-reject btn-danger" onClick={handleSetVisibleReasonPopup}>
                        <IconSVG name="close" width={16} height={16} /><span>Suspend</span>
                    </FeatureButton>
                </div>
            )
        } else if (entity.agreementStatus === SettlementAgreementStatus.confirmed) {
            return (
                <div className="flex-row">
                    <FeatureButton className="btn-link btn-edit flex-item-right" onClick={handleEdit}>
                        <IconSVG name="edit-pencil" width={16} height={16} />Edit Limit
                    </FeatureButton>
                    <FeatureButton className="btn-link btn-undo" onClick={() => setVisibleUndoConfirmPopup(true)}>
                        <IconSVG name="undo" width={16} height={16} />Undo
                    </FeatureButton>
                </div>
            );
        } else if (entity.agreementStatus === SettlementAgreementStatus.rejected) {
            return (
                <div className="flex-row">
                    <FeatureButton className="btn-link btn-undo" onClick={handleUndoConfirm}>
                        <IconSVG name="undo" width={16} height={16} />Undo
                    </FeatureButton>
                </div>
            );
        }
        return null;
    };

    return (
        <Relative>
            <Preloader small={true} inProgress={isRequesting(isUpdatingRequestState)} fullScreen={false} text="Processing...">
                {renderContent()}
            </Preloader>
            {
                visibleEditReasonPopup &&
                <RejectReasonPopup
                    title="Suspend Reason"
                    onClose={() => setVisibleEditReasonPopup(false)}
                    onReject={handleReject}
                    textareaPlaceholder="Enter the reason for suspending here"
                    comfirmButtonText="Suspend"
                />
            }
            {
                visibleUndoConfirmPopup &&
                <Confirm
                    title="Confirmation"
                    text={`Please confirm you would like to disable bidding access for ${entity.signatory.name}.`}
                    onCancel={() => setVisibleUndoConfirmPopup(false)}
                    onConfirm={handleUndoConfirm}
                />
            }
        </Relative>
    )
}
