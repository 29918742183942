import React, { PropsWithChildren, ReactNode } from 'react';
import IconSVG from '../../styles/svg-icons';

interface Props {
    title?: string
    hint?: ReactNode;
    renderTitle?: () => ReactNode;
}

export const CollapsablePanel: React.FC<PropsWithChildren<Props>> = ({ children, title, renderTitle, hint }) => {
    const [collapsed, setCollapsed] = React.useState(true);
    const toogleCollapsed = () => setCollapsed(!collapsed);

    const renderDefaultTitle = () => (
        <>
            <h2>{title}</h2>
            {!collapsed && <span className="secondary-text">{hint}</span>}
        </>
    )
    return (
        <div className="collapsable-panel">
            <div className="collapsable-panel-title">
                <div className="collapsable-panel-click-area" onClick={toogleCollapsed}>
                    <IconSVG name="corner-arrow" className={`${!collapsed ? 'is-open' : ''}`} width={16} height={16} />
                    {renderTitle ? renderTitle() : renderDefaultTitle()}
                </div>
            </div>
            <div className="collapsable-panel-cnt">
                {collapsed && children}
            </div>
        </div>
    )
};
