import { User } from '../../../types/account/User';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';

const scheduleMeetingMailPreviewColumnsDefinition: IColumnDefinition<User>[] = [
    {
        columnKey: 'name',
        renderColumnHeaderContent: () => '',
        renderColumnContent: user => `${user.firstName} ${user.lastName}`,
        className: 'data-list-cell-xl',
    },
    {
        columnKey: 'email',
        renderColumnHeaderContent: () => '',
        renderColumnContent: user => <span className="user-email">{user.email}</span>,
        className: 'data-list-cell-xl',
    },
    {
        columnKey: 'phone',
        renderColumnHeaderContent: () => '',
        renderColumnContent: user => user.publicPhone,
        className: 'data-list-cell-lg',
    },
];

export const scheduleMeetingMailPreviewColumns = scheduleMeetingMailPreviewColumnsDefinition.map(c => {
    return new ColumnBuilder(c);
});
