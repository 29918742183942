import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    alertOptionTooltipText,
    alertOptions,
    analyticAlertOptions,
    secondaryStatsAlertOptions,
} from '../../constants/amr-pipeline/alertOptions';
import { AlertOption } from '../../types/email-preferences/EmailPreferences';
import { AppState } from '../../types/state/AppState';
import { CompanyAlertsConfig, SecondaryStatsAlertsConfig } from '../../types/user-config/UserConfig';
import { Preloader } from '../common';
import { Popup, PopupBody, PopupFooter } from '../controls';
import { CloManager } from '../../types/clo-managers/CloManager';
import { EmailPreferencesRadioGroup } from '../profile/tabs/email-preferences/tabs/EmailPreferencesRadioGroup';
import { UserConfigType } from '../../types/user-config/UserConfigType';
import { imUserConfigActions } from '../../actions';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';
import { EmailPreferencesTab } from '../profile/tabs/email-preferences/EmailPreferencesContent';
import { alertDescriptions } from '../../constants/cloManagers/alertDescriptions';
import { isCollateralManagersOwnCompany } from '../../utils/amr-pipeline.utils';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { user } from '../../user';

interface Props {
    selectedManager: CloManager;
    onClose: () => void;
}

export const CloManagerAlertsPopup = ({ selectedManager, onClose }: Props) => {
    const dispatch = useDispatch();
    const userCompany = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);

    const isSellerCollateralManager = isCollateralManagersOwnCompany(selectedManager, userCompany);

    const { isUpdating: isUserConfigUpdating, isDeleting: isUserConfigDeleting } = useSelector(
        (state: AppState) => state.imUserConfig,
    );

    const cloManagerAnalyticsConfig = useSelector<AppState, CompanyAlertsConfig>(
        (state: AppState) => state.imUserConfig.cloManagerAnalyticsAlert,
    ).value.find(c => c.companyReferenceName === selectedManager?.referenceName);

    const cloManagerImConfig = useSelector<AppState, CompanyAlertsConfig>(
        (state: AppState) => state.imUserConfig.cloManagerIssuanceMonitorAlert,
    ).value.find(c => c.companyReferenceName === selectedManager?.referenceName);

    const cloManagerApConfig = useSelector<AppState, CompanyAlertsConfig>(
        (state: AppState) => state.imUserConfig.cloManagerArrangerPipelineAlert,
    ).value.find(c => c.companyReferenceName === selectedManager?.referenceName);

    const cloManagerBwicConfig = useSelector<AppState, SecondaryStatsAlertsConfig>(
        (state: AppState) => state.imUserConfig.cloManagerBwicAlert,
    ).value.find(c => c.companyReferenceName === selectedManager?.referenceName);

    const cloManagerDealerInventoryConfig = useSelector<AppState, SecondaryStatsAlertsConfig>(
        (state: AppState) => state.imUserConfig.cloManagerDealerInventoryAlert,
    ).value.find(c => c.companyReferenceName === selectedManager?.referenceName);

    const [editCloManagerAnalyticsConfig, setEditCloManagerAnalyticsConfig] = useState(cloManagerAnalyticsConfig);
    const [editCloManagerImConfig, setEditCloManagerImConfig] = useState(cloManagerImConfig);
    const [editCloManagerApConfig, setEditCloManagerApConfig] = useState(cloManagerApConfig);
    const [editCloManagerBwicConfig, setEditCloManagerBwicConfig] = useState(cloManagerBwicConfig);
    const [editCloManagerDiConfig, setEditCloManagerDiConfig] = useState(cloManagerDealerInventoryConfig);

    const cloManagerAnalyticsAlert = cloManagerAnalyticsConfig?.alertOption || AlertOption.Never;
    const cloManagerImAlert = cloManagerImConfig?.alertOption || AlertOption.Never;
    const cloManagerApAlert = cloManagerApConfig?.alertOption || AlertOption.Never;
    const cloManagerBwicAlert = cloManagerBwicConfig?.alertOption || AlertOption.Never;
    const cloManagerDiAlert = cloManagerDealerInventoryConfig?.alertOption || AlertOption.Never;

    const selectedCloManagerAnalyticsAlert = editCloManagerAnalyticsConfig?.alertOption || AlertOption.Never;
    const selectedCloManagerImAlert = editCloManagerImConfig?.alertOption || AlertOption.Never;
    const selectedCloManagerApAlert = editCloManagerApConfig?.alertOption || AlertOption.Never;
    const selectedCloManagerBwicAlert = editCloManagerBwicConfig?.alertOption || AlertOption.Never;
    const selectedCloManagerDiAlert = editCloManagerDiConfig?.alertOption || AlertOption.Never;

    const isAlertsInProgress = isUserConfigUpdating || isUserConfigDeleting;
    const isInitialAlertsState =
        cloManagerAnalyticsAlert === selectedCloManagerAnalyticsAlert &&
        cloManagerImAlert === selectedCloManagerImAlert &&
        cloManagerApAlert === selectedCloManagerApAlert &&
        cloManagerBwicAlert === selectedCloManagerBwicAlert &&
        cloManagerDiAlert === selectedCloManagerDiAlert;
    const isSaveDisabled = isInitialAlertsState || isAlertsInProgress;

    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!isAlertsInProgress && saving) {
            onClose();
            setSaving(false);
        }
    }, [isAlertsInProgress, saving, onClose]);

    useEffect(() => {
        setEditCloManagerAnalyticsConfig(cloManagerAnalyticsConfig);
        setEditCloManagerImConfig(cloManagerImConfig);
        setEditCloManagerApConfig(cloManagerApConfig);
        setEditCloManagerBwicConfig(cloManagerBwicConfig);
        setEditCloManagerDiConfig(cloManagerDealerInventoryConfig);
    }, [
        cloManagerAnalyticsConfig,
        cloManagerImConfig,
        cloManagerApConfig,
        cloManagerBwicConfig,
        cloManagerDealerInventoryConfig,
    ]);

    const handleSave = () => {
        setSaving(true);

        const configs = [
            ...(isSellerCollateralManager && editCloManagerAnalyticsConfig && user.hasAllFeatures(SubscriptionFeature.ManagerProfileManagerRelatedAlerts)
                ? [{ type: UserConfigType.cloManagerAnalyticsAlert, value: editCloManagerAnalyticsConfig }]
                : []),
            ...(editCloManagerApConfig && user.hasAllFeatures(SubscriptionFeature.ManagerProfileManagerRelatedAlerts, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts)
                ? [{ type: UserConfigType.cloManagerArrangerPipelineAlert, value: editCloManagerApConfig }]
                : []),
            ...(editCloManagerImConfig && user.hasAllFeatures(SubscriptionFeature.ManagerProfileManagerRelatedAlerts, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts)
                ? [{ type: UserConfigType.cloManagerIssuanceMonitorAlert, value: editCloManagerImConfig }]
                : []),
            ...(editCloManagerBwicConfig && user.hasAllFeatures(SubscriptionFeature.BwicMonitorAlerts)
                ? [{ type: UserConfigType.cloManagerBwicAlert, value: editCloManagerBwicConfig }]
                : []),
            ...(editCloManagerDiConfig && user.hasAllFeatures(SubscriptionFeature.BwicMonitorAlerts)
                ? [{ type: UserConfigType.cloManagerDealerInventoryAlert, value: editCloManagerDiConfig }]
                : []),
        ];

        dispatch(imUserConfigActions.saveCompanyAlerts(configs));
    };

    const renderAlertSection = (
        groupNameIndentifier: string,
        title: string,
        alertOptions: AlertOption[],
        selectedOption: AlertOption,
        onChange: (a: AlertOption) => void,
        description: string,
        requiredFeatures?: SubscriptionFeature[],
        alertOptionInfo?: { [key: string]: string },
    ) => (
        <div className="controls-wrap">
            <EmailPreferencesRadioGroup
                value={selectedOption}
                disabled={isAlertsInProgress}
                alertOptions={alertOptions}
                alertOptionInfo={alertOptionInfo}
                groupNameIndentifier={groupNameIndentifier}
                title={title}
                description={description}
                onChange={onChange}
                requiredFeatures={requiredFeatures}
            />
        </div>
    );

    return (
        <Popup
            title={
                <>
                    <span className="title">Manager Alerts</span>
                    <span className="name-tag text-ellipsis">{selectedManager.legalName}</span>
                </>
            }
            onClose={onClose}
            modalClass="modal-email-preferences"
        >
            <PopupBody className="notifications-center">
                {isSellerCollateralManager &&
                    renderAlertSection(
                        'analytics',
                        'Analytics',
                        analyticAlertOptions,
                        selectedCloManagerAnalyticsAlert,
                        a =>
                            setEditCloManagerAnalyticsConfig({
                                ...editCloManagerAnalyticsConfig!,
                                companyReferenceName: selectedManager.referenceName,
                                alertOption: (a as AlertOption.Daily) || AlertOption.Never,
                            }),
                        alertDescriptions.analytics,
                        [SubscriptionFeature.ManagerProfileManagerRelatedAlerts],
                        {
                            [AlertOption.Daily]: 'Alerts are sent at 5 AM EST every business day excluding holidays',
                        },
                    )}
                {renderAlertSection(
                    'clo-manager-ap',
                    'Arrangers Pipeline',
                    alertOptions,
                    selectedCloManagerApAlert,
                    a =>
                        setEditCloManagerApConfig({
                            ...editCloManagerApConfig!,
                            companyReferenceName: selectedManager.referenceName,
                            alertOption: (a as AlertOption.Daily) || AlertOption.Never,
                        }),
                    alertDescriptions.arrangersPipeline,
                    [SubscriptionFeature.ManagerProfileManagerRelatedAlerts, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts],
                    {
                        [AlertOption.Daily]: alertOptionTooltipText.Daily,
                        [AlertOption.Instant]:
                            'Alerts are sent once manager’s upcoming transactions are published on Arranger Pipeline',
                    },
                )}
                {renderAlertSection(
                    'clo-manager-im',
                    'Issuance Monitor',
                    alertOptions,
                    selectedCloManagerImAlert,
                    a =>
                        setEditCloManagerImConfig({
                            ...editCloManagerImConfig!,
                            companyReferenceName: selectedManager.referenceName,
                            alertOption: (a as AlertOption.Daily) || AlertOption.Never,
                        }),
                    alertDescriptions.issuanceMonitor,
                    [SubscriptionFeature.ManagerProfileManagerRelatedAlerts, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts],
                    {
                        [AlertOption.Daily]:
                            'Alerts are sent at 5 PM EST every business day excluding holidays if manager’s transactions are published or updated',
                        [AlertOption.Instant]: 'Alerts are sent once manager’s transactions are published or updated',
                    },
                )}
                {renderAlertSection(
                    'clo-manager-bwic',
                    'BWIC',
                    secondaryStatsAlertOptions,
                    selectedCloManagerBwicAlert,
                    a =>
                        setEditCloManagerBwicConfig({
                            ...editCloManagerBwicConfig!,
                            companyReferenceName: selectedManager.referenceName,
                            alertOption: (a as AlertOption.Instant) || AlertOption.Never,
                        }),
                    alertDescriptions.bwic,
                    [SubscriptionFeature.BwicMonitorAlerts],
                    {
                        [AlertOption.Instant]:
                            "Alerts are sent once the BWIC is posted/Post-BWIC color is added on manager's security.",
                    },
                )}
                {renderAlertSection(
                    'clo-manager-dealer-inventory',
                    'Dealer Inventory',
                    secondaryStatsAlertOptions,
                    selectedCloManagerDiAlert,
                    a =>
                        setEditCloManagerDiConfig({
                            ...editCloManagerDiConfig!,
                            companyReferenceName: selectedManager.referenceName,
                            alertOption: (a as AlertOption.Instant) || AlertOption.Never,
                        }),
                    alertDescriptions.dealerInventory,
                    [SubscriptionFeature.BwicMonitorAlerts],
                    {
                        [AlertOption.Instant]:
                            "Alerts are sent once manager's security was added/updated/marked as traded on the Dealer Inventory.",
                    },
                )}
            </PopupBody>
            <PopupFooter>
                <Link
                    to={routes.profileNotificationsTab(EmailPreferencesTab.CloManagers)}
                    className="btn-link margin-r-a"
                >
                    Manage Alerts
                </Link>
                <button className="btn btn-ghost" onClick={onClose}>
                    Cancel
                </button>
                <button className="btn btn-main" onClick={handleSave} disabled={isSaveDisabled}>
                    Save {isAlertsInProgress && <Preloader small inProgress />}
                </button>
            </PopupFooter>
        </Popup>
    );
};
