import * as React from 'react';
import cn from 'classnames';
import { OnHoverTooltip } from './OnHoverTooltip';
import IconSVG from '../../styles/svg-icons';

interface Props {
    text?: string;
    disabled?: boolean;
}

export function InfoTooltip({ text, disabled }: Props) {
    const classes = cn(
        "info-tooltip",
        {
            "display-inline text-light-grey": disabled
        }
    );

    if (disabled) {
        return <div className={classes}><IconSVG name="info" width={16} height={16} /></div>
    }

    return (
        <OnHoverTooltip wrapperClass={classes} overlay={text}>
            {text && <IconSVG name="info" width={16} height={16} />}
        </OnHoverTooltip>
    );
}
