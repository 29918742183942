import moment from 'moment';
import { BwicProcessType, Process } from '../../../types/models/Process';
import { BwicRulesStartEndTime } from '../sections/BwicRulesStartEndTime';
import { RequiredFeature } from '../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { SubscribeLink } from '../../access/BlockedFeatureText';
import { ParsedBwicProcess, ParsedBwicTwoStageSettings } from '../../../types/models/ParsedBwicProcess';
import { BwicRulesSection } from '../BwicRulesSection';
import { BwicRulesSectionRow } from '../sections/BwicRulesSectionRow';
import { formatUtils } from '../../../utils/format.utils';
import { TimeSpan } from '../../controls/TimeSpan';
import { constants } from '../../../constants/constants';
import { getProcessSpecificSettings } from '../../../actions/edit.parsed.bwic.actions';
import { InfoTooltip } from "../../common/InfoTooltip";

interface BwicRulesParsedProps {
    process: Process;
    parsedProcess?: ParsedBwicProcess;
    bidsDueDate: Date;
    goodUntilDate: Date;
    isSameDayBwic: boolean;
    daysToSettle: number;
}

export function BwicRulesParsed({ process, parsedProcess, bidsDueDate, goodUntilDate, isSameDayBwic, daysToSettle }: BwicRulesParsedProps) {
    if (!process) return null;

    const isGeneralSectionVisible =
        parsedProcess?.reserveLevelsApply !== null ||
        parsedProcess?.canTradeEarly != null ||
        parsedProcess.canBidEarly != null ||
        parsedProcess.partialBidsAccepted != null ||
        !!parsedProcess.bidsGoodFor;

    const settings = getProcessSpecificSettings(parsedProcess);
    const isTwoStage = (settings as ParsedBwicTwoStageSettings)?.hardCutOff === 2;
    const stage2EndDateUtc = (settings as ParsedBwicTwoStageSettings)?.stage2EndDateUtc;
    const stage2ImproverCount = (settings as ParsedBwicTwoStageSettings)?.improverCount;
    const processNameFormatted = parsedProcess?.processType
        ? formatUtils.formatBwicProcessTitle(parsedProcess.processType)
        : null;

    return (
        <div className="rules flex-row">
            <RequiredFeature
                feature={SubscriptionFeature.BwicMonitorBwicsData}
                text={<>Rules access is limited with your current&nbsp;plan. <SubscribeLink /> to&nbsp;unlock access to&nbsp;this feature.</>}
            >
                <div className="column">
                    <BwicRulesStartEndTime
                        daysToSettle={daysToSettle}
                        bidDueUtc={bidsDueDate}
                        goodUntilUtc={goodUntilDate}
                        isSameDayBwic={isSameDayBwic}
                    />
                </div>
                {
                    (!isGeneralSectionVisible || !parsedProcess) &&
                    <div className="column">
                        <BwicRulesSection title="Rules">
                            {
                                !!process.rulesText
                                ? <BwicRulesSectionRow label="Notes">{process.rulesText}</BwicRulesSectionRow>
                                : <p className="rules-section-empty">No rules added.</p>
                            }
                        </BwicRulesSection>
                    </div>
                } {
                    !!parsedProcess &&
                    <div className="column">
                        <BwicRulesSection
                            title="General"
                            visible={isGeneralSectionVisible}
                        >
                            <BwicRulesSectionRow
                                label="Reserve levels apply"
                                visible={parsedProcess.reserveLevelsApply != null}
                            >
                                <span className="text-capitalize">{formatUtils.formatBoolean(parsedProcess.reserveLevelsApply)}</span>
                            </BwicRulesSectionRow>
                            <BwicRulesSectionRow
                                label="Can trade early"
                                visible={parsedProcess.canTradeEarly != null}
                            >
                                <span className="text-capitalize">{formatUtils.formatBoolean(parsedProcess.canTradeEarly)}</span>
                            </BwicRulesSectionRow>
                            <BwicRulesSectionRow
                                label="Can bid early"
                                visible={parsedProcess.canBidEarly != null}
                            >
                                <span className="text-capitalize">{formatUtils.formatBoolean(parsedProcess.canBidEarly)}</span>
                            </BwicRulesSectionRow>
                            <BwicRulesSectionRow
                                label="Partial bids accepted"
                                visible={parsedProcess.partialBidsAccepted != null}
                            >
                                <span className="text-capitalize">{formatUtils.formatBoolean(parsedProcess.partialBidsAccepted)}</span>
                            </BwicRulesSectionRow>
                            <BwicRulesSectionRow
                                label="Bids must be good for"
                                visible={parsedProcess.bidsGoodFor != null}
                            >
                                {!!parsedProcess.bidsGoodFor && <TimeSpan value={parsedProcess.bidsGoodFor} />}
                            </BwicRulesSectionRow>
                            <BwicRulesSectionRow
                                label="Notes"
                                visible={!!process.rulesText}
                            >
                                {process.rulesText}
                            </BwicRulesSectionRow>
                        </BwicRulesSection>
                        {
                            !!processNameFormatted &&
                            <BwicRulesSection
                                title={parsedProcess?.processType === BwicProcessType.Unknown
                                    ? 'Bidding Process' : `Bidding Process: ${processNameFormatted}`}
                            >
                                {
                                    isTwoStage &&
                                    <h5>Stage 1 - Open bidding</h5>
                                }
                                <BwicRulesSectionRow
                                    label="Hard cut off (EST)"
                                    visible={!!settings?.stage1EndDateUtc}
                                >
                                    {moment.utc(settings?.stage1EndDateUtc).format(constants.timeFormat)}
                                </BwicRulesSectionRow>
                                <BwicRulesSectionRow
                                    label="# of stage 2 participants"
                                    visible={!!stage2ImproverCount}
                                >
                                    <div className="flex-row">
                                        Top {stage2ImproverCount} + ties
                                        <InfoTooltip text={`All participants whose bids are at the Top ${stage2ImproverCount} level will go to Stage 2 and will have a chance to improve the bid.`} />
                                    </div>
                                </BwicRulesSectionRow>
                                {
                                    isTwoStage &&
                                    <h5>Stage 2 - {processNameFormatted} bidders have the ability to improve their bids</h5>
                                }
                                <BwicRulesSectionRow
                                    label="Hard cut off (EST)"
                                    visible={!!stage2EndDateUtc}
                                >
                                    {moment.utc(stage2EndDateUtc).format(constants.timeFormat)}
                                </BwicRulesSectionRow>
                            </BwicRulesSection>
                        }
                    </div>
                }
            </RequiredFeature>
        </div>
    );
}
