import * as React from 'react';
import IconSVG from "../../styles/svg-icons";

interface Props {
    onRevert: (e: React.MouseEvent) => void;
}

export function RevertBidButton({ onRevert }: Props) {
    return (
        <button type="button" className="btn-link" onClick={onRevert}>
            <IconSVG name="undo" width={16} height={16} />
        </button>
    );
}
