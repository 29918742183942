import { constants } from '../../../../constants';

interface TotalStatisticsItemProps {
    count?: number;
}

export const TotalStatisticsItem = ({ count }: TotalStatisticsItemProps) => (
    <>
        {count ? <b>{count}</b> : constants.emptyPlaceholder}
    </>
);
