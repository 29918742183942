import { SectionContent } from '../../amrPipeline/common/section/SectionContent';
import { MultiSelectDataItem } from '../../controls';
import MultiSelectField from '../../forms/MultiSelectField';
import { useFormikContext } from 'formik';
import { CloManagerSaveForm } from '../../../types/clo-managers/CloManagerSaveForm';
import { CloSystemInfrastructures, CloSystemInfrastructuresTypes } from '../../../types/clo-managers/CloSystemInfrastructures';
import { useCloSystemInfrastructure } from '../../../effects/data-accessors/useCloSystemInfrastructure';
import { RequestState } from '../../../constants/request-state';
import { Preloader } from '../../common';
import { groupCloSystemInfrastructure } from '../../../utils/clo-managers.utils';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';


export const CloSystemInfrastructure = () => {
    const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext<CloManagerSaveForm>();

    const { requestState, cloSystemInfrastructureItems } = useCloSystemInfrastructure();

    const cloSystemInfrastructuresValues = useMemo(
        () => groupCloSystemInfrastructure(values.cloSystemInfrastructures),
        [values],
    );

    const cloValuationServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.CloValuationServices) || [];
    const cloAnalyticsServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.CloAnalyticsServices) || [];
    const cloPortfolioManagementServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.CloPortfolioManagementServices) || [];
    const offshoreLegalServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.OffshoreLegalServices) || [];
    const offshoreFundAdminServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.OffshoreFundAdministrationServices) || [];
    const cloAccountingServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.CloAccountingServices) || [];
    const secondaryCloPlatform = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.SecondaryCLOPlatforms) || [];
    const cloDocsServices = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.CloDocsServices) || [];
    const usCloLawFirms = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.USCLOLawFirms) || [];
    const euCloLawFirms = cloSystemInfrastructuresValues.get(CloSystemInfrastructuresTypes.EuropeanCLOLawFirms) || [];


    if (requestState === RequestState.request) {
        return <Preloader inProgress />
    }

    if (isEmpty(cloSystemInfrastructureItems)) {
        return null;
    }

    const handleSelect = (
        selectedValues: CloSystemInfrastructures[],
        type: CloSystemInfrastructuresTypes
    ) => {
        return (item: MultiSelectDataItem, selected: boolean) => {
            let updatedData: CloSystemInfrastructures[] = [...selectedValues];
            selected
                ? updatedData.push({ id: Number(item.value), name: item.text, type })
                : (updatedData = updatedData.filter(i => i.id !== item.value));
            setFieldValue('cloSystemInfrastructures', updatedData);
            setFieldTouched('cloSystemInfrastructures', true);
        };
    };

    const resetAll = (type: CloSystemInfrastructuresTypes) => (e: React.MouseEvent) => {
        const filteredData = values.cloSystemInfrastructures.filter(c => c.type !== type);

        setFieldValue('cloSystemInfrastructures', filteredData);
    }

    const convertToDataListItem = (cloSystemInfrastructuresItem: CloSystemInfrastructures[]) =>
        cloSystemInfrastructuresItem
            ? cloSystemInfrastructuresItem?.map(i => ({ ...i, text: i.name, value: i.id }))
            : [];

    const generateSelectedText = (selectedItems: MultiSelectDataItem[]) => {
        return selectedItems.length > 2 ? `${selectedItems.length} Selected` : selectedItems.map(s => s.text).join(', ');
    }

    return (
        <SectionContent title="CLO System Infrastructure">
            <div className="content-row">
                <div className="content-col">
                    <MultiSelectField
                        onSelect={handleSelect(
                            values.cloSystemInfrastructures,
                            CloSystemInfrastructuresTypes.CloValuationServices,
                        )}
                        onResetAll={resetAll(CloSystemInfrastructuresTypes.CloValuationServices)}
                        dataItems={convertToDataListItem(
                            cloSystemInfrastructureItems.get(CloSystemInfrastructuresTypes.CloValuationServices)!,
                        )}
                        selectedValues={cloValuationServices.map(v => v.id)}
                        label="CLO Valuation Services"
                        errorMessage={
                            (touched.cloSystemInfrastructures && errors.cloSystemInfrastructures?.toString()) || ''
                        }
                        name="cloValuation"
                        markRequired={false}
                        placeholder="Select"
                        horizontalOrientation
                        generateSelectedText={generateSelectedText}
                        resetButtonText='Reset to default'
                    />
                    <MultiSelectField
                        onSelect={handleSelect(
                            values.cloSystemInfrastructures,
                            CloSystemInfrastructuresTypes.CloAnalyticsServices,
                        )}
                        onResetAll={resetAll(CloSystemInfrastructuresTypes.CloAnalyticsServices)}
                        dataItems={convertToDataListItem(cloSystemInfrastructureItems.get(CloSystemInfrastructuresTypes.CloAnalyticsServices)!)}
                        selectedValues={cloAnalyticsServices.map(v => v.id)}
                        label="CLO Analytics Services"
                        errorMessage={
                            (touched.cloSystemInfrastructures && errors.cloSystemInfrastructures?.toString()) || ''
                        }
                        name="cloAnalytics"
                        markRequired={false}
                        placeholder="Select"
                        horizontalOrientation
                        generateSelectedText={generateSelectedText}
                        resetButtonText='Reset to default'

                    />
                    <MultiSelectField
                        onSelect={handleSelect(
                            values.cloSystemInfrastructures,
                            CloSystemInfrastructuresTypes.CloPortfolioManagementServices,
                        )}
                        onResetAll={resetAll(CloSystemInfrastructuresTypes.CloPortfolioManagementServices)}
                        dataItems={convertToDataListItem(cloSystemInfrastructureItems.get(CloSystemInfrastructuresTypes.CloPortfolioManagementServices)!)}
                        selectedValues={cloPortfolioManagementServices.map(v => v.id)}
                        label="CLO Portfolio Management Services"
                        errorMessage={
                            (touched.cloSystemInfrastructures && errors.cloSystemInfrastructures?.toString()) || ''
                        }
                        name="cloPortfolio"
                        markRequired={false}
                        placeholder="Select"
                        horizontalOrientation
                        generateSelectedText={generateSelectedText}
                        resetButtonText='Reset to default'
                    />
                    <MultiSelectField
                        onSelect={handleSelect(
                            values.cloSystemInfrastructures,
                            CloSystemInfrastructuresTypes.OffshoreLegalServices,
                        )}
                        onResetAll={resetAll(CloSystemInfrastructuresTypes.OffshoreLegalServices)}
                        dataItems={convertToDataListItem(cloSystemInfrastructureItems.get(CloSystemInfrastructuresTypes.OffshoreLegalServices)!)}
                        selectedValues={offshoreLegalServices.map(v => v.id)}
                        label="Offshore Legal Services"
                        errorMessage={
                            (touched.cloSystemInfrastructures && errors.cloSystemInfrastructures?.toString()) || ''
                        }
                        name="offshoreLegal"
                        markRequired={false}
                        placeholder="Select"
                        horizontalOrientation
                        generateSelectedText={generateSelectedText}
                        resetButtonText='Reset to default'
                    />
                    <MultiSelectField
                        onSelect={handleSelect(
                            values.cloSystemInfrastructures,
                            CloSystemInfrastructuresTypes.OffshoreFundAdministrationServices,
                        )}
                        onResetAll={resetAll(CloSystemInfrastructuresTypes.OffshoreFundAdministrationServices)}
                        dataItems={convertToDataListItem(cloSystemInfrastructureItems.get(CloSystemInfrastructuresTypes.OffshoreFundAdministrationServices)!)}
                        selectedValues={offshoreFundAdminServices.map(v => v.id)}
                        label="Offshore Fund Admin. Services"
                        errorMessage={
                            (touched.cloSystemInfrastructures && errors.cloSystemInfrastructures?.toString()) || ''
                        }
                        name="offshoreFundAdmin"
                        markRequired={false}
                        placeholder="Select"
                        horizontalOrientation
                        generateSelectedText={generateSelectedText}
                        resetButtonText='Reset to default'
                    />
                </div>
                <div className="content-col">
                    <MultiSelectField
                        onSelect={handleSelect(
                            values.cloSystemInfrastructures,
                            CloSystemInfrastructuresTypes.CloAccountingServices,
                        )}
                        onResetAll={resetAll(CloSystemInfrastructuresTypes.CloAccountingServices)}
                        dataItems={convertToDataListItem(cloSystemInfrastructureItems.get(CloSystemInfrastructuresTypes.CloAccountingServices)!)}
                        selectedValues={cloAccountingServices.map(v => v.id)}
                        label="CLO Accounting Services"
                        errorMessage={
                            (touched.cloSystemInfrastructures && errors.cloSystemInfrastructures?.toString()) || ''
                        }
                        name="cloAccounting"
                        markRequired={false}
                        placeholder="Select"
                        horizontalOrientation
                        generateSelectedText={generateSelectedText}
                        resetButtonText='Reset to default'
                    />
                    <MultiSelectField
                        onSelect={handleSelect(
                            values.cloSystemInfrastructures,
                            CloSystemInfrastructuresTypes.SecondaryCLOPlatforms,
                        )}
                        onResetAll={resetAll(CloSystemInfrastructuresTypes.SecondaryCLOPlatforms)}
                        dataItems={convertToDataListItem(cloSystemInfrastructureItems.get(CloSystemInfrastructuresTypes.SecondaryCLOPlatforms)!)}
                        selectedValues={secondaryCloPlatform.map(v => v.id)}
                        label="Secondary CLO Platforms"
                        errorMessage={
                            (touched.cloSystemInfrastructures && errors.cloSystemInfrastructures?.toString()) || ''
                        }
                        name="secondaryClo"
                        markRequired={false}
                        placeholder="Select"
                        horizontalOrientation
                        generateSelectedText={generateSelectedText}
                        resetButtonText='Reset to default'
                    />
                    <MultiSelectField
                        onSelect={handleSelect(
                            values.cloSystemInfrastructures,
                            CloSystemInfrastructuresTypes.CloDocsServices,
                        )}
                        onResetAll={resetAll(CloSystemInfrastructuresTypes.CloDocsServices)}
                        dataItems={convertToDataListItem(cloSystemInfrastructureItems.get(CloSystemInfrastructuresTypes.CloDocsServices)!)}
                        selectedValues={cloDocsServices.map(v => v.id)}
                        label="CLO Docs Services"
                        errorMessage={
                            (touched.cloSystemInfrastructures && errors.cloSystemInfrastructures?.toString()) || ''
                        }
                        name="cloDocs"
                        markRequired={false}
                        placeholder="Select"
                        horizontalOrientation
                        generateSelectedText={generateSelectedText}
                        resetButtonText='Reset to default'
                    />
                    <MultiSelectField
                        onSelect={handleSelect(
                            values.cloSystemInfrastructures,
                            CloSystemInfrastructuresTypes.USCLOLawFirms,
                        )}
                        onResetAll={resetAll(CloSystemInfrastructuresTypes.USCLOLawFirms)}
                        dataItems={convertToDataListItem(cloSystemInfrastructureItems.get(CloSystemInfrastructuresTypes.USCLOLawFirms)!)}
                        selectedValues={usCloLawFirms.map(v => v.id)}
                        label="US CLO Law Firms"
                        errorMessage={
                            (touched.cloSystemInfrastructures && errors.cloSystemInfrastructures?.toString()) || ''
                        }
                        name="usCloLaw"
                        markRequired={false}
                        placeholder="Select"
                        horizontalOrientation
                        generateSelectedText={generateSelectedText}
                        resetButtonText='Reset to default'
                    />
                    <MultiSelectField
                        onSelect={handleSelect(
                            values.cloSystemInfrastructures,
                            CloSystemInfrastructuresTypes.EuropeanCLOLawFirms,
                        )}
                        onResetAll={resetAll(CloSystemInfrastructuresTypes.EuropeanCLOLawFirms)}
                        dataItems={convertToDataListItem(cloSystemInfrastructureItems.get(CloSystemInfrastructuresTypes.EuropeanCLOLawFirms)!)}
                        selectedValues={euCloLawFirms.map(v => v.id)}
                        label="European CLO Law Firms"
                        errorMessage={
                            (touched.cloSystemInfrastructures && errors.cloSystemInfrastructures?.toString()) || ''
                        }
                        name="euCloLaw"
                        markRequired={false}
                        placeholder="Select"
                        horizontalOrientation
                        generateSelectedText={generateSelectedText}
                        resetButtonText='Reset to default'
                    />
                </div>
            </div>
        </SectionContent>
    );
};
