import { useMemo } from 'react';
import { CloManagerTrusteeStatsBarChart } from './CloManagerTrusteeStatsBarChart';
import { DashboardNoSelectedFilters } from '../../../../dashboard/DashboardNoSelectedFilters';
import { CloManagerStats } from '../../../../../types/clo-managers/CloManagerStats';

interface CloManagerTrusteeStatsWidgetProps {
    stats: CloManagerStats;
    onChartClick?: (companyReferenceName: string) => void;
}

export function CloManagerTrusteeStatsWidget({ stats, onChartClick }: CloManagerTrusteeStatsWidgetProps) {
    const chart = useMemo(() => {
        const data = stats.trusteesDeals.sort((a, b) => b.numberOfDeals - a.numberOfDeals);

        return <CloManagerTrusteeStatsBarChart data={data} onClick={onChartClick} />;
    }, [stats, onChartClick]);

    return (
        <div className="analytics dashboard-cnt-box half-size with-border im-chart trustee-chart">
            <div className="heading">
                <h3>
                    Trustees<span>Deal Count</span>
                </h3>
            </div>
            {!!stats.trusteesDeals.length ? chart : <DashboardNoSelectedFilters text="No data available" />}
        </div>
    );
}
