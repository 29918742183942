import { Suspense, lazy, useMemo, useRef } from 'react';
import { user } from '../../user';

const JoditEditorComponent = lazy(() => import('jodit-react'));

interface EditorProps {
    value: string;
    uploadBaseUrl?: string;
    uploadPath: string;
    maxLength?: number;
    onChange: (value: string) => void;
    placeholder?: string;
}

export const JoditEditor = ({ maxLength, value, uploadBaseUrl = `${process.env.REACT_APP_AMR_ENDPOINT}`, uploadPath, onChange, placeholder = "Start writing…" }: EditorProps) => {
    const editor = useRef(null);

    // `as any` is set on purpose, because of broken types.
    // `config` doesn't recognize `limitChars` property
    const config = useMemo(() => ({
        readonly: false,
        limitChars: maxLength,

        // Disable source editor to avoid loading ace.js
        sourceEditor: 'area',

        // Disable beautify HTML to avoid loading beautify-html
        beautifyHTML: false,
        uploader: {
            url: `${uploadBaseUrl}${uploadPath}`,
            withCredentials: true,
            format: 'json',
            headers: {
                authorization: `Bearer ${user.token()}`,
            },
            process: function (response: any) {
                if (!response) {
                    return {
                        files: [],
                        baseurl: uploadBaseUrl,
                        error: true,
                        msg: 'Error uploading image',
                    };
                }

                const [file] = response;

                return {
                    files: [file.url],
                    path: file.url,
                    baseurl: uploadBaseUrl,
                    error: false,
                    msg: '',
                };
            },
            isSuccess: function (response: any) {
                return !!response;
            },
            getMessage: function (response: any) {
                return !!response ? 'Uploaded' : 'Error';
            },
            defaultHandlerSuccess: function (response: any) {
                if (!response?.files?.length) {
                    return;
                }

                // Destroy type of this on purpose
                const that: any = this;

                response.files.forEach((filename: string) => {
                    const element = that.createInside.element('img');
                    const imageUrl = `${response.baseurl}${filename}`;
                    element.setAttribute('src', imageUrl);
                    that.s.insertImage(element as HTMLImageElement, null, that.o.imageDefaultWidth);
                });
            },
            defaultHandlerError: function (response: any) {
                // Destroy type of this on purpose
                const that: any = this;
                that.j.e.fire('errorMessage', 'Error uploading image');
            },
        },
        askBeforePasteHTML: false,
        toolbarAdaptive: false,
        buttons: 'bold,italic,underline,fontsize,brush,|,ul,ol,hr,eraser,|,paragraph,indent,outdent,|,left,center,right,justify,|,image,table,link,video,|,undo,redo,|,preview,fullsize',
        defaultActionOnPaste: 'insert_clear_html',
        placeholder
    } as any), [maxLength, uploadBaseUrl, uploadPath, placeholder]);

    return (
        <Suspense fallback={<span>Loading</span>}>
            <JoditEditorComponent
                ref={editor}
                value={value || ''}
                config={config}
                onBlur={value => onChange(value)}
            />
        </Suspense>
    );
}
