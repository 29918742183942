import * as React from 'react';
import classNames from 'classnames';
import { useSettlementAgentDefaultCommission } from '../../../effects/data-accessors/useSettlementAgentDefaultCommission';
import { user } from '../../../user';
import { isRequesting, isRequestNone } from '../../../utils';
import { ViewSettlementAgentCommissionPopup } from '../../management/companies/clearing-bank/ViewSettlementAgentComissionPopup';
import IconSVG from "../../../styles/svg-icons";

interface Props {
    companyId?: number;
    companyName: string;
    buttonIconVisible?: boolean;
    text?: string;
}

export function ViewSettlementAgentCommission({ companyId, companyName, buttonIconVisible = true, text = "Commission" }: Props) {
    const [isPopupVisible, setPopupVisible] = React.useState(false);
    
    const settlementAgentCommissionState = useSettlementAgentDefaultCommission(companyId ?? user.current()!.companyId, !isPopupVisible);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setPopupVisible(true);
    }

    return (
        <>
            <button
                className={classNames({'btn-link': buttonIconVisible, 'pseudo-link': !buttonIconVisible}, 'show-agent-commission')}
                onClick={handleClick}
            >
                {buttonIconVisible && < IconSVG name="commission" width={16} height={16} />}
                {text}
            </button>
            {
                isPopupVisible &&
                <ViewSettlementAgentCommissionPopup
                    isRequesting={
                        isRequestNone(settlementAgentCommissionState.requestState) ||
                        isRequesting(settlementAgentCommissionState.requestState)
                    }
                    commission={settlementAgentCommissionState.values}
                    companyName={companyName}
                    onClose={() => setPopupVisible(false)}
                />
            }
        </>
    );
}
