export enum InvestmentTeamColumn {
  status = 'status',
  fullName = 'fullName',
  title = 'title',
  email = 'email',
  linkedIn = 'linkedIn',
  phoneNumber = 'phoneNumber',
  location = 'location',
  yrsAtCompany = 'yrsAtCompany',
  yrsExperience = 'yrsExperience',
  meetingContact = 'meetingContact',
}
