import React, { Suspense, lazy, useState } from 'react';
import { Preloader } from './Preloader';

const ReactPlayer = lazy(() => import('react-player'));

interface Props {
    url: string;
    width?: number;
    height?: number
    controls?: boolean
}

export const Player = ({ url, width, height, controls = true }: Props) => {
    const [isReady, setIsReady] = useState(false);
    return (
        <div className="position-relative">
            <Suspense fallback={<Preloader inProgress={true} />}>
                {!isReady && <Preloader inProgress={!isReady} />}
                <ReactPlayer
                    url={url}
                    width={width}
                    height={height}
                    controls={controls}
                    onReady={() => setIsReady(true)}
                />
            </Suspense>
        </div>
    )
}
