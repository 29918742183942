import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { routes } from '../../../constants';
import { Clients } from './Clients';
import { ClientsSettlementAccounts } from './settlement-account/ClientsSettlementAccounts';

import { ClientsFilterOptions } from './clients-filter/ClientsFilterOptions';
import { defaultClientsFilter } from './clients-filter/ClientsFilter';
import { useSettlementAccounts } from '../../../effects/data-accessors/useSettlementAccounts';

export function ClientsView() {
    const [filter, setFilter] = useState<ClientsFilterOptions>(defaultClientsFilter);
    useSettlementAccounts();

    return (
        <Switch>
            <Route
                exact
                path={[routes.clients, routes.clientsCompanies]}
                render={() => (
                    <Clients
                        onFilterChanged={setFilter}
                        filter={filter}
                    />
                )}
            />
            <Route
                exact
                path={[
                    routes.clientsSettlementAccount,
                    routes.createClientsSettlementAccount,
                    routes.editClientsSettlementAccount
                ]}
                component={ClientsSettlementAccounts}
            />
            <Redirect to={routes.notFound} />
        </Switch>
    );
}
