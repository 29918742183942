import { ESGConsiderations } from './sections/ESGConsiderations';
import { ManagerPresentations } from './sections/ManagerPresentations';
import { KeyConsiderations } from './sections/KeyConsiderations';
import { ShowFor } from '../../access';
import { roles } from '../../../constants';
import { CloSystemInfrastructure } from './sections/CloSystemInrastructure';

export const AdditionalInformation = () => {
    return (
        <>
            <KeyConsiderations />
            <CloSystemInfrastructure />
            <ESGConsiderations />
            <ShowFor roles={roles.getAllRolesExclude(roles.Media, roles.Viewer)}>
                <ManagerPresentations />
            </ShowFor>
        </>
    );
};
