import * as React from 'react';

interface Props {
    singular: string;
    plural?: string;
    count: number;
    template?: string;
}

export function Pluralize({ singular, plural, count, template = '{{count}} {{text}}' }: Props) {
    const renderString = (text: string) => template.replace('{{count}}', String(count)).replace('{{text}}', text);

    return <>{count === 1 ? renderString(singular) : renderString(plural ?? singular + 's')}</>;
}
