import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { head, isNil } from "lodash";
import { Preloader } from "../common";
import { routes } from "../../constants";
import { AppState } from "../../types/state/AppState";
import { Redirect, useHistory, useParams } from "react-router";
import { CloManagersTabType } from '../../types/clo-managers/CloManagersTabType';
import { CloManagersSelectionPanel } from './CloManagersSelectionPanel';
import { cloManagersActions } from '../../actions/clo-managers.actions';
import CloManagersContext from './cloManagersContext';
import { canEditCloManager, hasCloManagerAnalyticsAccess, isCollateralManagersOwnCompany } from '../../utils/amr-pipeline.utils';
import { CloManagersDetails } from './CloManagersDetails';
import { user } from '../../user';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { imUserConfigActions } from "../../actions";
import { CloManagerAlertsPopup } from "./CloManagerAlertsPopup";

const EditTab = 'edit';

export interface CLOManagerRouteParams {
    referenceName: string;
    tab?: CloManagersTabType | typeof EditTab;
}

export const CloManagers = () => {
    const { referenceName, tab } = useParams<CLOManagerRouteParams>();
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        isLoading,
        isDetailsLoading,
        data,
        selectedManager,
        managerReferenceNameForAlert
    } = useSelector((state: AppState) => state.cloManagers);
    const userCompany = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);

    const managerForAlerts = managerReferenceNameForAlert ? data?.find(m => m.referenceName === managerReferenceNameForAlert) : null;

    const isUserCompany = selectedManager?.isUserCompany;
    const managersLoaded = !isNil(data);
    const contextValue = {
        canEdit: canEditCloManager(selectedManager, userCompany),
        canNavigateToIM: user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess),
        ownCollateralManagersCompany: isCollateralManagersOwnCompany(selectedManager, userCompany),
        ownCompany: selectedManager?.referenceName === userCompany?.referenceName,
        hasAnalyticsAccess: hasCloManagerAnalyticsAccess(!!isUserCompany),
    };

    const navigateToFirstManager = useCallback((tab: CloManagersTabType) => {
        const firstManager = head(data);

        if (firstManager) {
            history.replace(routes.manageCloManagerUrl(firstManager.referenceName, tab || CloManagersTabType.Overview));
        }
    }, [history, data]);

    useEffect(() => {
        if (data) {
            return;
        }

        dispatch(cloManagersActions.getCloManagers(userCompany?.referenceName));
    }, [dispatch, data, userCompany?.referenceName]);

    useEffect(() => {
        if (!referenceName || !managersLoaded) {
            return;
        }

        dispatch(cloManagersActions.getCloManager(referenceName));
    }, [dispatch, referenceName, managersLoaded]);

    useEffect(() => {
        if (referenceName || selectedManager) {
            return;
        }

        navigateToFirstManager(tab as CloManagersTabType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, referenceName, history, selectedManager]);

    useEffect(() => {
        if (!tab && referenceName) {
            history.replace(routes.manageCloManagerUrl(referenceName, CloManagersTabType.Overview));
        }

        if (!tab && !referenceName && data) {
            navigateToFirstManager(CloManagersTabType.Overview);
        }
    }, [history, tab, referenceName, data, navigateToFirstManager]);

    useEffect(() => {
        dispatch(imUserConfigActions.getUserConfig());

        return () => {
            dispatch(cloManagersActions.reset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (selectedManager?.legalName) {
            document.title = `${selectedManager?.legalName} - KTX ATS Platform`;
        }
    },[selectedManager?.legalName, tab]);

    const handleViewCloManager = (referenceName: string) => {
        history.replace(routes.manageCloManagerUrl(referenceName, CloManagersTabType.Overview));
    };

    const handleFavoriteClick = (referenceName: string, favorite: boolean) => {
        dispatch(cloManagersActions.switchFavoriteRequest(referenceName, favorite));
    };

    if (!data && !isLoading) {
        return <Redirect to={routes.notFound} />;
    }

    return (
        <CloManagersContext.Provider value={contextValue}>
            <div className="container">
                <Preloader inProgress={isLoading}>
                    <div className="container-flex container-sidebar">
                            <CloManagersSelectionPanel
                                data={data || []}
                                selectedReferenceName={referenceName}
                                onChange={handleViewCloManager}
                                onFavoriteClick={handleFavoriteClick}
                            />
                            <div className="container-flex content-part-sidebar container-clo-managers">
                                <Preloader inProgress={isDetailsLoading} fullScreen={false}>
                                    {selectedManager && <CloManagersDetails selectedManager={selectedManager} />}
                                </Preloader>
                            </div>
                    </div>
                </Preloader>
                {managerForAlerts && <CloManagerAlertsPopup selectedManager={managerForAlerts} onClose={() => dispatch(cloManagersActions.setManagerForAlertPopup(undefined))} />}
            </div>
        </CloManagersContext.Provider>
    );
};
