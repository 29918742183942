import React, { useState } from 'react';
import { Popup, PopupBody, PopupFooter } from '../controls';
import { SettlementAgentClientAgreement } from '../../types/bid-as-dealer/SettlementAgentClientAgreement';
import { moneyUtils } from '../../utils/money.utils';
import { constants } from '../../constants/constants';
import { OnHoverTooltip } from "./OnHoverTooltip";
import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { SettlementAgentAgreement } from '../../types/bid-as-dealer/SettlementAgentAgreement';
import { SettlementAgentDailyTraidingLimit } from './settlment-agent-popup/SettlementAgentDailyTraidingLimit';
import { useTotalDailyTradingLimit } from './settlment-agent-popup/useTotalDailyTradingLimit';

interface Props {
    companyName: string;
    valueLabel?: string;
    agreement: SettlementAgentAgreement | SettlementAgentClientAgreement;
    amountToMM?: boolean;
}

export const DailyTradingLimitDialog: React.FC<Props> = ({
        agreement,
        companyName,
        valueLabel,
        amountToMM,
    }) => {
    const dailyTradingLimit = agreement.dailyTradingLimit;
    const agreementStatus = agreement.agreementStatus;

    const [popupVisible, setPopupVisible] = useState(false);

    const total = useTotalDailyTradingLimit(dailyTradingLimit);

    const handleClosePopup = (e: MouseEvent | React.MouseEvent) => {
        e.stopPropagation();
        setPopupVisible(false);
    }

    const renderTitle = () => (
        <>
            <span className="title">Daily Trading Limit</span>
            <OnHoverTooltip overlay={companyName}>
                <span className="name-tag text-ellipsis">{companyName}</span>
            </OnHoverTooltip>
        </>
    )

    const renderPopup = () => popupVisible && (
        <Popup
            renderInBody={true}
            modalClass="modal-limits"
            title={renderTitle()}
            onClose={handleClosePopup}
        >
            <PopupBody>
                <SettlementAgentDailyTraidingLimit dailyTradingLimit={dailyTradingLimit} />
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={handleClosePopup}>Close</button>
            </PopupFooter>
        </Popup>
    )

    const handleOnTotalClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setPopupVisible(true);
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            {
                agreementStatus === SettlementAgreementStatus.confirmed && Boolean(total) ? (
                    <button className="pseudo-link show-limits" onClick={handleOnTotalClick}>
                        {Boolean(valueLabel) && valueLabel}
                        {amountToMM ? moneyUtils.amountToMM(total) : moneyUtils.money(total)}
                    </button>
                ) : constants.emptyPlaceholder
            }
            {renderPopup()}
        </div>
    )
}

DailyTradingLimitDialog.defaultProps = {
    amountToMM: false,
    valueLabel: '',
}
