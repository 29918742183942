import { Link } from 'react-router-dom';
import { Table } from '../../bidding/common/table';
import { user } from '../../../user';
import { scheduleMeetingMailPreviewColumns } from './columns';
import { routes } from '../../../constants';


interface Props {
    userCompanyName: string
    text: string;
}

export function ScheduleMeetingMailPreview({ userCompanyName, text }: Props) {
    return (
        <>
            <div className="mail-preview">
                <div className="mail-preview-head">
                    <h2>Hello,</h2>
                    <p>{userCompanyName} has requested a meeting. Please see the contact information below:</p>
                </div>
                    <Table
                        className="data-list-striped data-list-share-transaction"
                        dataItems={[user.current()]}
                        columns={scheduleMeetingMailPreviewColumns}
                    />
                {text && (
                    <div className="mail-preview-optional-text">
                        {text}
                    </div>
                )}
                <div className="mail-preview-info-text">
                    <h3>Regards,</h3>
                    <p>Team KopenTech</p>
                </div>
            </div>
            <div className="mail-preview-bottom">
                This email (including any attachments) is confidential and, by accepting this email, you agree that you will
                treat it as such and act or refrain from acting in accordance with applicable law. It does not constitute investment
                advice, is only for the use of the intended recipient and copying, redistributing or acting upon such email by
                unintended recipients is unauthorized and may be unlawful. Receipt by anyone other than the intended recipient is not
                a waiver of any protections or work-product or attorney-client privilege. If you received this email in error, please
                notify KopenTech, LLC ("KopenTech") immediately. KopenTech makes no representation or warranty as to the accuracy or
                completeness of the email and its contents and is not obligated to update or revise any information. This email is not
                an official confirmation of any transaction and is not offer to sell or the solicitation for the purchase of any security,
                financial instrument or interest in an investment vehicle. Such offering shall be made only by a final confidential offering
                circular to eligible investors. Any comments or opinions expressed in this communication do not necessarily reflect those
                of KopenTech or its affiliates. Emails sent to and from this address may be monitored and stored in accordance with internal
                policy. This email and any attachments are confidential and may be privileged or otherwise protected from disclosure. If you
                are not the intended recipient, please telephone or email the sender and delete this message and any attachment from your
                system immediately. If you are not the intended recipient you must not copy this message or attachment or disclose the contents
                to any other person. For more details, see the&nbsp;
                <Link to={routes.termsAndConditions}>Terms and Conditions</Link> and{' '}
                <Link to={routes.privacyPolicy}>Privacy Policy</Link>
            </div>
        </>
    );
}
