import React from 'react';
import { OnHoverTooltip } from './OnHoverTooltip';
import { Pluralize } from './Pluralize';

interface Props {
    tradedPositionsCount: number;
    hideCount?: boolean;
}

export const TradedPositionsCountBadge: React.FC<Props> = ({ tradedPositionsCount, hideCount }) => tradedPositionsCount
    ? (
        <OnHoverTooltip overlay={<Pluralize count={tradedPositionsCount} singular="pending trade"/>}>
            <span className="badge">{!hideCount && tradedPositionsCount}</span>
        </OnHoverTooltip>
    )
    : null

TradedPositionsCountBadge.defaultProps = {
    hideCount: false
}
