import { useSelector } from "react-redux";
import { AppState } from "../../../../types/state/AppState";
import { CloManagersState } from "../../../../types/state/CloManagersState";
import { SectionContent } from "../../../amrPipeline/common/section/SectionContent";
import { FormField } from "../../../amrPipeline/common/FormField";
import { constants } from "../../../../constants";
import { capitalize, isNil } from "lodash";
import { MarkdownViewer } from "../../../common/markdown-editor";
import { formatUtils } from "../../../../utils";
import { OnHoverTooltip } from "../../../common";
import { InstitutionTypeTitles } from '../../../../types/clo-managers/InstitutionType';

export const KeyConsiderations = () => {
    const { selectedManager } = useSelector<AppState, CloManagersState>(
        (state: AppState) => state.cloManagers
    );

    if (!selectedManager?.details) {
        return null;
    }

    const { details } = selectedManager;

    const getOperationsRegions = () => {
        const { usdDeals, eurDeals } = details?.stats;

        if (usdDeals && eurDeals) {
            return 'USA / Europe';
        }

        if (usdDeals) {
            return 'USA';
        }

        if (eurDeals) {
            return 'Europe';
        }

        return constants.emptyPlaceholder;
    };

    return (
        <>
        <SectionContent
            title="Key Considerations"
            className="data-item-general-information"
        >
            <div className="content-row">
                <div className="content-col">
                    <FormField
                        title="Region(s) of CLO Operations"
                        capitalize={false}
                        className="data-item-row-full-height"
                    >
                        {getOperationsRegions()}
                    </FormField>
                    <FormField title="Location" className="data-item-row-full-height">
                        {details?.location || constants.emptyPlaceholder}
                    </FormField>
                    <FormField title="CLO Issuance Since" className="data-item-row-full-height">
                        {isNil(details?.cloIssuanceSince)
                            ? constants.emptyPlaceholder
                            : details?.cloIssuanceSince}
                    </FormField>
                    <FormField title="Founded in" className="data-item-row-full-height">
                        {isNil(details?.foundedIn)
                            ? constants.emptyPlaceholder
                            : details.foundedIn}
                    </FormField>
                    <FormField title="Founder" className="data-item-row-full-height">
                        {details.founder ? (
                            <OnHoverTooltip overlay={details.founder}>
                                <div className="text-ellipsis">{details.founder}</div>
                            </OnHoverTooltip>
                        ) : (
                            constants.emptyPlaceholder
                        )}
                    </FormField>
                    <FormField title="Parent" className="data-item-row-full-height">
                        {details.parent ? (
                            <OnHoverTooltip overlay={details.parent}>
                                <div className="text-ellipsis">{details.parent}</div>
                            </OnHoverTooltip>
                        ) : (
                            constants.emptyPlaceholder
                        )}
                    </FormField>
                    <FormField title="Institution Type" className="data-item-row-full-height">
                        {details.institutionType ? (
                            InstitutionTypeTitles[details.institutionType]
                        ) : (
                            constants.emptyPlaceholder
                        )}
                    </FormField>
                </div>
                <div className="content-col">
                    <FormField title="Total AUM (Parent), USD" className="data-item-row-full-height">
                        {isNil(details?.totalParentAumUsd)
                            ? constants.emptyPlaceholder
                            : formatUtils.formatToBillions(details.totalParentAumUsd, 2, true)}
                    </FormField>
                    <FormField title="Total AUM (Parent), EUR" className="data-item-row-full-height">
                        {isNil(details?.totalParentAumEur)
                            ? constants.emptyPlaceholder
                            : formatUtils.formatToBillions(details.totalParentAumEur, 2, true)}
                    </FormField>
                    <FormField title="Leveraged Loan AUM, USD" className="data-item-row-full-height">
                        {isNil(details?.leveragedLoanAumUsd)
                            ? constants.emptyPlaceholder
                            : formatUtils.formatToBillions(details.leveragedLoanAumUsd, 2, true)}
                    </FormField>
                    <FormField title="Leveraged Loan AUM, EUR" className="data-item-row-full-height">
                        {isNil(details?.leveragedLoanAumEur)
                            ? constants.emptyPlaceholder
                            : formatUtils.formatToBillions(details.leveragedLoanAumEur, 2, true)}
                    </FormField>
                    <FormField title="Own Equity" className="data-item-row-full-height">
                        {capitalize(formatUtils.formatBoolean(details?.ownEquity))}
                    </FormField>
                    <FormField title="Proprietary Analytical Tools" className="data-item-row-full-height">
                        {capitalize(formatUtils.formatBoolean(details?.proprietaryAnalyticalTools))}
                    </FormField>
                </div>
            </div>
            <div className="data-item-row-col-full">
                <FormField
                    title="Investment Philosophy"
                    className="data-item-row-full-height"
                >
                    {details?.investmentPhilosophy ? (
                        <MarkdownViewer value={details.investmentPhilosophy} />
                    ) : (
                        constants.emptyPlaceholder
                    )}
                </FormField>
                <FormField
                    title="Investment Model"
                    className="data-item-row-full-height"
                >
                    {details?.investmentModel ? (
                        <MarkdownViewer value={details.investmentModel} />
                    ) : (
                        constants.emptyPlaceholder
                    )}
                </FormField>
            </div>
        </SectionContent>
        </>
    );
};
