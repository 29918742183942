import { constants } from "../../../../../constants";
import { CloManagerProfileView } from '../../../../../types/amr-pipeline/models/CloManagerSession';
import { FormField } from "../../../../amrPipeline/common/FormField";
import { OnHoverTooltip } from "../../../../common";

interface Props {
    userSession: CloManagerProfileView;
}

export function ContactsTab({ userSession }: Props) {
    return (
        <>
            <FormField
                title="Full Name"
                value={userSession.userFullName}
                className="data-item-row-full-height data-item-row-title-md"
            />
            <FormField
                title="Company Name"
                value={userSession.companyLegalName}
                className="data-item-row-full-height data-item-row-title-md"
            />
            <FormField
                title="Email"
                value={
                    <OnHoverTooltip
                        overlay={userSession.userEmail}
                        overlayClassName="word-break"
                    >
                        <a href={`mailto:${userSession.userEmail}`}>
                            {userSession.userEmail}
                        </a>
                    </OnHoverTooltip>
                }
                className="data-item-row-full-height data-item-row-title-md data-item-row-email"
            />
            <FormField
                title="Phone"
                value={
                    userSession.userPhoneNumber || constants.emptyPlaceholder
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
        </>
    );
}
