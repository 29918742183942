import * as React from 'react';
import { useBrokerDealers } from '../../effects/useBrokerDealers';
import { AutoCompleteInput, FormError, Popup, PopupBody, PopupFooter } from '../controls';
import { Preloader } from '../common/Preloader';
import { FormFieldLabel } from '../forms/FormFieldLabel';
import { CompanySlim } from '../../types/company/CompanySlim';
import { isRequesting, isRequestNone } from '../../utils';
import { errorMessages } from '../../constants';

interface Props {
    onSelected: (company: CompanySlim) => void;
    onCancel: () => void;
}

export function SelectBrokerDealerPopup({ onSelected, onCancel }: Props) {
    const { items, requestState } = useBrokerDealers();

    const [companyName, setCompanyName] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");

    const sourceItems = React.useMemo(() => items.map(i => i.name), [items]);

    const validate = (companyName: string) => {
        if (!companyName) return { errorMessage: errorMessages.empty };

        const company = items.find(c => c.name.localeCompare(companyName, undefined, { sensitivity: 'accent' }) === 0);
        if (!company) return { errorMessage: errorMessages.invalidValue };

        return { errorMessage: "", company };
    }

    const handleClick = () => {
        const { errorMessage, company } = validate(companyName);

        setErrorMessage(errorMessage);

        if (company) {
            onSelected(company);
        }
    }

    const handleCompanyChange = (name: string) => {
        setCompanyName(name);

        if (errorMessage) {
            const validationResult = validate(name);
            setErrorMessage(validationResult.errorMessage);
        }
    }

    return (
        <Popup
            renderInBody
            modalClass="modal-select-dealer"
            title="Select Broker-Dealer"
            onClose={onCancel}
        >
            <PopupBody>
                <Preloader inProgress={isRequesting(requestState) || isRequestNone(requestState)}>
                    <FormFieldLabel required={true} text="Broker-dealer" />
                    <AutoCompleteInput
                        className="form-control form-control-search"
                        maxLength={256}
                        sourceItems={sourceItems}
                        value={companyName}
                        onChange={handleCompanyChange}
                        placeholder="Choose Company"
                    />
                    <FormError message={errorMessage} />
                </Preloader>
            </PopupBody>
            <PopupFooter>
                <button type="button" className="btn btn-ghost" onClick={onCancel}>Cancel</button>
                <button
                    className="btn btn-main"
                    disabled={!!errorMessage || !companyName || isRequesting(requestState) || isRequestNone(requestState)}
                    onClick={handleClick}
                >
                    Apply
                </button>
            </PopupFooter>
        </Popup>
    );
}
