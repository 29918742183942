import { useFormikContext } from 'formik';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { routes } from '../../constants';
import { AppState } from '../../types/state/AppState';
import CloManagersContext from './cloManagersContext';
import { AdditionalInformation } from './form/AdditionalInformation';
import { ESGConsiderations } from './form/ESGConsiderations';
import { ManagerPresentations } from './form/ManagerPresentations';
import { ContactInformation } from './form/ContactInformation';
import { Contacts } from './form/Contacts';
import { CompanyProfile } from './form/CompanyProfile';
import { CloManagerSave } from '../../types/clo-managers/CloManagerSave';
import { RouteLeavingGuard } from '../routing';
import { isEqual } from 'lodash';
import { CloManagerSaveForm } from '../../types/clo-managers/CloManagerSaveForm';
import { CloSystemInfrastructure } from './form/CloSystemInfrastructure';

interface CloManagersEditProps {
    setSubmitHandler: (fn: () => Promise<void>) => void;
}

export const CloManagersEdit = ({ setSubmitHandler }: CloManagersEditProps) => {
    const history = useHistory();
    const { submitForm, initialValues, values } = useFormikContext();
    const { canEdit } = useContext(CloManagersContext);
    const { editManager } = useSelector((state: AppState) => state.cloManagers);

    useEffect(() => {
        setSubmitHandler(submitForm);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!canEdit || !editManager) {
        return <Redirect to={routes.notFound} />;
    }

    const hasChanges = () => {
        return !isEqual({...initialValues as CloManagerSave, isUSASelected: (values as CloManagerSaveForm).isUSASelected}, values);
    }

    return (
        <div className="tabs-data tabs-data-padding component-tabs-clo-edit">
            <RouteLeavingGuard
                navigate={(pathname: string) => history.push(pathname)}
                shouldBlockNavigation={() => hasChanges()}
            />
            <CompanyProfile />
            <ContactInformation />
            <Contacts />
            <AdditionalInformation />
            <CloSystemInfrastructure />
            <ESGConsiderations />
            <ManagerPresentations />
        </div>
    );
}
