import { constants } from '../../../../constants'
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { CloManagerAnalytics } from '../../../../types/clo-managers/CloManagerAnalytics'
import { FormField } from '../../../amrPipeline/common/FormField'
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent'
import { BlockedFeatureContent, SubscribeLink } from '../../../access/BlockedFeatureText';
import { RequiredFeature } from '../../../access/RequiredFeature';

interface GeneralProps {
    data: CloManagerAnalytics;
}

export const General = ({ data }: GeneralProps) => {
    return (
        <SectionContent
            title="General"
            className=""
        >
            <div className="data-item-row-col">
                <FormField
                    infoTooltip="Past 24 hours"
                    title="Recent Profile Views"
                    className="data-item-row-full-height"
                >
                    {data.recentProfileViews || constants.emptyPlaceholder}
                </FormField>
                <RequiredFeature feature={SubscriptionFeature.ManagerProfileAnalytics} renderBlockedContent={() =>
                    <BlockedFeatureContent
                        inline
                        className="empty-placeholder analytics-legend"
                        text={
                            <>
                                <h3>See total profile views, deal views, presentation downloads</h3>
                                <SubscribeLink />
                            </>
                        }
                    />
                }>
                    <FormField
                        title="Total Profile Views"
                        className="data-item-row-full-height"
                    >
                        {data.totalProfileViews || constants.emptyPlaceholder}
                    </FormField>
                    <FormField
                        title="Deal Views"
                        infoTooltip="Count of views from transaction marketing start day to pricing day"
                        className="data-item-row-full-height"
                    >
                        {data.dealViews || constants.emptyPlaceholder}
                    </FormField>
                    <FormField
                        infoTooltip="Downloaded from: Issuance Monitor, Managers Profile, Arranger Pipeline, Banks Profile"
                        title="Presentation Downloads"
                        className="data-item-row-full-height"
                    >
                        {data.presentationDownloads || constants.emptyPlaceholder}
                    </FormField>
                </RequiredFeature>
            </div>
        </SectionContent>
    );
}
