import React, { ChangeEvent, useRef } from 'react';
import classNames from 'classnames';

interface UploadFileButtonProps {
    onFile: (file: Blob) => void;
    buttonText: string;
    acceptedFormats: string[];
    className?: string,
    disabled?: boolean;
}

export function UploadFileButton({ onFile, buttonText, acceptedFormats, className = '', disabled = false }: UploadFileButtonProps) {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>{
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            onFile(file)
        }
    };

    return (
        <>
            <input ref={fileInputRef} type='file' accept={acceptedFormats.join(',')} hidden={true} onChange={handleChange}/>
            <button className={classNames("btn", className)} disabled={disabled} onClick={handleClick}>{buttonText}</button>
        </>
    )
}
