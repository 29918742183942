import { useMemo } from 'react';
import { constants } from '../../../../../constants';
import { ChartGrouping, ChartTypes } from '../../../../../types/dashboard/AmrChart';
import { Currency } from '../../../../../types/enums/Currency';
import { DropDownList, DropDownListItem } from '../../../../controls/DropDownList';
import { FilterPanel, FilterSection } from '../../../../filters';
import { ShortLongDatedDealsChartFilter, ShortLongDatedDealsChartFilterProps } from '../../../../dashboard/shortLongDatedDeals/ShortLongDatedDealsFilter';

export function ShortLongDatedDealsFilter({
    onChange,
    ...values
}: ShortLongDatedDealsChartFilterProps) {
    const handleValueChange = (prop: keyof ShortLongDatedDealsChartFilter) => (value: any) => {
        onChange({
            ...values,
            [prop]: value,
        });
    };

    const handleCurrencyChange = (value: any) => {
        onChange({
            ...values,
            currencyCode: value === constants.allListItem ? undefined : value as Currency,
        });
    };

    const chartCurrencies = useMemo(() => {
        const all = {
            value: constants.allListItem,
            text: 'All',
            selected: values.currencyCode === undefined,
        };

        const options = [Currency.USD, Currency.EUR].map(value => ({
            value,
            text: value,
            selected: values.currencyCode === value,
        }));

        return [
            all,
            ...options,
        ];
    }, [values.currencyCode]);

    const chartGroupings = [
        {
            text: ChartGrouping.M,
            value: ChartGrouping.M,
            payload: 'Month',
        },
        {
            text: ChartGrouping.Q,
            value: ChartGrouping.Q,
            payload: 'Quarter',
        },
        {
            text: ChartGrouping.Y,
            value: ChartGrouping.Y,
            payload: 'Year',
        }
    ];

    const chartTypes = useMemo(() => {
        const all = {
            value: constants.allListItem,
            text: 'All',
            selected: values.transactionType === constants.allListItem,
        };

        return [
            all,
            ...ChartTypes.map(({ value, text }) => ({
                value,
                text,
                selected: values.transactionType === value,
            })),
        ];
    }, [values.transactionType]);

    const renderListItemWithDescription = (item: DropDownListItem) => (
        <div className="">
            <span className="">{item.text}</span>
            <span className="">{item.payload}</span>
        </div>
    );

    return (
        <FilterPanel>
            <FilterSection>
                <DropDownList
                    items={chartTypes}
                    onChange={({ value }) => handleValueChange('transactionType')(value)}
                    className="custom-drop-down-ghost"
                    defaultSortingEnabled={false}
                    prefix="Type: "
                />
            </FilterSection>
            <FilterSection>
                <DropDownList
                    items={chartGroupings.map(x => ({ ...x, selected: values.grouping === x.value }))}
                    onChange={({ value }) => handleValueChange('grouping')(value)}
                    className="custom-drop-down-ghost custom-drop-down-period"
                    defaultSortingEnabled={false}
                    prefix="Period: "
                    renderItemContentCallback={renderListItemWithDescription}
                />
            </FilterSection>
            <FilterSection>
                <DropDownList
                    items={chartCurrencies}
                    onChange={({ value }) => handleCurrencyChange(value)}
                    className="custom-drop-down-ghost"
                    defaultSortingEnabled={false}
                    prefix="Ccy: "
                />
            </FilterSection>
        </FilterPanel>
    );
}
