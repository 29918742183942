import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import IconSVG from "../../styles/svg-icons";
interface HorizontalScrollProps {
    children: React.ReactElement[];
    activeItemIndex: number;
}

export const AdaptiveHorizontalSlider = ({
    children,
    activeItemIndex,
}: HorizontalScrollProps) => {
    const [disabledSlider, setDisabledSlider] = useState(false);
    const [scrollTo, setScrollTo] = useState(activeItemIndex);

    const scrollContainer = useRef<HTMLDivElement>(null);
    const backButtonRef = useRef<HTMLButtonElement>(null);
    const scrollData = useRef({
        left: 0,
        right: 0,
    });

    useEffect(() => {
        onResize();

        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setBackButtonDisabling = () => {
        if (scrollContainer.current && backButtonRef.current) {
            const { width: containerWidth, right: containerRight } =
                scrollContainer.current.getBoundingClientRect();
            const firstChildLeft =
                scrollContainer.current.children[0].getBoundingClientRect()
                    .left;
            const selectedChildRight =
                scrollContainer.current.children[
                    scrollTo
                ].getBoundingClientRect().right;

            if (
                containerRight - firstChildLeft > containerWidth ||
                selectedChildRight - firstChildLeft > containerWidth
            ) {
                backButtonRef.current.classList.remove("disabled");
            } else {
                backButtonRef.current.classList.add("disabled");
            }
        }
    };

    const onResize = () => {
        if (scrollContainer.current) {
            const childrenList = Array.from(scrollContainer.current.children);
            const parentRight =
                scrollContainer.current.getBoundingClientRect().right;
            const lastChildRight =
                childrenList[childrenList.length - 1].getBoundingClientRect()
                    .right;
            setDisabledSlider(
                Math.trunc(parentRight) >= Math.trunc(lastChildRight)
            );
            scrollData.current.left = 0;
            scrollData.current.right = 0;

            handleSlide(activeItemIndex);
            setBackButtonDisabling();
        }
    };

    const handleSlide = (indexToScroll: number) => {
        if (scrollContainer.current?.children) {
            const items = Array.from(scrollContainer.current.children);
            items[indexToScroll].scrollIntoView({ behavior: "smooth" });
            setScrollTo(indexToScroll);
        }
    };

    const getLeftEdge = () => {
        if (!scrollContainer.current) {
            return 0;
        }

        const childrenList = Array.from(
            scrollContainer.current.children
        ).reverse();
        const { left: parentLeft } =
            scrollContainer.current.getBoundingClientRect();

        const index = childrenList.findIndex(
            (c) => c.getBoundingClientRect().left < parentLeft
        );

        return childrenList.length - 1 - index;
    };

    const getRightEdge = () => {
        if (!scrollContainer.current) {
            return 0;
        }
        const childrenList = Array.from(scrollContainer.current.children);
        const { right: parentRight } =
            scrollContainer.current.getBoundingClientRect();

        return childrenList.findIndex(
            (c) =>
                Math.trunc(c.getBoundingClientRect().right) >
                Math.trunc(parentRight)
        );
    };

    const handleScrollBack = () => {
        scrollData.current.right = 0;
        scrollData.current.left = scrollData.current.left
            ? scrollData.current.left - 1
            : getLeftEdge();

        handleSlide(scrollData.current.left);
    };

    const handleScrollForward = () => {
        backButtonRef.current?.classList.remove("disabled");
        scrollData.current.left = 0;
        scrollData.current.right = scrollData.current.right
            ? scrollData.current.right + 1
            : getRightEdge();

        handleSlide(scrollData.current.right);
    };

    const leftArrowClassNames = cn(
        "btn-link horizontal-slider-button horizontal-slider-button-prev",
        { disabled: !scrollTo }
    );

    const rightArrowClassNames = cn(
        "btn-link horizontal-slider-button horizontal-slider-button-next",
        { disabled: scrollTo === children.length - 1 }
    );

    return (
        <div
            className={cn("horizontal-slider flex-row", {
                slider: !disabledSlider,
            })}
        >
            <button
                className={leftArrowClassNames}
                onClick={handleScrollBack}
                ref={backButtonRef}
            >
                <IconSVG name="icon-expand" width={16} height={16} />
            </button>

            <div
                ref={scrollContainer}
                className="horizontal-slider-content flex-row"
            >
                {children}
            </div>

            <button
                className={rightArrowClassNames}
                onClick={handleScrollForward}
            >
                <IconSVG name="icon-expand" width={16} height={16} />
            </button>
        </div>
    );
};
