import { StatusMessageSectionType } from "../../types/state/NotificationState";
import { Popup, PopupBody, PopupFooter } from "../controls";
import { StatusMessageSection } from "../status-message/StatusMessageSection";
import IconSVG from '../../styles/svg-icons';
import { useSelector } from "react-redux";
import { AppState } from "../../types/state/AppState";

interface SecuritiesGridFlaggedPopupProps {
    onClose: () => void;
}

export const SecuritiesGridFlaggedPopup = ({ onClose }: SecuritiesGridFlaggedPopupProps) => {
    const dataItems = useSelector((s: AppState) => s.grid.dataItems);
    const flaggedItemsLines = dataItems
        .map(({ ticker, isFlagged }, index) => isFlagged ? <li key={index}>{ticker} (line {index + 1})</li> : null)
        .filter(el => el)

    return (
        <Popup
            renderInBody={true}
            modalClass="modal-flagged"
            title="Flagged Securities"
            onClose={onClose}
        >
            <PopupBody>
                <StatusMessageSection type={StatusMessageSectionType.Alert}>
                    There are securities that were in the grid before the
                    update, but do not exist in the uploaded file.<br />
                    These securties are <b>marked with</b> <IconSVG name="flag" width={16} height={16} />
                    <ul className="list-bullet">
                        {flaggedItemsLines}
                    </ul>
                </StatusMessageSection>
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={onClose}>
                    Close
                </button>
            </PopupFooter>
        </Popup>
    )
}
