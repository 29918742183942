import { memo, useEffect, useRef, useState } from 'react';
import saveAs from 'file-saver';
import cn from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { DragFileZone } from '../../common/DragFileZone';
import { Table } from '../../bidding/common/table';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { SORT, SORTING_TYPE } from '../../../constants';
import { ProgressCircle } from '../../controls';
import FileIcon from '../../controls/FileIcon';
import { isEqual } from 'lodash';
import { SelectFile } from '../../grid/SelectFile';
import IconSVG from '../../../styles/svg-icons';
import { EmptyPlaceholder, OnHoverTooltip } from '../../common';
import { errorActions } from '../../../actions';
import { constants, errorMessages } from '../../../constants';
import { AmrFile } from '../../../types/amr-pipeline/models/AmrFile';
import { amrPipelineService } from '../../../services/amr-pipeline.service';
import { cloManagersService } from '../../../services/clo-managers.service';
import { useParams } from 'react-router';
import { CLOManagerRouteParams } from '../CloManagers';

interface Props {
    documents: AmrFile[];
    acceptedExtensions?: string[];
    label?: string;
    onChange: (documents: AmrFile[]) => void;
    onUploading: (uploading: boolean) => void;
}

function DocumentListComponent({ documents, onChange, onUploading, acceptedExtensions, label }: Props) {
    const dispatch = useDispatch();
    const { referenceName: companyReferenceName } = useParams<CLOManagerRouteParams>();
    const uploadedDocument = useRef<AmrFile | undefined>();
    const [dragEnter, setDragEnter] = useState(false);
    const [uploadingFileName, setUploadingFileName] = useState<string | undefined>();
    const [progress, setProgress] = useState<{[fileName: string]: number}>({});

    useEffect(() => {
        onUploading(!!uploadingFileName);
    }, [onUploading, uploadingFileName]);

    useEffect(() => {
        if (
            uploadedDocument.current
            && !uploadingFileName
            && !documents.some(({referenceName}) => referenceName === uploadedDocument.current?.referenceName)
        ) {
            onChange([
                {
                    name: uploadedDocument.current.name,
                    referenceName: uploadedDocument.current.referenceName,
                    remove: false,
                } as AmrFile,
                ...documents,
            ]);
        }
        // eslint-disable-next-line
    }, [uploadedDocument, uploadingFileName])

    const downloadDocument = async (documentToDownload: AmrFile) => {
        try {
            const { referenceName, name } = documentToDownload;
            const document = await cloManagersService.downloadManagerPresentationFile(companyReferenceName, referenceName);

            saveAs(document.blob, name);
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        }
    }

    const onUploadDocument = async (file: File) => {
        try {
            const response = await amrPipelineService.uploadDocument(
                file,
                (progressEvent: ProgressEvent) => setProgress((progress) => ({
                    ...progress,
                    [file.name]: Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                })),
            );

            return response[0];
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        }
    }

    const uploadFile = async (file: File) => {
        setUploadingFileName(file.name);
        const newDocument = await onUploadDocument(file);

        if (newDocument) {
            uploadedDocument.current = newDocument;
        }

        setUploadingFileName(undefined);
    }

    const handleDrop = (files: FileList) => {
        if (!files) {
            return;
        }

        [...files].forEach((file) => {
            const splitFile = file.name?.split('.');
            const fileExtension = splitFile[splitFile.length - 1];
            if (file.size > 1024 * 1024 * constants.documentMaxFilesizeInMb) {
                dispatch(errorActions.error(
                    '',
                    errorMessages.documentMaxFileSizeMessageText(constants.documentMaxFilesizeInMb),
                    errorMessages.documentMaxFileSizeMessageTitle)
                );
                return;
            }
            if (acceptedExtensions && !acceptedExtensions.some(ext => ext.toLowerCase() === fileExtension.toLowerCase())) {
                dispatch(errorActions.error(
                    '',
                    errorMessages.documentInvalidFileTypeText(fileExtension, acceptedExtensions.map(ext => `.${ext}`).join(',')),
                    errorMessages.documentInvalidFileTypeTitle)
                );
                return;
            }
            uploadFile(file);
        })
    }

    const handleDeleteFile = (documentReferenceName: string) => {
        let documentList = [];

        if(uploadedDocument.current?.referenceName === documentReferenceName) {
            documentList = documents.filter(d => d.referenceName !== documentReferenceName);
            uploadedDocument.current = undefined;
        } else {
            documentList = documents.map((file) =>
                file.referenceName === documentReferenceName
                    ? {...file, remove: true}
                    : file
            );
        }

        onChange(documentList);
    }

    const getTableColumns = () => {
        const columns: IColumnDefinition<AmrFile>[] = [{
            columnKey: 'name',
            renderColumnContent: document => (
                <OnHoverTooltip overlay={document.name}>
                    <div className="btn-link text-ellipsis btn-download-file" onClick={() => downloadDocument(document)}>
                        <FileIcon filename={document.name} />
                        {document.name}
                    </div>
                </OnHoverTooltip>
            ),
            renderColumnHeaderContent: () => 'Name',
            headerClassName: 'data-list-cell-xl-flexible',
            bodyClassName: 'data-list-cell-xl-flexible',
        }, {
            columnKey: 'date',
            renderColumnContent: document => {
                if (document.referenceName) {
                    return moment(document.uploadTime).format(constants.dateFormatDoubleDay)
                }
                return <ProgressCircle progress={progress[document.name]} />;
            },
            renderColumnHeaderContent: () => 'Date',
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-sm',
            sortingType: SORTING_TYPE.date,
        }, {
            columnKey: 'download-action',
            renderColumnContent: document => (
                <button className="btn-link" onClick={() => downloadDocument(document)}>
                    <IconSVG name="downloadTemplate" width={16} height={16} />
                </button>
            ),
            renderColumnHeaderContent: () => '',
            headerClassName: 'data-list-cell-xxs',
            bodyClassName: 'data-list-cell-xxs',
        }, {
            columnKey: 'delete-action',
            renderColumnContent: document => (
                <button className="btn-link btn-danger" onClick={() => handleDeleteFile(document.referenceName)}>
                    <IconSVG name="basket" width={16} height={16} />
                </button>
            ),
            renderColumnHeaderContent: () => '',
            headerClassName: 'data-list-cell-xxs',
            bodyClassName: 'data-list-cell-xxs',
        }]

        return columns.map(c => new ColumnBuilder(c));
    }

    const getTableData = (): AmrFile[] => {
        let documentsToDisplay;
        if (!uploadingFileName) {
            documentsToDisplay = documents;
        } else {
            documentsToDisplay = [
                {
                    name: uploadingFileName,
                    referenceName: '',
                    uploadTime: new Date(),
                },
                ...documents,
            ] as AmrFile[];
        }

        return documentsToDisplay.filter(({ remove }) => !remove);
    }

    return (
        <div className="data-item-row data-item-row-full-height">
            <div className="data-item-col data-item-col-title data-item-capitalize">
                {label}
            </div>
            <div className="data-item-col">
                <div className={cn('documents-section', { 'drag-enter-content': dragEnter })}>
                    {(uploadedDocument.current || uploadingFileName) ? (
                        <EmptyPlaceholder
                            text="Only one file can be uploaded per transaction update. Please save updates to upload another file."
                        />
                    ) : (
                        <DragFileZone
                            onFiles={handleDrop}
                            onDragEnter={() => setDragEnter(true)}
                            onDragLeave={() => setDragEnter(false)}
                            className="component-file-upload"
                        >
                            <div className="component-file-upload-placeholder">
                                {dragEnter ?
                                    (<>Uploading...</>) :
                                    (<>
                                        <IconSVG name="upload-doc" width={24} height={24} />
                                        <div className="text-left">
                                            <div className="main-text file-upload-main-text">
                                                Drag & Drop file here or&nbsp;
                                                <SelectFile
                                                    onFiles={handleDrop}
                                                    acceptedExtensions={acceptedExtensions?.map(extension => `.${extension}`).join(',')}
                                                />
                                            </div>
                                            <span className="footnote">Format: pdf, ppt, pptx</span>
                                        </div>
                                    </>)
                                }
                            </div>
                        </DragFileZone>
                    )}
                    <div className="component-file-upload-list">
                        <Table
                            columns={getTableColumns()}
                            dataItems={getTableData()}
                            defaultSortBy="uploadTime"
                            defaultSortByDirection={SORT.DESC}
                            className="component-file-upload-list data-list-striped"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ManagerPresentationUpload = memo(
    DocumentListComponent,
    (prevProps: Props, nextProps: Props) =>
        isEqual(prevProps.documents, nextProps.documents)
)
