import React from 'react';
import { BwicStatusLabel, BwicStatusLabelProps } from './BwicStatusLabel';
import { BiddingTimer, BiddingTimerProps } from '../bidding/common/BiddingTimer';
import { BwicStatus } from '../../types/enums/BwicStatus';

type Props = BwicStatusLabelProps & BiddingTimerProps & {
    renderEmpty?: () => JSX.Element;
};

export function BwicStatusTimerLabel({ renderEmpty, ...props }: Props) {
    if (props.status === BwicStatus.bidding) {
        return (
            <BiddingTimer
                {...props}
                render={(timer?: React.ReactNode, tooltip?: string) =>
                    timer
                        ? <BwicStatusLabel
                            {...props}
                            tooltip={tooltip}
                            render={<div className="label-status-timer">{timer}</div>}
                        />
                        : renderEmpty?.() ?? <BwicStatusLabel {...props} />
                }
            />
        );
    }
    return <BwicStatusLabel {...props} />
}