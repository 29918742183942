import { pick, sum, values } from 'lodash';
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent'
import { PercentageBar } from '../../../amrPipeline/detailed/new-issue/analytics/PercentageBar';
import { CloManagerAnalytics } from '../../../../types/clo-managers/CloManagerAnalytics';
import { RequiredFeature } from '../../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { BlockedFeatureContent, SubscribeLink } from '../../../access/BlockedFeatureText';

interface ViewedTabsProps {
    data: CloManagerAnalytics;
}

export const ViewedTabs = ({ data }: ViewedTabsProps) => {
    const totalViews = sum(values(pick(data, [
        'viewedTabsOverview',
        'viewedTabsDeals',
        'viewedTabsCloTeam',
        'viewedTabsAdditionalInformation'
    ])));

    return (
        <SectionContent title="Viewed Tabs">
            <RequiredFeature feature={SubscriptionFeature.ManagerProfileAnalytics} renderBlockedContent={() =>
                <div className="viewed-tabs-item">
                    <PercentageBar title="Overview" maxValue={totalViews} value={data.viewedTabsOverview} />
                    <BlockedFeatureContent
                        inline
                        className="empty-placeholder analytics-legend"
                        text={
                            <>
                                <h3>See more tabs viewed by clients</h3>
                                <SubscribeLink />
                            </>
                        }
                    />
                </div>
            }>
                <div className="viewed-tabs-item">
                    <PercentageBar title="Overview" maxValue={totalViews} value={data.viewedTabsOverview} />
                </div>
                <div className="viewed-tabs-item">
                    <PercentageBar title="Deals" maxValue={totalViews} value={data.viewedTabsDeals} />
                </div>
                <div className="viewed-tabs-item">
                    <PercentageBar title="CLO Team" maxValue={totalViews} value={data.viewedTabsCloTeam} />
                </div>
                <div className="viewed-tabs-item">
                    <PercentageBar title="Additional Info" maxValue={totalViews} value={data.viewedTabsAdditionalInformation} />
                </div>
            </RequiredFeature>
        </SectionContent>
    );
}
