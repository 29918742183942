import * as React from 'react';
import { Process } from '../../../types/models/Process';
import { TradingProtocol } from '../../common/TradingProtocol';
import { BwicRulesSectionGeneral } from '../sections/BwicRulesSectionGeneral';
import { BwicRulesSectionJumpBall } from '../sections/BwicRulesSectionJumpBall';
import { BwicRulesSectionOpenBidding } from '../sections/BwicRulesSectionOpenBidding';
import { BwicRulesStartEndTime } from '../sections/BwicRulesStartEndTime';
import { formatUtils } from '../../../utils/format.utils';
import { BwicProcessType } from "../../../types/models/Process";
import { BwicRulesTopX } from '../sections/BwicRulesTopX';
import { BwicRulesBestFootForward } from '../sections/BwicRulesBestFootForward';
import { InfoTooltip } from "../../common/InfoTooltip";

interface BwicRulesMainProps {
    bidsDueDate: Date;
    goodUntilDate: Date;
    process: Process;
    isSameDayBwic: boolean;
    daysToSettle: number;
    isMy: boolean;
    isAllToAll: boolean;
    isDirectBidding: boolean;
}

export function BwicRulesMain({ process, bidsDueDate, goodUntilDate, isSameDayBwic, daysToSettle, isMy, isAllToAll, isDirectBidding }: BwicRulesMainProps) {
    return (
        <div className="rules flex-row">
            <div className="column">
                <BwicRulesStartEndTime bidDueUtc={bidsDueDate}  daysToSettle={daysToSettle} goodUntilUtc={goodUntilDate} isSameDayBwic={isSameDayBwic} />
                <BwicRulesSectionGeneral process={process} isMy={isMy}/>
            </div>
            <div className="column">
                <div className="rules-section">
                    <div className="rules-section-title">
                        <h4 className="flex-row"><TradingProtocol isDirectBidding={isDirectBidding} isAllToAll={isAllToAll} infoTooltipVisible={true}/></h4>
                    </div>
                    <div className="rules-section-title">
                        <h4>Bidding Process: {formatUtils.formatBwicProcessTitle(process.type)}</h4>
                        {process.type === BwicProcessType.Live &&
                            <InfoTooltip text="In Stage 1, participants submit initial bids. In Stage 2 a specified number
                                of top bidders from Stage 1 can see the live Best Bid level and can
                                improve their bids during the pre-set interval of time. Bidding continues till
                                either improvement received during the improvement interval of BWIC ends." />
                        }
                    </div>
                    <BwicRulesSectionJumpBall process={process} />
                    <BwicRulesSectionOpenBidding process={process} />
                    <BwicRulesTopX process={process} />
                    <BwicRulesBestFootForward process={process} />
                </div>
            </div>
        </div>
    );
}
