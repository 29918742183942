import { AppState } from '../../../../types/state/AppState';
import { CloManagersState } from '../../../../types/state/CloManagersState';
import { useDispatch, useSelector } from 'react-redux';
import { General } from './General';
import { ViewedTabs } from './ViewedTabs';
import { useContext, useEffect } from 'react';
import CloManagersContext from '../../cloManagersContext';
import { ClientsActivityWidget } from './clientsActivity/ClientsActivityWidget';
import { ProfileViewHistoryWidget } from './profileViewHistory/ProfileViewHistoryWidget';
import { cloManagersActions } from '../../../../actions/clo-managers.actions';
import { EmptyPlaceholder, Preloader } from '../../../common';
import { TransactionViewHistoryWidget } from '../../../common/manager-analytics/transactionViewHistory/TransactionViewHistoryWidget';
import { DownloadedPresentationsWidget } from '../../../common/manager-analytics/downloadedPresentations/DownloadedPresentationsWidget';
import { CloManagerAccessTypes } from '../../../../types/amr-pipeline/enums/CloManagerAccessType';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';

export const Analytics = () => {
    const dispatch = useDispatch();
    const { hasAnalyticsAccess } = useContext(CloManagersContext);
    const { analytics: { general, isLoading, profileHistoryViews }, selectedManager } = useSelector<AppState, CloManagersState>((state: AppState) => state.cloManagers);

    useEffect(() => {
        if (!selectedManager?.referenceName) {
            return;
        }

        dispatch(cloManagersActions.analyticsInit(selectedManager.referenceName));

        return () => {
            dispatch(cloManagersActions.analyticsReset());
        };
    }, [selectedManager?.referenceName, dispatch]);

    if (isLoading) {
        return <Preloader inProgress />;
    }

    if (!general || !selectedManager || !hasAnalyticsAccess) {
        return null;
    }

    const isAnalyticsEmpty =
        !general.dealViews
        && !general.presentationDownloads
        && !general.totalProfileViews
        && !general.transactionViewHistory.items.length
        // Also check these in case user has no access to CLO Managers prouct
        && !profileHistoryViews.data.length
        && !general.recentProfileViews
        && !general.viewedTabsOverview;

    if (isAnalyticsEmpty) {
        return <EmptyPlaceholder text="There is no information to display." />;
    }

    return (
        <div className="component-tabs-analytics">
            <div className="data-item data-item-analytics data-item-general-cols">
                <div className="data-item-content">
                    <div className="data-item-row-col">
                        <General data={general} />
                    </div>
                    <div className="data-item-row-col">
                        <ViewedTabs data={general} />
                    </div>
                </div>
            </div>
            <ClientsActivityWidget companyReferenceName={selectedManager.referenceName} accessTypes={CloManagerAccessTypes} />
            <ProfileViewHistoryWidget companyReferenceName={selectedManager.referenceName} accessTypes={CloManagerAccessTypes} />
            <TransactionViewHistoryWidget data={general} requiredFeature={SubscriptionFeature.ManagerProfileAnalytics} />
            <DownloadedPresentationsWidget data={general} requiredFeature={SubscriptionFeature.ManagerProfileAnalytics} />
        </div>
    );
}
