import cn from 'classnames'
import { DropDownList, DropDownListItem } from '../controls/DropDownList'

interface SortDropdownProps {
    disabled?: boolean;
    items: DropDownListItem[];
    onChange: (option: string) => void;
}

export const SortDropdown = ({ disabled, items, onChange }: SortDropdownProps) => {
    return (
        <div className="sort-dropdown form-item flex-row">
            <label className={cn("form-label", { "text-light-grey": disabled })}>Sort: </label>
            <DropDownList
                className="form-control-sm"
                items={items}
                disabled={disabled}
                onChange={(item) => onChange(String(item.value))}
            />
        </div>
    )
}