import { useCallback, useEffect, useRef, useState } from "react";
import cn from 'classnames';
import IconSVG from "../../styles/svg-icons";

interface HorizontalScrollProps {
    disabled?: boolean;
    children: React.ReactElement[];
    itemsPerView: number;
    activeItemIndex: number;
}

enum Direction {
    Left = 'Left',
    Right = 'Right'
}

export const HorizontalSlider = ({ children, itemsPerView, activeItemIndex, disabled = false }: HorizontalScrollProps) => {
    const [lastDirection, setLastDirection] = useState<Direction>(Direction.Left);
    const [lastIndexToScroll, setLastIndexToScroll] = useState(0);
    const [lastActiveIndex, setLastActiveIndex] = useState<null | number>(null);
    const scrollContainer = useRef<HTMLDivElement>(null);

    const getRangeOfView = () => {
        const from = lastIndexToScroll;
        let to = lastDirection === Direction.Left
            ? from + itemsPerView - 1
            : from - itemsPerView + 1;
        return [from, to].sort();
    }

    const [from, to] = getRangeOfView();

    const handleSlide = (direction: Direction) => {
        const directionChanged = direction !== lastDirection;
        const proceedItems = directionChanged ? itemsPerView : 1;
        const intendedIndexToScroll = direction === Direction.Left
            ? lastIndexToScroll - proceedItems
            : lastIndexToScroll + proceedItems;
        handleSlideTo(intendedIndexToScroll);
    }

    const handleSlideTo = useCallback((intendedIndexToScroll: number) => {
        if (scrollContainer.current?.children) {
            const items = Array.from(scrollContainer.current.children);
            let indexToScroll = intendedIndexToScroll;
            if (indexToScroll > children.length - 1) {
                indexToScroll = children.length - 1;
            }
            if (indexToScroll < 0) {
                indexToScroll = 0;
            }
            if (indexToScroll <= from || indexToScroll >= to) {
                items[indexToScroll].scrollIntoView({ behavior: "smooth" });
                setLastDirection(indexToScroll <= from ? Direction.Left : Direction.Right);
                setLastIndexToScroll(indexToScroll);
            }
        }
    }, [children.length, from, to])

    useEffect(() => {
        // Prevent useEffect when handleSlideTo changed. Hook should work only when activeItemIndex changed.
        if(activeItemIndex !== lastActiveIndex) {
            handleSlideTo(
                activeItemIndex
            )
            setLastActiveIndex(activeItemIndex)
        }
    }, [activeItemIndex, lastActiveIndex, handleSlideTo])

    if (disabled) {
        return <>{children}</>
    }

    const leftArrowClassNames = cn(
        'btn-link horizontal-slider-button horizontal-slider-button-prev',
        { 'disabled': lastIndexToScroll === 0 }
    )

    const rightArrowClassNames = cn(
        'btn-link horizontal-slider-button horizontal-slider-button-next',
        { 'disabled': lastIndexToScroll === children.length - 1 }
    )

    return (
        <div className="horizontal-slider flex-row">
            <button
                className={leftArrowClassNames}
                onClick={() => handleSlide(Direction.Left)}
            >
                <IconSVG name="icon-expand" width={16} height={16} />
            </button>
            <div ref={scrollContainer} className="horizontal-slider-content flex-row">
                {children}
            </div>
            <button
                className={rightArrowClassNames}
                onClick={() => handleSlide(Direction.Right)}
            >
                <IconSVG name="icon-expand" width={16} height={16} />
            </button>
        </div>
    )
}