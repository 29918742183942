import * as React from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { changePxTalkActions as actions } from '../../../actions';
import { AutoCompleteInput, FormError } from '../../controls';
import { PxTalkRow } from './PxTalkRow';
import { errorMessages, pxTalkLevelTypes } from '../../../constants';
import { isRequesting, stringUtils } from '../../../utils';
import { yup } from '../../../validation/yup';
import { AppState } from '../../../types/state/AppState';
import { FormikHelpers } from 'formik/dist/types';
import IconSVG from '../../../styles/svg-icons';
import { usePriceTalks } from '../../../effects/usePriceTalks';
import { Preloader } from '../Preloader';
import { useBrokerDealers } from '../../../effects/useBrokerDealers';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    saveButtonText: string;
    onSave: () => void;
    onCancel: () => void;
}

export const ChangePxTalk: React.FC<Props> = ({ saveButtonText, onSave, onCancel }) => {
    const dispatch = useAppDispatch();

    const visible = useSelector((s: AppState) => s.changePxTalk.visible);
    const existingPriceTalks = useSelector((state: AppState) => state.changePxTalk.pxTalks);
    const ticker = useSelector((state: AppState) => state.changePxTalk.ticker);
    const bwicReferenceName = useSelector((state: AppState) => state.changePxTalk.bwicReferenceName);
    const positionId = useSelector((state: AppState) => state.changePxTalk.positionId);

    const hasEmptyCompany = existingPriceTalks?.some(p => p.company == null);
    const pxTalksState = usePriceTalks(bwicReferenceName ?? '', positionId ?? 0, hasEmptyCompany);
    const pxTalks = hasEmptyCompany ? [] : existingPriceTalks ?? [];

    const brokerDealersState = useBrokerDealers();

    React.useEffect(() => {
        return () => {
            dispatch(actions.reset());
        }
    }, [dispatch]);

    if (!visible) return null;

    const handleCompanyCustomValidate = (companyName: string | null | undefined) =>
        !!brokerDealersState.items
            .filter(c => !pxTalks?.some(p => p.company.id === c.id))
            .map(c => c.name)
            .some(n => n.localeCompare(String(companyName), undefined, { sensitivity: 'accent' }) === 0);

    const validationSchema = yup.object().shape({
        companyName: yup.string().required().test('companyName', errorMessages.invalidValue, handleCompanyCustomValidate)
    });

    const handleAddCompany = (values: { companyName: string }, formHelpers: FormikHelpers<{ companyName: string }>) => {
        const levelType = pxTalkLevelTypes.price.key;
        const company = brokerDealersState.items.find(c =>
            c.name.localeCompare(values.companyName, undefined, { sensitivity: 'accent' }) === 0);

        dispatch(actions.add({ company, levelType }));
        formHelpers.setFieldValue('companyName', '');
        formHelpers.setTouched({ companyName: false });
    };

    const handleCancel = () => {
        dispatch(actions.reset());
        if (onCancel) {
            onCancel();
        }
    };

    return (
        <div className="sidebar-panel sidebar-panel-pxTalk">
            <div className="sidebar-panel-header">
                {!stringUtils.isNullOrEmpty(ticker) && <h2>Px Talk for {ticker}</h2>}
                {stringUtils.isNullOrEmpty(ticker) && <h2>Change Px Talk</h2>}
                <button className="btn-close flex-none flex-item-right" onClick={handleCancel}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
            </div>
            <div className="sidebar-panel-content">
                <Preloader inProgress={isRequesting(pxTalksState.requestState) || isRequesting(brokerDealersState.requestState)}>
                    <Formik
                        initialValues={{ companyName: '' }}
                        validateOnBlur={true}
                        onSubmit={handleAddCompany}
                        validationSchema={validationSchema}
                    >
                        {({ values, errors, touched, setFieldValue }) => (
                            <Form>
                                <div className="form-row form-row-inline form-row-add">
                                    <div className="form-control-wrapper">
                                        <AutoCompleteInput
                                            name="companyName"
                                            className="form-control form-control-search"
                                            maxLength="256"
                                            required={true}
                                            sourceItems={brokerDealersState.items.filter(c => !pxTalks.some(p => p.company.id === c.id)).map(c => c.name)}
                                            value={values.companyName}
                                            onChange={(companyName: string) => setFieldValue('companyName', companyName)}
                                            placeholder="Add New Company"
                                            isInvalid={touched.companyName && values.companyName.trim() && errors.companyName}
                                        />
                                        <FormError
                                            message={touched.companyName && values.companyName.trim() && errors.companyName}
                                        />
                                    </div>
                                    <button className="btn btn-main" type="submit" disabled={!values.companyName.trim()}>
                                        Add
                                </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div className="table-pxTalks">
                        <div className="table-pxTalks-header flex-row">
                            <div className="flex-none table-pxTalks-cell-company">Company</div>
                            <div className="flex-none table-pxTalks-cell-pxTalk">Px Talk</div>
                            <div className="flex-none table-pxTalks-cell-normalized text-right">Normalized</div>
                            <div className="flex-none table-pxTalks-cell-type">Type</div>
                        </div>
                        <div className="table-pxTalks-body">
                            {pxTalks.map((p, i: number) => <PxTalkRow key={i} index={i} pxTalk={p} />)}
                        </div>
                    </div>
                </Preloader>
            </div>
            <div className="sidebar-panel-footer">
                <button className="btn btn-ghost" type="button" onClick={handleCancel}>CANCEL</button>
                <button className="btn btn-main" type="button" onClick={() => dispatch(actions.waitNormalizing(onSave))}>
                    {saveButtonText}
                </button>
            </div>
        </div>
    );
};

ChangePxTalk.defaultProps = {
    saveButtonText: 'SAVE CHANGES'
};
