import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { companyMembersActions } from '../../../actions/company-members.actions';
import { chartBoxHorizontal } from '../../../constants/dashboard';
import { MembersList } from './MembersList';
import { companyStatuses, constants } from '../../../constants';
import { Accordion, AccordionItem, AccordionItemHeader, AccordionItemBody } from '../accordion';
import { OnHoverTooltip, EmptyPlaceholder } from '../../common';
import { PxTalkNormalizationTable } from './PxTalkNormalizationTable'
import IconSVG from '../../../styles/svg-icons';
import { isRequesting, shouldRequest } from '../../../utils/request-state.utils';
import { Preloader } from '../Preloader';
import { usePriceTalks } from '../../../effects/usePriceTalks';
import { ValueBlocker } from '../../access/ValueBlocker';
import { HorizontalBoxChart } from '../charts/horizontalBox/HorizontalBoxChart';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { RequiredFeature } from '../../access/RequiredFeature';
import { EvalKTalkLabel } from './EvalKTalkLabel';
import { useAppSelector } from '../../../effects/useAppSelector';
import { CompanySlim } from '../../../types/company/CompanySlim';
import classNames from 'classnames';
import { BankLink } from '../../amrPipeline/common/BankLink';

const chartName = "Price Talk Dispersion";

interface PxTalkListProps {
    bwicReferenceName: string,
    isParsed: boolean,
    evalPriceTalkVisible?: boolean,
    evalPriceTalk?: string | null,
    normalizedEvalPriceTalk?: number | null
}

export function PxTalkList({
    bwicReferenceName,
    isParsed,
    evalPriceTalkVisible = false,
    evalPriceTalk = null,
    normalizedEvalPriceTalk = null
}: PxTalkListProps) {
    const dispatch = useDispatch();

    const companyMembers = useAppSelector(store => store.companyMembers);
    const visible = useAppSelector(store => store.pxTalkList.visible);
    const positionId = useAppSelector(store => store.pxTalkList.positionId);
    const priceTalksState = usePriceTalks(bwicReferenceName, positionId ?? 0);
    const priceTalks = positionId && visible ? priceTalksState.priceTalks : [];
    const [expandedCompanies, setExpandedCompanies] = useState<number[]>([]);
    const companyIds = Object.keys(companyMembers).map(cm => Number(cm));
    const isExpandAll = companyIds.length === expandedCompanies.length;

    useEffect(() => {
        priceTalks.forEach((p) => {
            if (p.company) {
                const companyId = p.company.id;
                if (!companyMembers[companyId] || shouldRequest(companyMembers[companyId].requestState)) {
                    dispatch(companyMembersActions.fetchCompanyMembers.request({ companyId }));
                }
            }
        })
        // eslint-disable-next-line
    }, [priceTalks, dispatch]);

    if (!visible || !positionId) {
        return null;
    }

    const handleCompanyExpanded = (company: CompanySlim | null, e: React.FormEvent<HTMLElement>) => {
        e.stopPropagation();

        if (!company || company?.id === undefined) {
            return;
        }

        if (company.status !== companyStatuses.active.statusCode) {
            return;
        }

        if (expandedCompanies.includes(company.id)) {
            setExpandedCompanies(expandedCompanies.filter(sm => sm !== company.id));
        } else {
            setExpandedCompanies([...expandedCompanies, company.id])
        }
    };

    const toggleExpandAll = () => {
        if (isExpandAll) {
            setExpandedCompanies([]);
        } else {
            setExpandedCompanies(companyIds);
        }
    }

    const renderEmptyList = () => {
        const placeholder = (
            <div className="px-talk-information-placeholder">
                <EmptyPlaceholder textView={true} text="No Px Talk submitted." />
            </div>
        );

        return (
            <div className="px-talk-information">
                {
                    isParsed ? (
                        <RequiredFeature feature={SubscriptionFeature.BwicMonitorBwicsData}>
                            {placeholder}
                        </RequiredFeature>
                    ) : placeholder
                }
            </div>
        );
    };

    const renderChart = () => {
        const chart = <HorizontalBoxChart
            pxTalks={priceTalks}
            evalPriceTalk={normalizedEvalPriceTalk}
            evalPriceTalkVisible={evalPriceTalkVisible}
            constants={chartBoxHorizontal}
        />;

        return isParsed ? (
            <ChartRequiredFeature
                feature={SubscriptionFeature.BwicMonitorBwicsData}
                chartName={chartName}
                blockedClassName="restricted-placeholder-px-talk"
            >
                {chart}
            </ChartRequiredFeature>
        ) : chart
    }

    const renderPriceTalkValue = (priceTalk: string | null) => isParsed ? (
        <ValueBlocker feature={SubscriptionFeature.BwicMonitorBwicsData}>
            {priceTalk}
        </ValueBlocker>
    ) : priceTalk

    const pxTalks = priceTalks
        .filter(pxTalk => pxTalk.talk && pxTalk.company)
        .sort((a, b) => (b.normalizedLevel ?? 0) - (a.normalizedLevel ?? 0));

    const renderEvalPxTalk = () => {
        if (!evalPriceTalkVisible) {
            return null;
        }
        return (
            <AccordionItem key="eval" isSelected={false} className="collapsable-item-k-talk">
                <AccordionItemHeader onItemHeaderClick={() => null} showAdditionalIcon={false} isShowCollapsedIcon={false}>
                    <>
                        <EvalKTalkLabel withInfoButton />
                        <div className="flex-item-right text-medium">
                            {evalPriceTalk || constants.emptyPlaceholder}
                        </div>
                    </>
                </AccordionItemHeader>
            </AccordionItem>
        );
    }

    const hasPxTalksToDisplay = (evalPriceTalkVisible && evalPriceTalk)
        || (pxTalks.length > 0 && pxTalks.some(p => p.normalizedLevel));

    return (
        <Preloader inProgress={isRequesting(priceTalksState.requestState)}>
            {
                !isRequesting(priceTalksState.requestState) &&
                <>
                    {
                        hasPxTalksToDisplay &&
                        <div className="px-talk-information" onClick={e => e.stopPropagation()}>
                            <div className="px-talk-information-title">
                                <h3>{chartName}</h3>
                            </div>
                            <div className="px-talk-information-chart">
                                {renderChart()}
                            </div>
                            <div className="px-talk-information-history">
                                <div onClick={toggleExpandAll} className="px-talk-information-title flex-row">
                                    <IconSVG name="icon-double-arrows" className={classNames({ 'collapse': isExpandAll })} width={16} height={16} />
                                    <div className="text-sm">Сompanies</div>
                                    <div className="flex-item-right text-sm">Px Talk</div>
                                    <OnHoverTooltip
                                        overlayClassName="px-talk-tooltip"
                                        overlay={<PxTalkNormalizationTable />}
                                    >
                                        <IconSVG name="info" width={16} height={16} />
                                    </OnHoverTooltip>
                                </div>
                                <Accordion>
                                    {renderEvalPxTalk()}
                                    {
                                        pxTalks
                                            .map((item) => {

                                                return (
                                                    <AccordionItem
                                                        key={item.company.id}
                                                        isSelected={expandedCompanies.includes(item.company.id)}
                                                    >
                                                        <AccordionItemHeader
                                                            isSelected={expandedCompanies.includes(item.company.id)}
                                                            onItemHeaderClick={(e) => handleCompanyExpanded(item.company, e)}
                                                            isShowCollapsedIcon={item.company.status === companyStatuses.active.statusCode}
                                                        >
                                                            <>
                                                                <div className="company-name">
                                                                    <BankLink
                                                                        referenceName={item.company.referenceName}
                                                                        legalName={item.company.name}
                                                                    />
                                                                </div>
                                                                <div className="flex-item-right text-medium">
                                                                    {renderPriceTalkValue(item.talk)}
                                                                </div>
                                                            </>
                                                        </AccordionItemHeader>
                                                        <AccordionItemBody visible={expandedCompanies.includes(item.company.id)}>
                                                            <MembersList companyMembers={companyMembers[item.company.id]} />
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                );
                                            })
                                    }
                                </Accordion>
                            </div>
                        </div>
                    }
                    {!hasPxTalksToDisplay && renderEmptyList()}
                </>
            }
        </Preloader>
    );
}

PxTalkList.defaultProps = {
    priceTalksPreloaded: undefined
}
