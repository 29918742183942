import React from 'react';
import { StatusMessageSectionType } from '../../../../types/state/NotificationState';
import { StatusMessageSection } from '../../../status-message/StatusMessageSection';

interface Props {
    isCommon: boolean;
    isByRating: boolean;
}

export const TradingLimitDescription = ({ isCommon, isByRating }: Props) => {
    let result: React.ReactNode = 'Please select from the options below';
    if (isCommon && !isByRating) {
        result = 'The total daily trading limit across all ratings will be set.'
    } else if (!isCommon && isByRating) {
        result = 'The trading daily limit will be set per each rating individually.'
    } else if (isCommon && isByRating) {
        result = (
            <>
                <p>A double daily trading limit will be set:</p>
                <ul className="list-bullet">
                    <li>First, the limit by rating is checked.</li>
                    <li>Then by the total daily trading limit.</li>
                </ul>
            </>
        )
    }
    return <div className="limits-form-info"><StatusMessageSection type={StatusMessageSectionType.Info}>{result}</StatusMessageSection></div>
}
