import { ProfileViewHistoryTabs } from "./ProfileViewHistoryTabs";
import { useState } from "react";
import { OverviewTab } from "./OverviewTab";
import { ContactsTab } from "./ContactsTab";
import { PresDownloadedTab } from "./PresDownloadedTab";
import { CloManagerProfileView } from '../../../../../types/amr-pipeline/models/CloManagerSession';
import { OnHoverTooltip } from "../../../../common";
import { dateTimeUtils } from "../../../../../utils";
import { Popover } from "../../../../controls";
import { TabItem, TabList } from "../../../../bidding/common/tab-list";
import { TabContent } from "../../../../amrPipeline/common/tabs/TabContent";
import { SubscriptionFeature } from "../../../../../types/billing/SubscriptionFeature";
import { RequiredFeature } from "../../../../access/RequiredFeature";

interface Props {
    userSession: CloManagerProfileView;
    children?: React.ReactNode;
    defaultTab?: ProfileViewHistoryTabs;
    companyReferenceName: string;
}

export function ProfileViewHistoryPopover({
    userSession,
    children,
    defaultTab = ProfileViewHistoryTabs.Overview,
    companyReferenceName,
}: Props) {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const formattedActionDate = dateTimeUtils.utcToLocalString(
        userSession.accessDateTime
    );

    const PseudoLink = (
        <span className="pseudo-link">
            <OnHoverTooltip>{children || formattedActionDate}</OnHoverTooltip>
        </span>
    );

    const setDefaultTab = () => setActiveTab(defaultTab);

    const renderActiveTab = () => {
        switch (activeTab) {
            case ProfileViewHistoryTabs.PresDownloaded:
                return (
                    <PresDownloadedTab
                        companyReferenceName={companyReferenceName}
                        documents={userSession.documents}
                    />
                );
            case ProfileViewHistoryTabs.Contacts:
                return <ContactsTab userSession={userSession} />;
            default:
                return <OverviewTab views={userSession.views} />;
        }
    };

    return (
        <RequiredFeature
            feature={SubscriptionFeature.ManagerProfileAnalytics}
            renderBlockedContent={() => (
                <span>{children || formattedActionDate}</span>
            )}
        >
            <Popover
                className="popover-transaction-views"
                title={formattedActionDate}
                label={
                    <span className="name-tag">{userSession.userFullName}</span>
                }
                actionButton={PseudoLink}
                onOpen={setDefaultTab}
                showCloseButton={true}
            >
                <TabList>
                    <TabItem
                        active={activeTab === ProfileViewHistoryTabs.Overview}
                        title="Overview"
                        onClick={() =>
                            setActiveTab(ProfileViewHistoryTabs.Overview)
                        }
                    />
                    <TabItem
                        active={
                            activeTab === ProfileViewHistoryTabs.PresDownloaded
                        }
                        title="Pres. Downloaded"
                        onClick={() =>
                            setActiveTab(ProfileViewHistoryTabs.PresDownloaded)
                        }
                    />
                    <TabItem
                        active={activeTab === ProfileViewHistoryTabs.Contacts}
                        title="Contacts"
                        onClick={() =>
                            setActiveTab(ProfileViewHistoryTabs.Contacts)
                        }
                    />
                </TabList>
                <TabContent>{renderActiveTab()}</TabContent>
            </Popover>
        </RequiredFeature>
    );
}
