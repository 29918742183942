import React from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router';
import { routes } from '../../../../constants';
import { SettlementAccountsView } from '../../../settlement/SettlementAccountsView';
import { SettlementAccountView } from '../../../settlement/view/SettlementAccountView';
import { SettlementAccountEdit } from '../../../settlement/edit/SettlementAcountEdit';
import { Breadcrumbs } from '../../../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../../controls/breadcrumbs/BreadcrumbsItem';
import { isRequesting, isRequestSuccess } from '../../../../utils/request-state.utils';
import { Preloader } from '../../../common';
import { useAssetManagers } from '../../../../effects/data-accessors/useAssetManagers'
import { useSettlementAccountDelete } from '../../../settlement/edit/useSettlementAccountDelete';
import { CompanyStatus } from '../../../../types/company/CompanyStatus';
import { Confirm } from '../../../alerts';

export function ClientsSettlementAccounts() {
    const history = useHistory();
    const { assetManagersRequestState, assetManagers } = useAssetManagers();
    const { companyId } = useParams<{ companyId?: string }>();
    const assetManagerCompanyId = Number(companyId);
    const isAssetManagersLoaded = isRequestSuccess(assetManagersRequestState);
    const activeAssetManagers = assetManagers.filter(a => a.status === CompanyStatus.active);
    const activeAssetManager = isAssetManagersLoaded
        ? activeAssetManagers.find(a => a.id === assetManagerCompanyId)
        : undefined;
    const [deletingAccountId, setDeletingAccountId] = React.useState<number | null>(null)
    const { deleteAccountRequestState, onDelete } = useSettlementAccountDelete(assetManagerCompanyId);

    if (isAssetManagersLoaded && companyId && !activeAssetManager) {
        return <Redirect to={routes.notFound} />;
    }

    const renderSelectedAccountName = () => {
        const assetManager = activeAssetManagers.find(manager => manager.id === assetManagerCompanyId);
        return assetManager ? <span className="name-tag">{assetManager.name}</span> : null;
    }

    return (
        <div className="container settlement-accounts">
            <Preloader inProgress={!isAssetManagersLoaded}>
                <div className="sub-header">
                    <div className="sub-header-row type01 flex-row">
                        <Breadcrumbs>
                            <BreadcrumbsItem route={routes.clients} text="Clients" />
                        </Breadcrumbs>
                        <Switch>
                            <Route exact path={routes.createClientsSettlementAccount}>
                                <h1>
                                    Create Settlement Account
                                    <span className="name-tag">{activeAssetManager?.name}</span>
                                </h1>
                            </Route>
                            <Route path={routes.editClientsSettlementAccount}>
                                <h1>Edit Settlement Account {renderSelectedAccountName()}</h1>
                            </Route>
                            <Route>
                                <h1>Settlement Accounts</h1>
                                <select
                                    className="form-control form-select"
                                    value={assetManagerCompanyId}
                                    onChange={e => history.push(routes.clientsSettlementAccountUrl(e.target.value))}
                                >
                                    {activeAssetManagers.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                </select>
                            </Route>
                        </Switch>
                    </div>
                </div>
                <SettlementAccountsView
                    assetManagerId={assetManagerCompanyId}
                    deleteAccountRequestState={deleteAccountRequestState}
                    onAccountCreate={() => history.push(routes.createClientsSettlementAccountUrl(companyId))}
                    onAccountChange={(account, replace) => {
                        const path = routes.clientsSettlementAccountDetailsUrl(assetManagerCompanyId, account.id);
                        replace ? history.replace(path) : history.push(path);
                    }}
                >
                    {
                        activeSettlementAccount =>
                            <>
                                <Switch>
                                    <Route exact path={routes.clientsSettlementAccount}>
                                        {
                                            activeSettlementAccount &&
                                            <SettlementAccountView
                                                settlementAccount={activeSettlementAccount}
                                                disabled={isRequesting(deleteAccountRequestState)}
                                                onEdit={() => history.push(
                                                    routes.editClientsSettlementAccountUrl(
                                                        assetManagerCompanyId,
                                                        activeSettlementAccount.id
                                                    )
                                                )}
                                                onDelete={() => setDeletingAccountId(activeSettlementAccount.id)}
                                            />
                                        }
                                    </Route>
                                    <Route exact path={routes.createClientsSettlementAccount}>
                                        <SettlementAccountEdit
                                            assetManagerId={assetManagerCompanyId}
                                            disabled={isRequesting(deleteAccountRequestState)}
                                        />
                                    </Route>
                                    <Route exact path={routes.editClientsSettlementAccount}>
                                        <SettlementAccountEdit
                                            settlementAccount={activeSettlementAccount}
                                            assetManagerId={assetManagerCompanyId}
                                            disabled={isRequesting(deleteAccountRequestState)}
                                        />
                                    </Route>
                                    <Redirect to={routes.notFound} />

                                </Switch>
                                {deletingAccountId && (
                                    <Confirm
                                        title="Confirmation"
                                        text={<>Would you like to remove <strong>{activeSettlementAccount?.accountName}</strong> account?</>}
                                        onConfirm={() => {
                                            onDelete(deletingAccountId);
                                            setDeletingAccountId(null);
                                        }}
                                        onCancel={() => setDeletingAccountId(null)}
                                        confirmButtonText="Confirm"
                                    />
                                )}
                            </>
                    }
                </SettlementAccountsView>
            </Preloader>
        </div>
    );
}
