import { useSelector } from 'react-redux';
import { portfolioActions, pxTalkListActions, rulesActions, securityDetailsActions } from '../../../../actions';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { BwicPositionBase } from '../../../../types/bwic/BwicPositionBase';
import { AppState } from '../../../../types/state/AppState';
import { BwicRules } from '../../../bwic-rules/BwicRules';
import { PxTalkList } from '../../../common';
import { BwicViewLink } from '../../../common/BwicViewLink';
import { RightSideTabPanel } from '../../../controls/RightSideTabPanel';
import { SecurityDetails, SecurityDetailsPanel } from '../../../security-details';
import SecurityDetailsOptions from '../../../security-details/SecurityDetailsOptions';
import { BWICLog } from '../../../security-details/bwic-log/BWICLog';
import { bwicLogActions } from '../../../../actions/bwic-log.actions';
import { useAppDispatch } from '../../../../effects/useAppDispatch';
import { cloManagersActions } from '../../../../actions/clo-managers.actions';

export const DealsControlPanel = () => {
    const dispatch = useAppDispatch();

    const security = useAppSelector(s => s.cloManagers.selectedSecurity);
    const securityDetails = useAppSelector(s => s.securityDetails);
    const bwicLog = useAppSelector(s => s.bwicLog);
    const pxTalkListVisible = useAppSelector(s => s.pxTalkList.visible);
    const rules = useSelector((s: AppState) => s.rules);

    if (!security) return null;

    const currentBwic = security?.currentBwic;

    const clearSelectedSecurity = () => dispatch(cloManagersActions.selectedSecurityChange());

    if (!currentBwic) return <SecurityDetailsPanel onClose={clearSelectedSecurity} />;

    const tabs = [
        {
            title: 'Px Talk',
            active: pxTalkListVisible,
            renderContent: () => 
                <PxTalkList 
                    bwicReferenceName={currentBwic.referenceName} 
                    isParsed={currentBwic.isParsed} 
                />,
            onActivate: () => dispatch(pxTalkListActions.show(currentBwic.positionId)),
            onDeactivate: () => dispatch(pxTalkListActions.hide())
        },
        {
            title: 'Security',
            active: !!securityDetails.security,
            renderContent: () => <SecurityDetails />,
            onActivate: () => { security && dispatch(securityDetailsActions.securityDetailsInitRequest(security as BwicPositionBase, true)) },
            onDeactivate: () => { dispatch(securityDetailsActions.securityDetailsReset()) }
        },
        {
            title: 'BWIC Log',
            active: !!bwicLog.security,
            renderContent: () => <BWICLog />,
            onActivate: () => { security && dispatch(bwicLogActions.bwicLogInitRequest(security as BwicPositionBase)) },
            onDeactivate: () => dispatch(bwicLogActions.bwicLogReset())
        },
        {
            title: 'Rules',
            active: rules.show,
            renderContent: () =>
                <div className="overflow-auto">
                    <BwicRules
                        isParsed={currentBwic.isParsed}
                        bidsDueDate={currentBwic.bidsDueUtc}
                        process={currentBwic.process}
                        parsedProcess={currentBwic.parsedProcess}
                        goodUntilDate={currentBwic.goodUntilUtc}
                        isSameDayBwic={currentBwic.isSameDayBwic}
                        daysToSettle={currentBwic.daysToSettle}
                        isMy={currentBwic.isMy}
                        isAllToAll={currentBwic.isAllToAll}
                        isDirectBidding={currentBwic.isClearingBankParticipant}
                    />
                    <div className="view-bidding flex-item-right">
                        <BwicViewLink
                            referenceName={currentBwic.referenceName}
                            status={currentBwic.status}
                            isDirectBidding={currentBwic.isClearingBankParticipant}
                            isAllToAll={currentBwic.isAllToAll}
                            isMy={currentBwic.isMy}
                            isParticipant={currentBwic.isParticipant}
                            isParsed={currentBwic.isParsed}
                        />
                    </div>
                </div>,
            onActivate: () => dispatch(rulesActions.show(currentBwic.positionId)),
            onDeactivate: () => dispatch(rulesActions.hide())
        }
    ];

    return (
        <RightSideTabPanel
            className="right-panel-tabs"
            title={
                <>
                    <>{security?.ticker}</>
                    {security && (
                        <SecurityDetailsOptions
                            optionsExclude={[]}
                            position={security as BwicPositionBase}
                        />
                    )}
                </>
            }
            items={tabs}
            onClose={() => {
                dispatch(portfolioActions.hideActivePanels());
                clearSelectedSecurity();
            }}
        />
    )
};
