import * as React from 'react';
import IconSVG from "../../styles/svg-icons";

interface Props {
    text: string;
    stateDescription?: boolean
    duration?: number
}

export function CopyToClipboard({ text, stateDescription = false, duration = 500 }: Props) {
    const [copyed, setCopyed] = React.useState(false);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (navigator.clipboard != null) {
            navigator.clipboard.writeText(text);
            setCopyed(true);
        }
        else if (window.clipboardData) { // IE 11
            window.clipboardData.setData("Text", text);
            setCopyed(true);
        }
        setTimeout(() => setCopyed(false), duration);
    }

    return (
        <div onClick={handleClick}>
            {
                stateDescription
                    ?
                    <>
                        <IconSVG name="copy" width={16} height={16} />
                        {copyed ? 'Сopied' : 'Copy Generated Token'}
                    </>
                    : <IconSVG name="copy" width={16} height={16} />
            }
        </div>
    );
}
