import { TransactionStatus } from '../../../../types/amr-pipeline/enums/TransactionStatus';
import { TransactionType } from '../../../../types/amr-pipeline/enums/TransactionType';

export const StatisticsChartsTransactionStatuses = [
    TransactionStatus.Active,
    TransactionStatus.Priced,
    TransactionStatus.Closed,
    TransactionStatus.OnHold,
];

export const ArrangersTrusteesChartsTransactionStatuses = [
    TransactionStatus.Priced,
    TransactionStatus.Closed,
];

export const StatisticsChartsTransactionTypes = [
    TransactionType.newIssue,
    TransactionType.refi,
    TransactionType.reset
];
