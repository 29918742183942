import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../../constants";
import IconSVG from "../../../../styles/svg-icons";
import { AppState } from "../../../../types/state/AppState";
import { CloManagersState } from "../../../../types/state/CloManagersState";
import { FormField } from "../../../amrPipeline/common/FormField";
import { SectionContent } from "../../../amrPipeline/common/section/SectionContent";
import { Table } from "../../../bidding/common/table";
import { investmentTeamColumns } from "./investment-team.columns";
import { EmptyPlaceholder } from "../../../common";
import { cloManagersActions } from '../../../../actions/clo-managers.actions';
import { CloManagerAccessType } from '../../../../types/amr-pipeline/enums/CloManagerAccessType';
import { BlockedFeatureContent, SubscribeLink } from '../../../access/BlockedFeatureText';
import { ActionBlocker } from '../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { useContext } from 'react';
import CloManagersContext from '../../cloManagersContext';

export const CloTeam = () => {
    const dispatch = useDispatch();
    const { ownCompany } = useContext(CloManagersContext);
    const { selectedManager, bwicCloManagers } = useSelector<AppState, CloManagersState>(
        (state: AppState) => state.cloManagers
    );

    if (!selectedManager?.details) {
        return null;
    }

    const {
        address,
        city,
        state,
        zipCode,
        country,
        webSite,
        linkedIn,
        phone,
        investmentTeam,
    } = selectedManager.details;

    const cityAndState = city?.name && state?.name
        ? `${city?.name}, ${state?.name}`
        : city?.name || state?.name || constants.emptyPlaceholder;

    const zipCodeAndCountry = zipCode && country?.name
        ? `${zipCode}, ${country?.name}`
        : zipCode || country?.name || constants.emptyPlaceholder;

    const allAddressFieldsEmpty =
        !address
        && !city?.name
        && !state?.name
        && !zipCode
        && !country?.name;

    const trackActivity = (accessType: CloManagerAccessType) => {
        dispatch(cloManagersActions.logUserActivity(selectedManager.referenceName, accessType))
    };

    const contactItems = [
        ...(bwicCloManagers || []),
        ...(investmentTeam.items || []),
    ];

    const hiddenContacts = contactItems.length + investmentTeam.numberOfHiddenItems - 1;

    return (
        <>
            <SectionContent
                title="Contact Information"
                className="data-item-general-information data-item-general-cols"
            >
                <div className="data-item-row-col">
                    <FormField
                        title="Website"
                        value={
                            webSite ? (
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={webSite}
                                    onClick={() => trackActivity(CloManagerAccessType.CloTeamWebsite)}
                                >
                                    {webSite}
                                </a>
                            ) : (
                                constants.emptyPlaceholder
                            )
                        }
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="LinkedIn"
                        value={
                            linkedIn ? (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn-link"
                                    href={linkedIn}
                                    onClick={() => trackActivity(CloManagerAccessType.CloTeamIn)}
                                >
                                    <IconSVG
                                        name="linkedin-filled"
                                        width={16}
                                        height={16}
                                    />
                                </a>
                            ) : (
                                constants.emptyPlaceholder
                            )
                        }
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Phone"
                        value={phone || constants.emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                </div>
                <div className="data-item-row-col">
                    <FormField
                        title="Address"
                        className="data-item-row-full-height data-item-row-title-md"
                        value={
                            allAddressFieldsEmpty
                            ? constants.emptyPlaceholder
                            : (
                                <>
                                    {address && <p>{address || constants.emptyPlaceholder}</p>}
                                    {cityAndState && <p>{cityAndState}</p>}
                                    {zipCodeAndCountry && <p>{zipCodeAndCountry}</p>}
                                </>
                            )
                        }
                    />
                </div>
            </SectionContent>
            <SectionContent title="Contacts">
                {contactItems.length ? (
                    <ActionBlocker feature={SubscriptionFeature.ManagerProfileContacts} overrideRequiredFeatures={ownCompany} noTooltip>
                        {blocked => (
                            <>
                                <Table
                                    className="data-list data-list-striped data-list-overflow-auto clo-manager-investment-team-table"
                                    columns={investmentTeamColumns}
                                    dataItems={blocked ? contactItems.slice(0, 1) : contactItems}
                                    createSecurityCustomArgs={() => ({ trackActivity, ownCompany })}
                                />
                                {blocked && hiddenContacts > 0 && (
                                    <BlockedFeatureContent
                                        inline
                                        text={<><SubscribeLink /> to see {hiddenContacts} more contact(s).</>}
                                    />
                                )}
                            </>
                        )}
                    </ActionBlocker>
                ) : (
                    <EmptyPlaceholder text="There are no contacts yet" />
                )}
            </SectionContent>
        </>
    );
};
