import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { sumBy, values } from 'lodash';
import { AppState } from '../../../types/state/AppState';
import { clientsCompaniesActions } from '../../../actions/clients-companies.actions';
import { Table } from '../../bidding/common/table';
import { EmptyPlaceholder, Preloader, SearchInput } from '../../common';
import { isRequesting, isRequestSuccess, shouldRequest } from '../../../utils';
import {
    SettlementAgentClientAgreement,
    SettlementAgentClientCompany
} from '../../../types/bid-as-dealer/SettlementAgentClientAgreement';
import { ClientsControlPanel } from './control-panel/ClientsControlPanel';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { CompanyDetailsButton } from '../../company-details/CompanyDetailsButton';
import { BrokerDealerAgreementStatusLabel } from './BrokerDealerAgreementStatusLabel';
import { AgreementActionButtons } from './AgreementActionButtons';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import {
    SettlementAgreementStatus,
    UnrequestedAgreementStatus
} from '../../../types/bid-as-dealer/SettlementAgreementStatus';
import { companyRoles, constants, routes, SORT, SORTING_TYPE } from '../../../constants';
import { CurrencyConversion } from './CurrencyConversion';
import { ViewSettlementAgentCommission } from './ViewSettlementAgentCommission';
import { DailyTradingLimitDialog } from '../../common/DailyTradingLimitDialog';
import { useCurrentUserCompany } from '../../../effects/useCurrentUserCompany';
import { useSettlementAccounts } from '../../../effects/data-accessors/useSettlementAccounts';
import { ClientsFilter } from './clients-filter/ClientsFilter';
import { ClientsFilterOptions } from './clients-filter/ClientsFilterOptions';
import { useClientsFilter } from './clients-filter/useClientsFilter';
import { AgreementTableDataItem, ClientsTableDataItem, UnrequestedTableDataItem } from './ClientsTableDataItem';
import { useAssetManagers } from '../../../effects/data-accessors/useAssetManagers';
import { CompanyStatus } from '../../../types/company/CompanyStatus';
import { CompanyDetailsPanel } from '../../company-details/CompanyDetailsPanel';
import { settlementAccountActions } from '../../../actions/settlement-account.actions';
import { FeatureButton } from '../../access/FeatureButton';
import { companyDetailsActions } from '../../../actions/company-details.actions';

interface Props {
    onFilterChanged: (filter: ClientsFilterOptions) => void;
    filter: ClientsFilterOptions;
}

export function Clients({ onFilterChanged, filter }: Props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const agreements = useSelector((s: AppState) => s.clientsCompanies.companiesList);
    const requestStateFetchClientsList = useSelector((s: AppState) => s.clientsCompanies.requestStateFetchClientsList);
    const searchString = useSelector((s: AppState) => s.clientsCompanies.searchString);
    const selectedCompanyId = useSelector((s: AppState) => s.clientsCompanies.selectedCompanyForAffirm);
    const selectedCompanyForAffirm = selectedCompanyId ? agreements.find(a => a.signatory.id === selectedCompanyId) : undefined;
    const companyDetailsPanelCompanyId = useSelector((state: AppState) => state.companyDetails.currentCompanyId);

    const { settlementAccountsRequestState, settlementAccounts } = useSettlementAccounts(false);
    const { assetManagers, assetManagersRequestState } = useAssetManagers();
    const [companyRequestState, company] = useCurrentUserCompany();

    const activeAssetManagers = useMemo(
        () => assetManagers.filter(x => x.status === CompanyStatus.active),
        [assetManagers]);
    const isLoading = isRequesting(requestStateFetchClientsList) ||
        isRequesting(companyRequestState) ||
        isRequesting(settlementAccountsRequestState) ||
        isRequesting(assetManagersRequestState);

    const tableDataItems: ClientsTableDataItem[] = useMemo(() => {
        const agreementTableItems = agreements.map(a => ({
            ...a,
            settlementAccountCount: settlementAccounts.filter(sa => sa.assetManager.id === a.signatory.id).length,
            totalDailyTradingLimit: a.agreementStatus === SettlementAgreementStatus.confirmed
                ? a.dailyTradingLimit.common
                    ? a.dailyTradingLimit.common
                    : sumBy(values(a.dailyTradingLimit.byRating), limit => Number(limit))
                : 0
        }));

        const unrequestedCompanies = activeAssetManagers.filter(c => !agreements.some(a => a.signatory.id === c.id));
        const unrequested: UnrequestedTableDataItem[] = unrequestedCompanies.map(c => ({
            signatory: c as SettlementAgentClientCompany,
            settlementAccountCount: settlementAccounts.filter(a => a.assetManager.id === c.id).length,
            agreementStatus: UnrequestedAgreementStatus.Unrequested,
            totalDailyTradingLimit: 0
        }));

        return [...agreementTableItems, ...unrequested];
    }, [agreements, settlementAccounts, activeAssetManagers]);

    const searchResults = useClientsFilter(tableDataItems, filter, searchString);

    useEffect(() => {
        if (shouldRequest(requestStateFetchClientsList)) {
            dispatch(clientsCompaniesActions.fetchListRequest());
        }
    }, [dispatch, requestStateFetchClientsList]);

    const handleSetSearchString = (string: string) => {
        dispatch(clientsCompaniesActions.setSearchString(string));
        dispatch(clientsCompaniesActions.hideActivePanels());
    };

    const handleFilterChange = (filter: ClientsFilterOptions) => onFilterChanged(filter);
    const handleViewSettlementAccountClick = () => {
        const sortedTableDataItems = [...tableDataItems]
            .sort((a, b) => a.signatory.name.localeCompare(b.signatory.name))

        const firstWithSettlementAccount = sortedTableDataItems.find(a => a.settlementAccountCount);
        const agreementToRedirect = firstWithSettlementAccount ?? sortedTableDataItems[0];
        history.push(routes.clientsSettlementAccountUrl(agreementToRedirect.signatory.id));
    }

    const handleCompanyDetailsButtonClick = (assetManagerId: number) => {
        if (companyDetailsPanelCompanyId && companyDetailsPanelCompanyId === assetManagerId) {
            dispatch(clientsCompaniesActions.resetSelectedAgreement());
            dispatch(companyDetailsActions.hide());
        } else {
            dispatch(clientsCompaniesActions.setSelectedAgreement(assetManagerId))
        }
    }

    const getColumns = (): IColumnDefinition<ClientsTableDataItem>[] => [
        {
            columnKey: 'client-company',
            renderColumnContent: entity => (
                <CompanyDetailsButton
                    title={entity.signatory.name}
                    company={entity.signatory}
                    rowKey={entity.signatory.id}
                    onClick={() => handleCompanyDetailsButtonClick(entity.signatory.id)}
                />
            ),
            renderColumnHeaderContent: () => 'Client Company',
            headerClassName: 'data-list-cell-xxxl-flexible data-client-company',
            bodyClassName: 'data-list-cell-xxxl-flexible data-client-company',
            sortingField: 'signatory.name',
            sortingType: SORTING_TYPE.string,
        }, {
            columnKey: 'settlement-accounts',
            renderColumnContent: entity => renderSettlementAccountLink(entity),
            renderColumnHeaderContent: () => 'Settl. Accs',
            headerClassName: 'data-settl-accs',
            bodyClassName: 'data-settl-accs',
            sortingField: 'settlementAccountCount',
            sortingType: SORTING_TYPE.settlementAccounts,
        }, {
            columnKey: 'status',
            renderColumnContent: entity =>
                entity.agreementStatus === UnrequestedAgreementStatus.Unrequested
                    ? <span className="status status-unrequested">Unrequested</span>
                    : <BrokerDealerAgreementStatusLabel entity={entity as SettlementAgentClientAgreement} />
            ,
            renderColumnHeaderContent: () => 'Request Status',
            headerClassName: 'data-list-cell-sm data-agreement-status',
            bodyClassName: 'data-list-cell-sm data-agreement-status',
            sortingType: SORTING_TYPE.settlementRequestStatus,
            sortingField: 'agreementStatus'
        }, {
            columnKey: 'date',
            renderColumnContent: entity => (entity as AgreementTableDataItem).date
                ? moment((entity as AgreementTableDataItem).date).format(constants.dateFormat)
                : constants.emptyPlaceholder,
            renderColumnHeaderContent: () => 'Request Date',
            headerClassName: 'data-list-cell-sm data-date',
            bodyClassName: 'data-list-cell-sm data-date',
            sortingField: 'date',
            sortingType: SORTING_TYPE.date,
        }, {
            columnKey: 'daily-trading-limit',
            renderColumnContent: entity => (entity as AgreementTableDataItem).dailyTradingLimit
                ? <DailyTradingLimitDialog
                    amountToMM={true}
                    agreement={entity as AgreementTableDataItem}
                    companyName={entity.signatory.name}
                />
                : constants.emptyPlaceholder
            ,
            renderColumnHeaderContent: () => 'Daily Trd Lim (MM)',
            headerClassName: 'data-list-cell-lg data-daily-trading-limit text-right',
            bodyClassName: 'data-list-cell-lg data-daily-trading-limit text-right',
            sortingField: 'totalDailyTradingLimit',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'action-buttons',
            renderColumnContent: entity => entity.agreementStatus === UnrequestedAgreementStatus.Unrequested
                ? null
                : <AgreementActionButtons entity={entity} />,
            renderColumnHeaderContent: () => 'Actions',
            headerClassName: 'data-list-cell-lg-02 data-agreement-action text-right',
            bodyClassName: 'data-list-cell-lg-02 data-agreement-action text-right',
        }
    ];

    const renderSettlementAccountLink = (agreement: ClientsTableDataItem) => {
        const handleClick = () => {
            dispatch(settlementAccountActions.reset()); // TO DO: Remove after Settlement Account push will be implemented
            history.push(routes.clientsSettlementAccountUrl(agreement.signatory.id));
        }
        if (agreement.signatory.role === companyRoles.BrokerDealer) {
            return constants.emptyPlaceholder;
        }
        if (agreement.settlementAccountCount) {
            return (
                <button onClick={handleClick} className="btn btn-counter">
                    {agreement.settlementAccountCount}
                </button>
            )
        }
        return (
            <FeatureButton
                onClick={handleClick}
                className="btn btn-link"
            >
                add
            </FeatureButton>
        );
    };

    const renderContent = () => {
        if (isRequestSuccess(requestStateFetchClientsList) && !tableDataItems.length) {
            return <EmptyPlaceholder text="No clients to approve." />;
        }

        if (!searchResults.length) {
            return <EmptyPlaceholder />;
        }

        return (
            <>
                <Table
                    columns={getColumns().map(c => new ColumnBuilder(c))}
                    className="data-list-striped"
                    defaultSortBy="date"
                    defaultSortByDirection={SORT.DESC}
                    dataItems={searchResults}
                    createSecurityCustomClassName={
                        (entity: SettlementAgentClientAgreement) =>
                            classNames({
                                'active':
                                    entity.signatory.id === selectedCompanyForAffirm?.signatory.id ||
                                    entity.signatory.id === companyDetailsPanelCompanyId,
                                'pending-agreement': entity.agreementStatus === SettlementAgreementStatus.pending
                            })
                    }
                />
                {selectedCompanyForAffirm ? <ClientsControlPanel agreement={selectedCompanyForAffirm} /> : <CompanyDetailsPanel />}
            </>
        );
    };

    return (
        <div className="container clients-area">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <h1>Clients</h1>
                    <SearchInput
                        disabled={!tableDataItems.length || isLoading}
                        value={searchString}
                        onChange={handleSetSearchString}
                        onClear={() => handleSetSearchString('')}
                        placeholder="Search by company name"
                    />
                    <div className="flex-row flex-item-right controls">
                        <ViewSettlementAgentCommission companyName={company?.name ?? ''} />
                        <div className="text-sm currency-converter">
                            <CurrencyConversion />
                        </div>
                    </div>
                </div>
                <div className="sub-header-row type02 flex-row">
                    <ClientsFilter disabled={isLoading} onChange={handleFilterChange} currentFilter={filter} />
                    <div className="flex-row flex-item-right controls">
                        <button onClick={handleViewSettlementAccountClick} className="btn btn-ghost btn-sm">
                            Settlement Accounts
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex-row flex-row-full-size clients-companies">
                <Preloader inProgress={isLoading}>
                    {renderContent()}
                </Preloader>
            </div>
        </div>
    );
}
