import * as React from 'react';
import { isIE } from '../../utils/isIE.utils';
import cn from 'classnames';

interface Props {
    inProgress: boolean;
    text?: React.ReactNode;
    children?: React.ReactNode;
    small?: boolean;
    fullScreen?: boolean;
    textOnly?: boolean;
}

export function Preloader({ inProgress = false, text = '', children, small = false, fullScreen = true, textOnly = false}: Props) {
    const renderIE = () => <i className={cn('icon', { 'icon-loader-sm': small, 'icon-loader-lg': !small })} />;

    const render = () => (
        <figure>
            <div className={cn('preloader', { 'sm': small })}>
                <svg viewBox='0 0 100 100' preserveAspectRatio='none'>
                    <circle id="internal-circle" cx="50" cy="50" r="20" stroke="currentColor" fill="transparent" />
                    <circle id="external-circle" cx="50" cy="50" r="40" stroke="currentColor" fill="transparent" />
                </svg>
            </div>
        </figure>
    );

    const renderFullScreen = (content: React.ReactNode) => {
        return fullScreen
            ? <div className="container-loader">{content}</div>
            : content as React.ReactElement<any>;
    }

    const renderLoaderBox = (loaderImage: React.ReactNode) => {
        return fullScreen
            ? loaderImage as React.ReactElement<any>
            : <div className="loader-box">{loaderImage} {renderText()}</div>;
    }

    const renderLoader = () => isIE() ? renderLoaderBox(renderIE()) : renderLoaderBox(render())

    const renderText = () => text ? <span className="text-loader">{text}</span> : null

    if (inProgress) {
        const content = textOnly ? renderText() : renderLoader();

        return renderFullScreen(content);
    }

    return children ? children as React.ReactElement<any> : null;
}
