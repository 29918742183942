import { useSelector, useDispatch } from "react-redux";
import { capitalize } from "lodash";
import moment from "moment";
import { AppState } from "../../../../types/state/AppState";
import { CloManagersState } from "../../../../types/state/CloManagersState";
import { SectionContent } from "../../../amrPipeline/common/section/SectionContent";
import { FormField } from "../../../amrPipeline/common/FormField";
import { constants } from "../../../../constants";
import { MarkdownViewer } from "../../../common/markdown-editor";
import { formatUtils } from "../../../../utils";
import { cloManagersActions } from "../../../../actions/clo-managers.actions";
import { CloManagerAccessType } from '../../../../types/amr-pipeline/enums/CloManagerAccessType';

export const ESGConsiderations = () => {
    const dispatch = useDispatch();
    const { selectedManager } = useSelector<AppState, CloManagersState>(
        (state: AppState) => state.cloManagers
    );

    if (!selectedManager?.details) {
        return null;
    }

    const { details } = selectedManager;

    const unpriSignatoryValue = details?.unpriSignatorySince
        ? moment(details?.unpriSignatorySince).format(constants.dateFormat)
        : constants.emptyPlaceholder;

    return (
        <SectionContent
            title="ESG Considerations"
            className="data-item-general-information"
        >
            <div className="content-row">
                <div className="content-col">
                    <FormField title="ESG Formal Policy" className="data-item-row-full-height">
                        {capitalize(
                            formatUtils.formatBoolean(details?.esgFormalPolicy)
                        )}
                    </FormField>
                </div>
                <div className="content-col">
                    <FormField title="UNPRI Signatory" className="data-item-row-full-height">
                        {details?.unpriSignatory ? (
                            <a
                                href={details?.unpriSignatory}
                                onClick={() => dispatch(cloManagersActions.logUserActivity(selectedManager.referenceName, CloManagerAccessType.AdditionalInformationUnpri))}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {unpriSignatoryValue}
                            </a>
                        ) : (
                            unpriSignatoryValue
                        )}
                    </FormField>
                </div>
            </div>
            <FormField
                title="ESG Strategy"
                className="data-item-row-full-height"
            >
                {details?.esgStrategy ? <MarkdownViewer value={details.esgStrategy} /> : constants.emptyPlaceholder}
            </FormField>
        </SectionContent>
    );
};
