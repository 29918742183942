import classNames from 'classnames';
import IconSVG from '../../../styles/svg-icons';
import { OnHoverTooltip } from '../OnHoverTooltip';

interface Props {
    withInfoButton?: boolean;
}

export const EvalKTalkLabel = ({ withInfoButton = false }: Props) => {
    const tooltipOverlay = (
        <div>
            <div className="new-feature-head">
                <IconSVG name="favorite" width={16} height={16} />
                <span>New Feature</span>
            </div>
            <div>
                K-Talk is KopenTech's proprietary BWICs (Bids Wanted in Competition) price talk tool. 
                Utilizing diverse datasets, including both primary and secondary market data, 
                K-Talk provides price talks calibrated to reflect broad market activity. 
                Values are regularly updated on business days.
            </div>
        </div>
    );

    return (
        <div className={classNames("eval-box", { "animated": true })}>
            <div className="eval-box-text">
                <IconSVG name="kopentech" width={16} height={16} />
                <span>K-Talk</span>
                {withInfoButton &&
                    <OnHoverTooltip overlay={tooltipOverlay} placement="bottomRight" overlayClassName="eval-box-popover">
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>
                }
            </div>
        </div>
    );
};
