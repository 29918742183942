import * as React from 'react';
import { OnHoverTooltip } from './OnHoverTooltip';
import IconSVG from '../../styles/svg-icons';

export function SameDayBwicIcon() {
    return (
        <OnHoverTooltip wrapperClass="same-day-wrapper" overlay="Same-day BWICs are BWICs that are announced and scheduled on the same day.">
            <IconSVG name="same-day" width={16} height={16} />
        </OnHoverTooltip>
    );
}
