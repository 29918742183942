import IconSVG from "../../styles/svg-icons";
import { generateIntexLink } from "../../utils/amr-pipeline.utils";
import cn from 'classnames';
import { OnHoverTooltip } from "./OnHoverTooltip";
import { logger } from "../../logging/logger";
import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";
import { ActionBlocker } from "../access/ActionBlocker";

type Props = {
    positionId?: string,
    text?: string;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    overrideRequiredFeature?: boolean;
}

export function IntexLink({
    positionId,
    text,
    className,
    onClick,
    disabled = false,
    overrideRequiredFeature,
}: Props) {
    if (disabled) {
        return (
            <button className={cn('btn-link btn-link-intex', className)} disabled={disabled}>
                <IconSVG name="intex" width={16} height={16} />
                {!!text && text}
            </button>
        );
    }

    return (
        <ActionBlocker
            feature={SubscriptionFeature.IntegrationsIntex}
            overrideRequiredFeatures={overrideRequiredFeature}
        >
            {
                blocked =>
                    <OnHoverTooltip
                        wrapperClass="intex-link"
                        overlay={
                            !blocked && (
                                positionId
                                    ? "Launch the INTEXcalc™ application to view additional data"
                                    : "There is no data to open Intex"
                            )}
                    >
                        <a
                            className={cn('btn-link btn-link-intex', className, { 'btn-link-no-label': !text, 'disabled': !positionId || blocked })}
                            href={(positionId && !blocked) ? generateIntexLink(positionId) : '/#'}
                            onClick={e => {
                                e.stopPropagation()
                                logger.trace('User clicked the Intex button');
                                if (onClick) {
                                    onClick();
                                }
                            }}
                        >
                            <IconSVG name="intex" width={16} height={16} />
                            {!!text && text}
                        </a>
                    </OnHoverTooltip>
            }
        </ActionBlocker>
    );
}
