import React, { useLayoutEffect, useRef } from 'react';
import { scrollIntoView } from 'seamless-scroll-polyfill';

interface Props {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
}

export const ScrollIntoView = ({ children, className, disabled = true }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {

        if (!disabled && containerRef.current) {
            scrollIntoView(containerRef.current, { block: 'center', inline: 'center', behavior: 'smooth' })
        }
        // eslint-disable-next-line
    }, []);

    return <div className={className} ref={containerRef}>{children}</div>
}
