import * as React from 'react';
import moment from 'moment';
import classNames from 'classnames';

interface TimerProps {
    start: Date,
    durationSeconds: number,
    durationHighlightLimitSecods?: number
    onEllapsed: () => void,
    customFormat?: (remainingDuration: number) => string
}

export function Timer({ start, durationSeconds, durationHighlightLimitSecods = 60, customFormat, onEllapsed }: TimerProps) {
    const [iteration, setIteration] = React.useState(0);
    const currentDuration = moment().diff(start, 's');
    const remainingDuration = durationSeconds - currentDuration;
    const shouldHighlight = durationHighlightLimitSecods && remainingDuration <= durationHighlightLimitSecods;
    const formatTime = (time: number) => time <= 0
        ? '00'
        : time.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const format = (): string => {
        const hours = ~~(remainingDuration / 3600);
        const minutes = ~~((remainingDuration - hours * 3600) / 60);
        const seconds = remainingDuration - hours * 3600 - minutes * 60;

        return [!!hours && formatTime(hours), formatTime(minutes), formatTime(seconds)]
            .filter(part => !!part)
            .join(':');
    }

    React.useEffect(() => {
        let handle: number | undefined = undefined;
        if (remainingDuration > 0) {
            handle = window.setTimeout(() => setIteration(iteration + 1), 1000);
        } else {
            onEllapsed();
        }

        return () => {
            if (handle) {
                clearTimeout(handle);
            }
        }  // eslint-disable-next-line
    }, [iteration, remainingDuration]);


    return (
        <div className={classNames({'timer':true, 'time-ends-soon': shouldHighlight })}>
            <i className="icon icon-xl icon-time-gray" />
            <span>{customFormat == null ? format() : customFormat(remainingDuration)}</span>
        </div>
    );
}
