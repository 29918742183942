import React, { Component } from "react";
import Slider from "react-slick";
import { introCarousel } from '../../constants';
import "../../styles/components/slick-slider.scss";

class IntroCarousel extends Component {
    render() {
        const carouselItem = introCarousel.map(({ img, title, text, newFeature }, i) => {
            return (
                <div key={i}>
                    <div className="img-box"><img src={img} alt={title} /></div>
                    <div className="text-box">
                        <h2>
                            { newFeature &&
                                <sup>new</sup>
                            }
                            {title}
                        </h2>
                        <ul>
                            {text.map((t, j) => <li key={j}>{t}</li>)}
                        </ul>
                    </div>
                </div>
            )
        });
        const settings = {
            arrows: true,
            dots: true,
            draggable: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 10000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <>
                <Slider {...settings}>
                    {carouselItem}
                </Slider>
            </>
        );
    }
}

export default IntroCarousel
