import cn from 'classnames';
import React, { useState } from 'react';

interface Props {
    children: React.ReactNode;
    disabled?: boolean;
}

export const BorderHighlightAnimation = ({ children, disabled = true }: Props) => {
    const [animate, setAnimate] = useState(false);

    React.useLayoutEffect(() => {
        if(!disabled){
            setAnimate(true);
        }
        // eslint-disable-next-line
    }, []);

    return <div className={cn({highlight: animate})}>{children}</div>
}
