import classNames from 'classnames';
import { constants } from '../../../../constants';
import { chartUtils } from '../../../../utils';
import { OnHoverTooltip, Plot } from "../../../common";
import IconSVG from '../../../../styles/svg-icons';
import { PlotMouseEvent } from 'plotly.js';
import { ActionBlocker } from '../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { BlockedFeatureTooltipText } from '../../../access/BlockedFeatureText';

const layout = {
    height: 122,
    width: 122,
    showlegend: false,
    autosize: false,
    margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
        pad: 4,
    },
};

const mainConfig = {
    responsive: true,
    displaylogo: false,
    doubleClickDelay: 1000,
    displayModeBar: false,
    showTips: false,
};

interface StatisticsChartProps<T> {
    title: string;
    onSectionClick: (section: T) => void;
    sections: [T, T];
    values: [number?, number?];
    labels: [string, string];
    colors: [string, string];
    features?: SubscriptionFeature[];
}

export const StatisticsChart = <T,>({
    title,
    onSectionClick,
    sections,
    values,
    labels,
    colors,
    features,
}: StatisticsChartProps<T>) => {
    const merged = values.map((value, index) => ({
        value,
        label: labels[index],
        color: colors[index],
        section: sections[index],
    })).filter(x => !!x.value);

    const isEmpty = !merged.length;

    const handlePlotClick = (e: PlotMouseEvent) => {
        if (!e.points || !e.points.length || isEmpty) {
            return;
        }

        const [clickedPoint] = e.points;

        const customData = (clickedPoint.customdata as unknown) as T[];

        if (!customData || !customData.length) {
            return;
        }

        const [clickedSection] = customData;

        onSectionClick(clickedSection);
    }

    const data = [
        {
            textinfo: "none",
            hoverinfo: "text",
            hole: 0.65,
            type: "pie",
            hoverlabel: {
                bgcolor: "#4A4A4A",
                bordercolor: "#4A4A4A",
                font: {
                    size: 12,
                    color: "#fff",
                },
            },
            direction: "clockwise",
            sort: false,
            values: isEmpty ? [100] : merged.map(x => x.value),
            text: merged.map(x => `${x.label}: ${x.value}`),
            customdata: merged.map(x => x.section),
            marker: {
                colors: isEmpty ? ['#F5F5F5'] : merged.map(x => x.color),
                line: {
                    color: "#fff",
                    width: 2,
                },
            },
        },
    ];

    const redirectButton = (section: T) => (disabled: boolean = false) => (
        <OnHoverTooltip
            overlayClassName="trial-tooltip"
            overlay={disabled ? <BlockedFeatureTooltipText /> : "See on Issuance Monitor"}
        >
            <button
                className={classNames("btn-link", { disabled })}
                onClick={disabled ? undefined : () => onSectionClick(section)}
            >
                <IconSVG name="go-to" width={16} height={16} />
            </button>
        </OnHoverTooltip>
    );

    return (
        <ActionBlocker requireAllFeatures features={features} noTooltip>
            {blocked => (
                <div className="statistics-chart-item">
                    <div className="statistics-chart-head">
                        <h3>{title}</h3>
                    </div>
                    <div className="statistics-chart-box">
                        <Plot
                            data={data}
                            layout={layout}
                            config={mainConfig}
                            onHover={blocked ? undefined : chartUtils.setCursor('pointer')}
                            onUnhover={blocked ? undefined : chartUtils.setCursor('crosshair')}
                            onClick={blocked ? undefined : handlePlotClick}
                        />
                    </div>
                    <div className="agenda">
                        {labels.map((label, index) =>
                            <div key={label} className={classNames("agenda-item", label.toLowerCase().replaceAll(' ', '-'))}>
                                <span>{label}</span>
                                <span className={classNames("agenda-item-box", { "agenda-item-placeholder": !values[index] })}>
                                    {values[index] || constants.emptyPlaceholder}
                                    {!!values[index] && redirectButton(sections[index])(blocked)}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ActionBlocker>
    );
}
