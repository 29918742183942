import * as React from 'react';
import dateTimeUtils from '../../utils/dateTime.utils';
import { BwicRulesSectionRow } from './sections/BwicRulesSectionRow';
import { Process } from '../../types/models/Process';

export function CutOffReminder({ process }: { process: Process }) {
    return (
        <BwicRulesSectionRow label="Reminder to bid" dataTestId="reminder-to-bid">
            {dateTimeUtils.parseTimeSpan(process?.cutOffReminder).totalMinutes} minutes
        </BwicRulesSectionRow>
    );
}

