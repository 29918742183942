import cn from 'classnames';
import { roles, routes } from '../../constants';
import { user } from '../../user';
import { BwicStatus } from '../../types/enums/BwicStatus';
import { DirectBiddingDesclaimerLink } from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerLink';
import { useAgreement } from '../seller-buyside/useAgreement';
import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { RoleActionBlocker } from '../access/RoleActionBlocker';
import { OnHoverTooltip } from './OnHoverTooltip';

interface Props {
    small?: boolean;
    text?: string;
    emptyPlaceholderText?: string;
    referenceName: string;
    status?: BwicStatus;
    isDirectBidding: boolean;
    isParticipant: boolean;
    isParsed: boolean;
    isMy: boolean;
    isAllToAll?: boolean;
    disabled?: boolean;
    className?: string;
}

export function BwicViewLink({
    text,
    emptyPlaceholderText,
    referenceName,
    status,
    isMy,
    isParticipant,
    isDirectBidding,
    isAllToAll,
    disabled,
    isParsed,
    className,
    small }: Props) {
    const { agreement } = useAgreement();

    const isSeller = user.hasRoles(...roles.seller());
    const isAdmin = user.hasRoles(roles.Administrator);
    const isBd = user.hasRoles(...roles.bd());

    const isParsedHidden = isParsed && (isSeller || isAdmin);
    const isAllToAllHidden = isAllToAll && isAdmin;
    const isSellerHidden = isSeller && !isMy;
    const isHiddenForSettlementAgent = isAllToAll && user.current()?.isClearingBank;


    if (isHiddenForSettlementAgent) {
        return emptyPlaceholderText ?
            (<OnHoverTooltip overlay="Action is unavailable for the Settlement Agent. Only platform Broker-Dealers approved to All-to-All trading is able to participate.">
                {emptyPlaceholderText}
            </OnHoverTooltip>)
            : null
    }

    if (isParsedHidden || isAllToAllHidden || isSellerHidden || isHiddenForSettlementAgent) {
        return emptyPlaceholderText ? <>{emptyPlaceholderText}</> : null;
    }

    const isScheduled = status === BwicStatus.scheduled;
    const isBidding = status === BwicStatus.bidding;
    const hasSignedAgreement = agreement?.agreementStatus === SettlementAgreementStatus.confirmed;
    const isBdBuyer = isBd && hasSignedAgreement;

    const canSubmitBid = isBd && user.hasRoles(roles.BrokerDealerTrader);

    const getAppearance = () => {
        if (text) return { text, className: "" };

        const bid = { text: "bid", className: "btn btn-main width-64" };
        const addPxTalk = { text: "Px Talk", className: "btn btn-ghost width-64" };
        const view = { text: "view", className: "btn btn-ghost width-64" };

        if (isScheduled && isBd && (!isAllToAll || !hasSignedAgreement) && canSubmitBid) return addPxTalk;
        if (isAllToAll && isBdBuyer && (isBidding || isScheduled) && !isMy && canSubmitBid) return bid;
        if (!isAllToAll && isBd && isParticipant && isBidding && canSubmitBid) return bid;
        return view;
    }

    const appearance = getAppearance();

    return (
        <RoleActionBlocker roles={[...roles.bd(), ...roles.seller(), roles.Administrator]}>
            {disabledByRole => (
                <DirectBiddingDesclaimerLink
                    path={routes.biddingUrl(referenceName)}
                    // Display disclaimer for direct bidding bwic owners only
                    disclaimerDisabled={disabled || !(isDirectBidding && isMy) || disabledByRole}
                    disabled={disabled || disabledByRole}
                    text={appearance.text}
                    className={cn(className, appearance.className, { "btn-sm": small })}
                    target="_blank"
                    tooltip="Click to open appropriate BWIC in a separate browser tab"
                />
            )}
        </RoleActionBlocker>
    );
}