import { useField } from 'formik';
import { AmrFile } from '../../../types/amr-pipeline/models/AmrFile';
import { SectionContent } from '../../amrPipeline/common/section/SectionContent';
import { ManagerPresentationUpload } from './ManagerPresentationUpload';
import { useDispatch } from 'react-redux';
import { cloManagersActions } from '../../../actions/clo-managers.actions';

const AcceptedExtensions = ['pdf', 'ppt', 'pptx'];

export const ManagerPresentations = () => {
    const dispatch = useDispatch();
    const [managerPresentationField, , managerPresentationHelpers] = useField<AmrFile[]>('managerPresentationFiles');

    const handleOnUploading = (uploading: boolean) => {
        dispatch(cloManagersActions.setCloManagerSaveState(uploading));
    }

    return (
        <SectionContent title="Manager Presentations">
            <ManagerPresentationUpload
                label=""
                documents={managerPresentationField.value}
                onChange={documents => managerPresentationHelpers.setValue(documents)}
                acceptedExtensions={AcceptedExtensions}
                onUploading={handleOnUploading}
            />
        </SectionContent>
    );
}
