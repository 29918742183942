import moment from "moment";

interface Props {
    inventoryLastTraded?: Date;
    bwicLastTradedDate?: Date;
    children: (isInventoryDate: boolean, date?: Date) => React.ReactNode;
}

export function SecurityLastTradedDate({ inventoryLastTraded, bwicLastTradedDate, children }: Props) {
    const isInventoryDate = inventoryLastTraded !== null
        && (!bwicLastTradedDate || moment(inventoryLastTraded).isAfter(bwicLastTradedDate, 'day'));

    const lastTradedDate = isInventoryDate
        ? inventoryLastTraded
        : bwicLastTradedDate;

    return <>{children(isInventoryDate, lastTradedDate)}</>;
}
