import React from 'react';
import classNames from 'classnames';
import { userStatuses } from '../../constants';
import { UserStatus } from '../../types/account/UserStatus';
import { Popover } from '../controls';

interface UserStatusDetailsProps {
    blockReason?: string;
    status: UserStatus;
}

export function UserStatusDetails({ blockReason, status }: UserStatusDetailsProps) {
    const hasBlockedReason = status === UserStatus.Blocked && blockReason;

    const statusClasses = classNames({
        [`first-letter status ${userStatuses.getByStatusCode(status)?.className}`]: true,
        'has-blocked-reason': hasBlockedReason
    });

    return (
        <div className={statusClasses}>
            {
                hasBlockedReason ? (
                    <Popover
                        title="Block Reason"
                        className="popover-md"
                        actionButton={(
                            <button className='pseudo-link'>{userStatuses.getByStatusCode(status)?.title}</button>
                        )}
                    >
                        <div className="status-message alert">
                            <i className="icon icon-warning" />
                            <span className="status-message-cnt">{blockReason}</span>
                        </div>
                    </Popover>
                ) : (
                    <span>{userStatuses.getByStatusCode(status)?.title}</span>
                )
            }
        </div>
    )
}
