import { PlotMouseEvent } from 'plotly.js';
import { cloManagersChartBar, trusteeBarColor } from '../../../../../constants/cloManagers/chartBar';
import { mainConfig, Plot } from '../../../../common';
import { chartUtils } from '../../../../../utils';
import { CloManagerDealStats } from '../../../../../types/clo-managers/CloManagerDealStats';
import { constants } from '../../../../../constants';

interface Props {
    chartDiv?: string;
    data: CloManagerDealStats[];
    onClick?: (comoanyReferenceName: string) => void;
}

export function CloManagerTrusteeStatsBarChart({ chartDiv, data, onClick }: Props) {
    const onBarClick = (e: PlotMouseEvent) => {
        if (!onClick) {
            return;
        }

        const point = e && e.points && e.points[0];

        if (!point) {
            return;
        }

        const companyReferenceName = point.customdata as string;

        onClick(companyReferenceName);
    };

    const getChartData = () =>
        data.map(d => ({
            x: [d.companyShortName || constants.emptyPlaceholder],
            y: [d.numberOfDeals],
            type: 'bar',
            hoverinfo: 'text',
            textposition: 'none',
            name: d.companyReferenceName,
            customdata: [d.companyReferenceName],
            text: `${d.companyShortName || constants.emptyPlaceholder}: ${d.numberOfDeals}` || [],
            marker: { color: trusteeBarColor },
        }));

    const chartData = getChartData();

    const { label, tickColor, tickFont, zeroLineColor, margin, fontSize } = cloManagersChartBar;

    const layout = {
        showlegend: false,
        barmode: 'group',
        autosize: true,
        hovermode: 'closest',
        hoverlabel: {
            bordercolor: label.bordercolor,
            bgcolor: label.bgColor,
            font: {
                color: label.fontColor,
                family: label.fontFamily,
                size: label.size,
            },
        },
        font: { size: fontSize },
        margin,
        xaxis: {
            type: 'category',
            zeroline: false,
            fixedrange: true,
            tickangle: 0,
            ticklen: 0,
        },
        yaxis: {
            showgrid: true,
            mirror: 'ticks',
            fixedrange: true,
            showtickprefix: 'none',
            zerolinecolor: zeroLineColor,
            gridcolor: tickColor,
            ticks: 'inside',
            tickcolor: tickColor,
            tickfont: tickFont,
        },
    };

    return (
        <>
            <Plot
                onInitialized={chartUtils.setCursorByDiv(chartDiv, 'crosshair')}
                onHover={chartUtils.setCursor('pointer')}
                onUnhover={chartUtils.setCursor('crosshair')}
                divId={chartDiv}
                onClick={onBarClick}
                data={chartData}
                layout={layout}
                config={mainConfig}
            />
            <div className="description">All statistics are calculated based on the transaction pricing date.</div>
        </>
    );
}

CloManagerTrusteeStatsBarChart.defaultProps = {
    chartDiv: 'clo-manager-bars-chart',
};
