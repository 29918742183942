import { FormField } from '../../amrPipeline/common/FormField';
import { SectionContent } from '../../amrPipeline/common/section/SectionContent';
import { CheckboxField } from '../../common/form-fields/CheckboxField';
import { DateField } from '../../common/form-fields/DateField';
import { MarkdownField } from '../../common/form-fields/MarkdownField';
import { TextField } from '../../common/form-fields/TextField';

export const ESGConsiderations = () => {
    return (
        <SectionContent title="ESG Considerations">
            <div className="content-row">
                <div className="content-col">
                    <DateField maximumDate={new Date()} label="UNPRI Signatory since" name="unpriSignatorySince" />
                </div>
                <div className="content-col">
                    <TextField maxLength={256} label="UNPRI Signatory" name="unpriSignatory" />
                </div>
            </div>
            <MarkdownField
                label="ESG Strategy"
                name="esgStrategy"
                wrapperClassName="data-item-row-full-height"
                maxLength={2048}
            />
            <FormField title="" className="formal-policy-checkbox">
                <CheckboxField label="ESG Formal Policy" name="esgFormalPolicy" />
            </FormField>
        </SectionContent>
    );
}
