import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePxTalkActions as actions } from '../../../actions';
import { ButtonSwitch } from '../../controls';
import { pxTalkLevelTypes } from '../../../constants';

class PxTalkRow extends Component {
    state = {
        changed: true
    }

    render = () => {
        const { index, pxTalk, dispatch } = this.props;

        return (
            <div className="flex-row flex-row-pxTalks">
                <div className="flex-none table-pxTalks-cell-company">{pxTalk.company.name}</div>
                <div className="flex-none table-pxTalks-cell-pxTalk">
                    <div className="form-control-wrapper">
                        {
                            !!pxTalk.error
                                ?
                                  <input
                                    className="form-control is-invalid"
                                    type="text"
                                    value={pxTalk.talk || ''}
                                    autoComplete="off"
                                    onChange={this.handleTalkChange}
                                    onBlur={this.handleTalkBlur} />
                                :
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={pxTalk.talk || ''}
                                    autoComplete="off"
                                    onChange={this.handleTalkChange}
                                    onBlur={this.handleTalkBlur}
                                />
                        }
                        {!!pxTalk.error && <div className="form-error">{pxTalk.error}</div>}
                    </div>
                </div>
                <div className="flex-none table-pxTalks-cell-normalized text-right">{pxTalk.normalizedLevel || ''}</div>
                <div className="flex-none table-pxTalks-cell-type">
                    <ButtonSwitch
                        sourceItems={[pxTalkLevelTypes.price, pxTalkLevelTypes.DM]}
                        selectedValue={pxTalk.levelType}
                        onSelectionChange={levelType => dispatch(actions.levelTypeChange(pxTalk.company.id, levelType))}
                        className="control-group-type01"
                    />
                </div>
                <i className="btn-link btn-danger icon icon-delete" onClick={() => dispatch(actions.deletePxTalk(index))}></i>
            </div>
        );
    }

    handleTalkChange = e => {
        const { pxTalk, dispatch } = this.props;

        this.setState({ changed: true });
        dispatch(actions.talkChange(pxTalk.company.id, e.target.value));
    }

    handleTalkBlur = () => {
        const { pxTalk, dispatch } = this.props;

        if (this.state.changed) {
            this.setState({ changed: false });
            dispatch(actions.normalizeTalk(pxTalk.company.id, pxTalk.talk, pxTalk.levelType));
        }
    }
}

const mapStateToProps = () => ({
});

const connectedPxTalkRow = connect(mapStateToProps)(PxTalkRow);
export { connectedPxTalkRow as PxTalkRow };
