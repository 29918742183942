import { user } from "../../user";

interface BwicFlags {
    isMy: boolean;
    isParticipant: boolean;
    isAllToAll: boolean;
}

interface MyBwicIconProps {
    bwic: BwicFlags
}


export const MyBwicIcon = ({ bwic }: MyBwicIconProps) => {
    if (bwic.isAllToAll && user.current()?.isClearingBank) return null;

    return bwic.isMy || bwic.isParticipant ? (
        <div className="bwic-state-my">My</div>
    ) : null;
}