import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Popup from '../../controls/Popup';
import { PopupBody, PopupFooter } from '../../controls';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { isRequesting, isRequestNone } from '../../../utils';
import { clientsCompaniesActions } from '../../../actions/clients-companies.actions';
import { Currency } from '../../../types/enums/Currency';
import { CurrencyRate } from '../../../types/currency/CurrencyRate';
import { constants } from '../../../constants';
import { FeatureButton } from '../../access/FeatureButton';
import IconSVG from '../../../styles/svg-icons';

export const CurrencyConversion: React.FC = () => {
    const dispatch = useDispatch();

    const currencyRate = useSelector((s: AppState) => s.clientsCompanies.currencyRate);
    const visibleSetRatePopup = useSelector((s: AppState) => s.clientsCompanies.visibleSetRatePopup);
    const requestStateGetCurrencyRate = useSelector((s: AppState) => s.clientsCompanies.requestStateGetCurrencyRate);
    const requestStateUpdateCurrencyRate = useSelector((s: AppState) => s.clientsCompanies.requestStateUpdateCurrencyRate);
    const euroCurrency: CurrencyRate | undefined = useMemo(() => currencyRate.find(currency => currency.code === Currency.EUR), [currencyRate]);

    const [rate, setRate] = useState(euroCurrency?.usdRate || '');

    useEffect(() => {
        if (isRequestNone(requestStateGetCurrencyRate)) {
            dispatch(clientsCompaniesActions.getCurrencyRate());
        }
    }, [requestStateGetCurrencyRate, dispatch]);

    useEffect(() => {
        if (euroCurrency?.usdRate) {
            setRate(euroCurrency.usdRate);
        }
    }, [euroCurrency]);

    const handleSetValue = (e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const value = Number(e.target.value);
        if (value === 0 || (value >= constants.currencyConversionRate.min && value <= constants.currencyConversionRate.max)) {
            setRate(e.target.value);
        }
    };

    const handleHidePopup = () => {
        dispatch(clientsCompaniesActions.setVisibleUpdateRatePopup(false));
        setRate('');
    };

    const handleShowPopup = () => {
        if (euroCurrency) {
            setRate(euroCurrency?.usdRate);
        }
        dispatch(clientsCompaniesActions.setVisibleUpdateRatePopup(true));
    };

    const handleApply = () => {
        if (euroCurrency) {
            euroCurrency.usdRate = Number(rate);
            dispatch(clientsCompaniesActions.updateCurrencyRate(euroCurrency))
        }
    };

    const renderPopup = () => visibleSetRatePopup && (
        <Popup title="Adjust EUR to USD Conversion Rate" onClose={handleHidePopup}>
            <PopupBody>
                <div className="currency-conversion-form">
                    <label className="form-label">1.00 Euro =</label>
                    <input
                        className="form-control"
                        type="text"
                        value={rate}
                        maxLength={5}
                        onChange={handleSetValue}
                    />
                    <span className="usd">USD</span>
                </div>
            </PopupBody>
            <PopupFooter>
                <button
                    type="button"
                    className="btn btn-ghost"
                    disabled={isRequesting(requestStateUpdateCurrencyRate)}
                    onClick={handleHidePopup}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-main"
                    disabled={isRequesting(requestStateUpdateCurrencyRate) || !rate || Number(rate) === 0}
                    onClick={handleApply}
                >
                    Apply
                </button>
            </PopupFooter>
        </Popup>
    );

    return (
        <div className="currency-conversion">
            {renderPopup()}
            <span className="current-currency">1.00 Euro = {euroCurrency?.usdRate} USD</span>
            <FeatureButton className="btn-link btn-edit" onClick={handleShowPopup}>
                <IconSVG name="edit-pencil" width={16} height={16} />
            </FeatureButton>
        </div>
    )
};