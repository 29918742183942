import { OriginatingTransactionDocument } from "../../../../../types/amr-pipeline/models/OriginatingTransactionDocument";
import { EmptyPlaceholder } from "../../../../common";
import { DownloadedDocument } from "../../../../../types/amr-pipeline/models/AnalyticsSession";
import DownloadDocument from "../../../../amrPipeline/common/documents/DownloadDocument";
import useDownloadDocumentList from "../../../../../effects/useDownloadDocumentList";
import { cloManagersService } from "../../../../../services/clo-managers.service";

interface Props {
    companyReferenceName: string;
    documents: DownloadedDocument[];
}

export function PresDownloadedTab({ companyReferenceName, documents }: Props) {
    const { loadHandler, loadingState } = useDownloadDocumentList({
        documents,
        downloadRequest: (referenceName: string) =>
            cloManagersService.downloadManagerPresentationFile(companyReferenceName, referenceName),
    });

    const getDocumentDownloadLink = (document: DownloadedDocument) =>
        loadHandler(document.referenceName, document.name);

    if (!documents.length) {
        return <EmptyPlaceholder text="There is no information to display." />;
    }

    return (
        <ul className="list-bullet docs-downloaded-box">
            {documents.map((d, index) => (
                <li
                    key={d.referenceName + index}
                    className="download-document-item"
                >
                    {d.isDeleted ? (
                        d.name
                    ) : (
                        <DownloadDocument
                            document={
                                {
                                    referenceName: d.referenceName,
                                    name: d.name,
                                } as OriginatingTransactionDocument
                            }
                            onClick={() => getDocumentDownloadLink(d)}
                            isLoading={loadingState[d.referenceName]}
                            loadingText
                            secondary
                        >
                            {d.name}
                        </DownloadDocument>
                    )}
                </li>
            ))}
        </ul>
    );
}
