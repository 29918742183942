interface BwicRulesStageProps {
    title: string;
    children: React.ReactNode;
}

export const BwicRulesStage = ({ title, children }: BwicRulesStageProps) => {
    return (
        <div className="rules-stage">
            <h5><strong>{title}</strong></h5>
            {children}
        </div>
    )
}