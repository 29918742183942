import React, { useState } from 'react';
import { Preloader } from './index';

interface Props {
    path: string;
    fallback?: React.ReactNode;
}

export const ImageLoader:React.FC<Props> = ({ path, fallback }) => {
    const [loaded, setLoaded] = useState(false);
    const [error, seError] = useState(false);

    if (!path) {
        return null;
    }

    return (
        <>
            <Preloader inProgress={!loaded} />
            {!error &&
                <img
                    alt={`release-${path}`}
                    src={path}
                    onLoad={() => setLoaded(true)}
                    onError={() => {
                        setLoaded(true);
                        seError(true);
                    }}
                />
            }
            {!!error && fallback}
        </>
    )
};

export const FallbackImage = (
    <i className="icon icon-logo-bwic"></i>
);
